import { observer } from "mobx-react-lite";
import React from "react";

import { getUserAlias } from "../../../../../helpers/accountHelper";
import { updatePropValueActionV2 } from "../../../actions/jobActions";
import { isRestrictedSharing } from "../../../helpers/checkUserPermissionHelper";
import { getUserProfileResponse } from "../../../helpers/getUserProfileResponseHelper";
import { getValue } from "../../../selectors/getJobPropV2";
import { JobSBSExpView } from "./JobSBSExpView";

export const JobSbsExpContainer = observer(() => {
  const isSBSEnabled = getValue("create_sbs_experiment");
  const [isLoading, setIsLoading] = React.useState(true);
  const [disabled, setDisabled] = React.useState(false);

  React.useEffect(() => {
    if (isSBSEnabled === undefined) {
      setIsLoading(false);
      setDisabled(false);
    } else {
      setIsLoading(true);
      getUserProfileResponse()
        .then((userProfile) => {
          setIsLoading(false);
          setDisabled(isRestrictedSharing(getUserAlias(), userProfile));
        })
        .catch(() => {
          setIsLoading(false);
          setDisabled(true);
        });
    }
  }, [isSBSEnabled]);
  React.useEffect(() => {
    if (disabled && isSBSEnabled) {
      updatePropValueActionV2({
        prop: "create_sbs_experiment",
        newData: false,
      });
    }
  }, [disabled, isSBSEnabled]);

  return (
    <JobSBSExpView
      isSBSEnabled={isSBSEnabled}
      onChange={(value) => {
        updatePropValueActionV2({
          prop: "create_sbs_experiment",
          newData: value,
        });
      }}
      disabled={disabled}
      isLoading={isLoading}
    />
  );
});
