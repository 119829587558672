import { createStore } from "satcheljs";
import type {
  VirtualAccount,
  VirtualTenant,
} from "../../../models/VirtualTenant";

export enum AccountType {
  UseSyntheticTenant = "Use synthetic tenant",
  InputManually = "Input manually",
}

interface IJobCreationSyntheticStore {
  isOpenSelectSyntheticUserDialog: boolean;
  selectedUser: string;
  accountType: string;
  selectedQuerySet: string;
  personalAccount: string;
  templateQuerySet: string;
  searchTenant: string;
  searchTag: string;
  searchRing: string;
  searchCreator: string;
  virtualTenants: VirtualTenant[];
  querySets: string[];
  selectedVirtualTenant?: VirtualTenant;
  selectedVirtualAccount?: VirtualAccount;
  openItems: string[];
}

export const initSyntheticStore: IJobCreationSyntheticStore = {
  isOpenSelectSyntheticUserDialog: false,
  selectedUser: "",
  accountType: AccountType.UseSyntheticTenant,
  selectedQuerySet: "",
  personalAccount: "",
  templateQuerySet: "",
  searchTenant: "",
  searchTag: "",
  searchRing: "",
  searchCreator: "",
  virtualTenants: [],
  querySets: [],
  openItems: [],
};

export const jobCreationSyntheticStore =
  createStore<IJobCreationSyntheticStore>(
    "syntheticStore",
    Object.assign({}, initSyntheticStore),
  )();
