import { computed } from "mobx";
import { store } from "../store/store";

// SEVAL user permission unknown
export const isUnknownUser = computed(() => store.permission === undefined);

// SEVAL users
export const isUser = computed(() => store.permission?.isUser === true);

// SEVAL developers
export const isDev = computed(() => store.permission?.isDev === true);

// SEVAL dogfooders
export const isDogfooder = computed(
  () => store.permission?.isDogfooder === true,
);

// SEVAL admins
export const isAdmin = computed(() => store.permission?.isAdmin === true);

// SEVAL data scientists
export const isScientist = computed(
  () => store.permission?.isScientist === true,
);

// Bing Admin
export const isBingAdmin = computed(
  () => store.permission?.isBingAdmin === true,
);

// Query Management Admin
export const isQueryAdmin = computed(
  () => store.permission?.isQueryAdmin === true,
);

// Product Admin
export const isProductAdmin = computed(
  () => store.permission?.isProductAdmin === true,
);

export const isDRI = computed(() => store.permission?.isDRI === true);

// DRI, Dev, Partner team DRI should have access to add incidents
export const isIncidentCreator = computed(
  () =>
    store.permission?.isDRI === true ||
    store.permission?.isDev === true ||
    store.permission?.isBingAdmin === true,
);

export const isSchedulerManager = computed(
  () => store.permission?.isSchedulerManager === true,
);
