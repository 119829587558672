import {
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  DialogTrigger,
  tokens,
} from "@fluentui/react-components";
import { observer } from "mobx-react";
import React, { useEffect } from "react";
import type { DevUILink } from "../../../../helpers/devUILinkHelper";
import { openDevUILink } from "../../../../helpers/devUILinkHelper";

export interface IDevUIDialogKeyProps {
  query: string;
  queryHash: string;
  jobId: string;
  exp_name: string;
}

export interface IDevUIDialogProps {
  isOpen: boolean;
  onComplete: () => void;
  content?: IDevUIDialogKeyProps;
}

const commonIssueWikiLink = `https://eng.ms/docs/experiences-devices/m365-core/microsoft-search-assistants-intelligence-msai/msai-stca/sydney-evaluation-platform/copilot-evaluation-platform/reference/devui-integration#common-issue`;

export const DevUIDialog = observer((props: IDevUIDialogProps) => {
  const { isOpen, content } = props;

  const [devUILink, setDevUILink] = React.useState<DevUILink | undefined>(
    undefined,
  );
  const [error, setError] = React.useState<string | undefined>(undefined);
  useEffect(() => {
    if (isOpen && content) {
      openDevUILink(
        content.query,
        content.queryHash,
        content.jobId,
        content.exp_name,
      )
        .then((data) => setDevUILink(data))
        .catch((err) => setError(err.message));
    }
  }, [isOpen, content]);
  return (
    <Dialog
      open={isOpen}
      onOpenChange={() => {
        props.onComplete();
      }}
    >
      <DialogSurface>
        <DialogBody>
          <DialogTitle>Open Dev UI Link...</DialogTitle>
          <DialogContent>
            {devUILink ? (
              <div>
                <p>
                  <strong
                    style={{ color: tokens.colorStatusSuccessForeground1 }}
                  >
                    Conversation record found!
                  </strong>
                  <br />
                  <strong>Conversation ID: </strong>
                  {devUILink.conversationId}
                  <br />
                  <strong>TimeStamp: </strong>
                  {devUILink.timeStamp}
                  <br />
                  <strong>Link: </strong>
                  <a href={devUILink.link} target="_blank" rel="noreferrer">
                    {devUILink.link}
                  </a>
                  <br />
                  <strong>Note:</strong>
                  <br />
                  {devUILink.isNew && (
                    <p>
                      1.If this conversation is created by Test tenant account,
                      the conversation will be expired 90 days after the job
                      complete. If this converstion is created by personal
                      account, this link will be expired 24 hours after the
                      conversation complete. Please download raw response file
                      using Download {">"} Scrape Data & Conversation, open
                      DevUI link and upload the conversation file for debug.
                      <br />
                      2. When open DevUI link, please wait for 10s to load the
                      conversations. <br />
                      3. If you want to share the conversation to more member to
                      debug. Please click share and copy the link to others.{" "}
                      <br />
                      4. If you have more question, please check{" "}
                      <a
                        href={commonIssueWikiLink}
                        target="_blank"
                        rel="noreferrer"
                      >
                        this wiki
                      </a>
                      . <br />
                    </p>
                  )}
                  {!devUILink.isNew && (
                    <p>
                      1. The conversation will be expired 24 hours after the job
                      complete. If this devUI link expired, please download raw
                      response file using Download {">"} Scrape Data &
                      Conversation, open DevUI link and upload the conversation
                      file for debug. <br />
                      2. Only the owner of the link can access and debug the
                      conversation, if you are using Test tenant, please log
                      into this tenant to debug. <br />
                      3. If you want to share the conversation to more member to
                      debug. Please click share and copy the link to others.{" "}
                      <br />
                      4. If you have more question, please check{" "}
                      <a
                        href={commonIssueWikiLink}
                        target="_blank"
                        rel="noreferrer"
                      >
                        this wiki
                      </a>
                      .
                    </p>
                  )}
                </p>
              </div>
            ) : error ? (
              <p>
                <strong style={{ color: tokens.colorStatusWarningForeground1 }}>
                  {error}
                </strong>
                <br />
                If you have more question, please check{" "}
                <a href={commonIssueWikiLink} target="_blank" rel="noreferrer">
                  this wiki
                </a>
                .
              </p>
            ) : (
              <p> Query conversation record...</p>
            )}
          </DialogContent>
          <DialogActions>
            <DialogTrigger disableButtonEnhancement>
              <Button appearance="secondary">Close</Button>
            </DialogTrigger>
            {devUILink && (
              <Button
                appearance="primary"
                onClick={() => {
                  if (devUILink) {
                    window.open(devUILink.link, "_blank");
                  }
                }}
              >
                Open In New Tab
              </Button>
            )}
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
});
