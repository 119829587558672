import {
  Dropdown,
  Field,
  makeStyles,
  Option,
  shorthands,
  tokens,
} from "@fluentui/react-components";

import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { updateProductFilterAction } from "../../../actions/jobActions";
import { refreshProductSettings } from "../../../actions/productSettingsActions";
import {
  getBuildInProducts,
  getCurrentPublishedProducts,
  getCurrentSelectedProduct,
} from "../../../selectors/productSelectors";

const useStyles = makeStyles({
  block: {
    display: "flex",
    flexDirection: "column",
    ...shorthands.flex(1),
    width: "100%",
    ...shorthands.gap("8px"),
  },
  blockTitle: {
    fontFamily: tokens.fontFamilyBase,
    fontSize: "16px",
    fontWeight: 600,
    lineHeight: "22px",
  },
  dropdownMenu: {
    backgroundColor: "#F8F7F9",
  },
  listbox: {
    maxHeight: "300px",
  },
});

export const JobQuickFilterView = observer(() => {
  const styles = useStyles();

  useEffect(() => {
    refreshProductSettings();
  }, []);

  const clickType = (type: string) => {
    updateProductFilterAction(type);
  };

  const renderRegisteredProducts = () => {
    const builtInProducts = getBuildInProducts.get();
    const publishedProducts = getCurrentPublishedProducts.get();
    const products = builtInProducts.concat(publishedProducts);
    const selectedProduct = getCurrentSelectedProduct.get();
    const selecteOptions = selectedProduct ? [selectedProduct.id] : [];

    return (
      <Dropdown
        className={styles.dropdownMenu}
        listbox={{ className: styles.listbox }}
        placeholder="Select a product"
        value={selectedProduct?.displayName}
        selectedOptions={selecteOptions}
        onOptionSelect={(ev, data) => {
          const value = data.optionValue;
          if (value) {
            clickType(value);
          }
        }}
        data-testid="mock-template-combobox"
      >
        {products.map((product) => (
          <Option key={product.id} value={product.id}>
            {product.displayName}
          </Option>
        ))}
      </Dropdown>
    );
  };

  return (
    <Field label={<span className={styles.blockTitle}>Product</span>} required>
      {renderRegisteredProducts()}
    </Field>
  );
});
