import { observer } from "mobx-react-lite";
import { isFeatureEnabled } from "../../../../../selectors/features";
import { refreshCustomQuerySets } from "../../../helpers/jobCreationHelper";
import {
  updateCustomizedQuerySets,
  updatePublicLMChecklistSets,
} from "../../../mutators/jobCreationFileMutators";
import { jobCreationFileStore } from "../../../store/jobCreationFileStore";
import { jobStore } from "../../../store/jobStore";
import { JobConfigure3pPluginsBlockView } from "../components/Job3pPluginsConfigurationBlockView";
import { JobEvalTimeOverrideView } from "../components/JobEvalTimeOverrideView";
import { JobGPTIdentifierView } from "../components/JobGPTIdentifierView";
import { JobLLMChecklistDatasetView } from "../components/JobLLMChecklistDatasetView";
import { JobMetricsBlockView } from "../components/JobMetricsBlockView";
import { JobPairExperimentationView } from "../components/JobPairExperimentationView";
import { JobSbsExpContainer } from "../components/JobSBSExpContainer";
import { JobSelectedFileView } from "../components/JobSelectedFileView";
import { JobSelectSyntheticUserView } from "../components/JobSelectSyntheticUserView";
import { JobUserIdView } from "../components/JobUserIdView";
import { useJobCreationStyles } from "./useJobCreationStyles";
import { isSyntheticUserEnabled } from "../../../helpers/jobSyntheticUserHelper";

const renderDivider = () => {
  const styles = useJobCreationStyles();
  return <div className={styles.divider} />;
};
export const NormalTemplateRender = observer(() => {
  const selectedTemplate = jobStore.selectedTemplate;
  if (selectedTemplate === undefined) {
    return <></>;
  }
  return (
    <>
      <JobMetricsBlockView />
      {isSyntheticUserEnabled() && <JobSelectSyntheticUserView />}
      {!isSyntheticUserEnabled() && (
        <>
          <JobSelectedFileView
            customQuerySets={jobCreationFileStore.customQuerySets}
            setCustomQuerySets={updateCustomizedQuerySets}
            refreshCustomQuerySets={refreshCustomQuerySets}
          />
          <JobLLMChecklistDatasetView
            publicLMChecklistSets={jobCreationFileStore.publicLMChecklistSets}
            customLMChecklistSets={jobCreationFileStore.customLMChecklistSets}
            setPublicLMChecklistSets={updatePublicLMChecklistSets}
          />
          <JobUserIdView />
        </>
      )}
      {isFeatureEnabled("eval-time-override") && <JobEvalTimeOverrideView />}
      {renderDivider()}
      <JobPairExperimentationView />
      {!isFeatureEnabled("gpt-agent-list") && <JobGPTIdentifierView />}
      <JobSbsExpContainer />
      <JobConfigure3pPluginsBlockView />
      {renderDivider()}
    </>
  );
});
