import { observer } from "mobx-react-lite";
import { updateJsonStringAction } from "../../../actions/jobActions";
import { getNewVersionRules } from "../../../helpers/editorRuleHelper";
import { jobStore } from "../../../store/jobStore";
import { JSONEditorView } from "../../Other/JSONEditorView";

export const JsonTemplateRender = observer(() => {
  return (
    <JSONEditorView
      key="general_json_editor_JsonTemplateRender"
      object={jobStore.configuration}
      path={""}
      rules={getNewVersionRules()}
      onChange={(configs: string) => {
        updateJsonStringAction(JSON.stringify(configs, null, 2));
      }}
    />
  );
});
