import {
  Button,
  Combobox,
  Option,
  makeStyles,
  shorthands,
} from "@fluentui/react-components";
import {
  ArrowDownload24Filled,
  ArrowDownload24Regular,
  ClipboardDataBar24Filled,
  ClipboardDataBar24Regular,
  Clock24Filled,
  Clock24Regular,
  bundleIcon,
} from "@fluentui/react-icons";
import React, { useEffect } from "react";

import type { ComboboxProps } from "@fluentui/react-components";
import { observer } from "mobx-react-lite";
import { getExpNames } from "sydneyeval-shared";
import { Tip } from "../../../../../components/Shared/Tip";
import { telemetryHelper } from "../../../../../helpers/telemetryHelper";
import type { Job } from "../../../models/Job";
import { isMultiturnJob } from "../../../selectors/jobDetailHelper";
import { JavisDialog } from "../../Dialog/JavisDialog";

const useStyles = makeStyles({
  rowContainer: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    ...shorthands.padding("0px", "0px", "16px", "0px"),
    gap: "16px",
  },
  title: {
    fontSize: "18px",
    fontWeight: 600,
    lineHeight: "24px",
    ...shorthands.padding("0px", "24px", "0px", "0px"),
  },
  buttonContainer: {
    display: "flex",
    alignItems: "center",
    marginLeft: "auto",
  },
  verticalDivider: {
    color: "#ccc",
    height: "20px",
    marginLeft: "5px",
    marginRight: "5px",
  },
  listbox: {
    maxHeight: "200px",
  },
});

const DownloadIcon = bundleIcon(ArrowDownload24Filled, ArrowDownload24Regular);
const BoardIcon = bundleIcon(Clock24Filled, Clock24Regular);
const SummaryIcon = bundleIcon(
  ClipboardDataBar24Filled,
  ClipboardDataBar24Regular,
);

interface DetailCardHeaderProps {
  readonly job: Job;
  readonly title: string;
  readonly jobId: string;
  readonly jobName: string;
  readonly segments?: string[];
  readonly selectedSegment?: string;
  readonly onSegmentUpdated?: (segment: string) => void;

  readonly hasView3SSummaryDashboardButton?: boolean;
  readonly hasViewPerfInfoButton?: boolean;
  readonly hasDownloadMetricsButton?: boolean;

  readonly onDownloadMetricsClick?: () => void;
}

export const DetailCardHeader = observer((props: DetailCardHeaderProps) => {
  const styles = useStyles();
  const [matchSegments, setMatchSegments] = React.useState<string[]>(
    props.segments ?? [],
  );

  const [isPerfLinkDialogOpen, setIsPerfLinkDialogOpen] = React.useState(false);
  const [javisLink, setJavisLink] = React.useState("");
  const [linkName, setLinkName] = React.useState("");
  useEffect(() => {
    setMatchSegments(props.segments ?? []);
  }, [props.segments]);

  const onChange: ComboboxProps["onChange"] = (event) => {
    const value = event.target.value.trim();
    const matches = (props.segments ?? []).filter((option) =>
      option.toLowerCase().includes(value.toLowerCase()),
    );
    setMatchSegments(matches);
  };

  const onOpenPerfInfoClick = () => {
    const expNames = getExpNames(props.job.Settings ?? "");
    const jobPerfLink = `https://aka.ms/bizchatperfcopilotflights?overrides=[{"query":"//*[id='SourceB']","key":"value","replacement":"eval__${expNames.experiment}_${props.jobId}"},{"query":"//*[id='SourceA']","key":"value","replacement":"eval__${expNames.control}_${props.jobId}"}]`;
    setJavisLink(jobPerfLink);
    setIsPerfLinkDialogOpen(true);
    setLinkName("Perf Information");
    telemetryHelper.logUserActionEvent("ClickMChatPerfInfoLink");
  };

  const onOpen3SSummaryDashboardClick = () => {
    const dashboard = `https://portal.microsoftgeneva.com/dashboard/SubstrateSearchService/MChat/Eval%2520Job%2520Dashboard?overrides=%5b%7b%22query%22:%22//%2a%5bid%3D%27JobId%27%5d%22%2c%22key%22:%22value%22%2c%22replacement%22:%22${props.jobId}%22%7d%5d%20`;
    setJavisLink(dashboard);
    setIsPerfLinkDialogOpen(true);
    setLinkName("3S Summary Dashboard");
    telemetryHelper.logUserActionEvent("ClickMChat3SSummaryDashboardLink");
  };

  const onOptionSelect: ComboboxProps["onOptionSelect"] = (_ev, data) => {
    if (data.optionValue !== undefined) {
      props.onSegmentUpdated?.(data.optionValue ?? "All");
    }
  };

  const isMultiturn =
    isMultiturnJob.get() && props.title === "Flight Review Metric";

  return (
    <div className={styles.rowContainer}>
      <span className={styles.title}>{props.title}</span>
      {props.segments && (
        <Combobox
          listbox={{ className: styles.listbox }}
          placeholder="Search an segment"
          defaultValue={props.selectedSegment ?? props.segments[0]}
          onChange={onChange}
          onOptionSelect={onOptionSelect}
        >
          {matchSegments.map((segment) => (
            <Option key={segment}>{segment}</Option>
          ))}
          {matchSegments.length === 0 ? (
            <Option disabled key="no-results" text="">
              No results found
            </Option>
          ) : null}
        </Combobox>
      )}
      {isMultiturn && (
        <Combobox
          data-testid="turn-filter"
          className={styles.listbox}
          placeholder="Last Turn"
          positioning={"below"}
        >
          <Option key={"last-turn"} value={"last-turn"}>
            Last Turn
          </Option>
        </Combobox>
      )}
      <div className={styles.buttonContainer}>
        {props.hasView3SSummaryDashboardButton && (
          <Tip content={"3S Summary dashboard"} relationship={"label"}>
            <Button
              title={"3S Summary dashboard"}
              appearance="transparent"
              icon={<SummaryIcon />}
              onClick={onOpen3SSummaryDashboardClick}
            >
              3S Summary dashboard
            </Button>
          </Tip>
        )}

        {props.hasViewPerfInfoButton && (
          <Tip content={"Perf information"} relationship={"label"}>
            <Button
              title={"Perf information"}
              appearance="transparent"
              icon={<BoardIcon />}
              onClick={onOpenPerfInfoClick}
            >
              Perf information
            </Button>
          </Tip>
        )}

        {props.hasDownloadMetricsButton && (
          <Tip content={"Download Metrics"} relationship={"label"}>
            <Button
              title={"Download Metrics"}
              appearance="transparent"
              icon={<DownloadIcon />}
              onClick={props.onDownloadMetricsClick}
            >
              Download Metrics
            </Button>
          </Tip>
        )}
        <JavisDialog
          isOpen={isPerfLinkDialogOpen}
          onComplete={() => setIsPerfLinkDialogOpen(false)}
          link={javisLink}
          linkName={linkName}
        />
      </div>
    </div>
  );
});
