import {
  Button,
  Link as FluentLink,
  Input,
  makeStyles,
  Menu,
  MenuItemRadio,
  MenuList,
  MenuPopover,
  MenuTrigger,
  shorthands,
  Text,
  tokens,
} from "@fluentui/react-components";
import {
  AddCircle24Filled,
  Calendar16Regular,
  Edit16Regular,
  Home20Filled,
  Home20Regular,
  List20Filled,
  List20Regular,
} from "@fluentui/react-icons";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { Link } from "react-router-dom";
import { ResponsiveRow } from "../../../../components/Responsive/ResponsiveRow";
import { ShadowABExperimentSetupDialog } from "../../../../components/Shared/shadowABExperimentSetupDialog/ShadowABExperimentSetupDialog";
import { FilterButton } from "../../../OfflineEval/components/Other/FilterButton";
import type { Experiment } from "../../models/Experiment";
import { getUserAlias } from "../../utils/account";
import { System_Owner_Of_Create_EXP_API } from "../../utils/contants";
import { formatDatetime } from "../../utils/format";
import { ExperimentListTable } from "./ExperimentListTable";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    height: "100%",
  },
  filter: {
    display: "flex",
    justifyContent: "flex-start",
    marginTop: "16px",
    marginBottom: "16px",
    alignItems: "center",
    flex: "0 0 18px",
    gap: "8px",
  },
  columnContainer: {
    display: "flex",
    flexDirection: "column",
  },
  functionBarContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    flexWrap: "wrap-reverse",
    justifyContent: "space-between",
  },
  rowContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    ...shorthands.gap("8px"),
  },
  newExperimentButton: {
    ...shorthands.padding("10px", "12px"),
    ...shorthands.borderRadius(tokens.borderRadiusLarge),
    width: "186px",
    height: "44px",
    color: "#fff",
    fontFamily: tokens.fontFamilyBase,
    fontStyle: "normal",
    fontSize: "16px",
  },
  content: {
    display: "flex",
    flexDirection: "column",
  },
  list: {
    marginTop: "16px",
    overflowX: "auto",
  },
  label: {
    fontFamily: tokens.fontFamilyBase,
    fontWeight: 600,
    fontSize: "12px",
    lineHeight: "16px",
    fontStyle: "normal",
    color: "#605E5C",
    marginTops: "4px",
    marginBottom: "4px",
  },
  combobox: {
    minWidth: "184px",
    width: "100%",
  },
  experimentContainer: {
    minHeight: "76px",
    backgroundColor: "#FFF",
    borderRadius: "6px",
  },
  truncateContent: {
    width: "100%",
    overflow: "hidden",
  },
  truncateLink: {
    display: "inline-block",
    width: "100%",
    textOverflow: "ellipsis",
    overflow: "hidden",
  },
  loading: {
    width: "100%",
    height: "100%",
  },
});

const timeRangeSelections = [
  {
    key: "7",
    text: "Last 1 week",
  },
  {
    key: "14",
    text: "Last 2 weeks",
  },
  {
    key: "30",
    text: "Last 1 month",
  },
  {
    key: "90",
    text: "Last 3 months",
  },
  {
    key: "180",
    text: "Last 6 months",
  },
  {
    key: "365",
    text: "Last 1 year",
  },
];

export const ExperimentList = observer(() => {
  const myAlias = getUserAlias();
  const styles = useStyles();
  const [isNewShadowABDialogOpen, setIsNewShadowABDialogOpen] =
    React.useState(false);
  const [searchName, setSearchName] = React.useState("");
  const [creator, setCreator] = React.useState("");
  const [filterTag, setFilterTag] = React.useState("All");
  const [timeRange, setTimeRange] = React.useState<number>(14);
  const timeRangeText = React.useMemo(() => {
    return (
      timeRangeSelections.find(
        (selection) => selection.key === timeRange.toString(),
      )?.text ?? ""
    );
  }, [timeRange]);
  const columns = React.useMemo(() => {
    return [
      {
        key: "experimentName",
        title: "Experiment name",
        minWidth: 200,
        width: 240,
        render: (item: Experiment) => (
          <Text
            className={styles.truncateContent}
            truncate={true}
            title={item.experimentName}
            wrap={false}
            block={true}
          >
            <Link
              to={`/shadowabexperiment/${item.experimentId}?name=${item.experimentName}&createTime=${item.experimentCreateTime}`}
            >
              <FluentLink className={styles.truncateLink}>
                {item.experimentName}
              </FluentLink>
            </Link>
          </Text>
        ),
      },
      ...(filterTag === "All"
        ? [
            {
              key: "owner",
              title: "Created by",
              minWidth: 200,
              width: 240,
              render: (item: Experiment) => {
                const owners = (item.owners ?? [])
                  .filter((v) => !System_Owner_Of_Create_EXP_API.includes(v))
                  .join(",");
                return (
                  <Text
                    className={styles.truncateContent}
                    truncate={true}
                    title={owners}
                    wrap={false}
                    block={true}
                  >
                    {owners}
                  </Text>
                );
              },
            },
          ]
        : []),
      {
        key: "experimentCreateTime",
        title: "Created at",
        minWidth: 160,
        width: 200,
        render: (item: Experiment) => (
          <>{formatDatetime(item.experimentCreateTime ?? "")}</>
        ),
      },
    ];
  }, [filterTag]);
  const experimentFilter = React.useMemo(() => {
    return {
      owner: filterTag === "My" ? myAlias : creator,
      name: searchName,
      timeRange: timeRange,
    };
  }, [myAlias, searchName, timeRange, creator, filterTag]);
  return (
    <>
      <div className={styles.filter}>
        <FilterButton
          value="All"
          text="All experiments"
          selectedTab={filterTag}
          activeIcon={<List20Filled />}
          inactionIcon={<List20Regular />}
          clickTab={() => {
            setFilterTag("All");
          }}
        />
        <FilterButton
          value="My"
          text="My experiments"
          selectedTab={filterTag}
          activeIcon={<Home20Filled />}
          inactionIcon={<Home20Regular />}
          clickTab={() => {
            setFilterTag("My");
          }}
        />
      </div>
      <div className={styles.content}>
        <div className={styles.functionBarContainer}>
          <div className={styles.rowContainer}>
            <ResponsiveRow
              maxColumnCount={4}
              maxColumnCountSmall={1}
              columnGap={8}
            >
              <div className={styles.columnContainer}>
                <label className={styles.label}>Experiment name</label>
                <Input
                  placeholder="Type experiment name"
                  value={searchName}
                  onChange={(_e, data) => {
                    setSearchName(data.value);
                  }}
                />
              </div>
              {filterTag !== "My" && (
                <div className={styles.columnContainer}>
                  <label className={styles.label}>Created by</label>
                  <Input
                    placeholder="Type creator alias"
                    value={creator}
                    onChange={(_e, data) => {
                      setCreator(data.value);
                    }}
                  />
                </div>
              )}
              <div className={styles.columnContainer}>
                <label className={styles.label}>Time range</label>
                <Menu
                  checkedValues={{ timeRange: [String(timeRange)] }}
                  onCheckedValueChange={(_e, data) => {
                    setTimeRange(Number(data.checkedItems));
                  }}
                >
                  <MenuTrigger>
                    <Input
                      contentBefore={<Calendar16Regular />}
                      contentAfter={<Edit16Regular />}
                      value={timeRangeText}
                      readOnly={true}
                    />
                  </MenuTrigger>
                  <MenuPopover>
                    <MenuList>
                      {timeRangeSelections.map((selection) => (
                        <MenuItemRadio
                          key={selection.key}
                          name="timeRange"
                          value={selection.key}
                        >
                          {selection.text}
                        </MenuItemRadio>
                      ))}
                    </MenuList>
                  </MenuPopover>
                </Menu>
              </div>
            </ResponsiveRow>
          </div>
          <Button
            className={styles.newExperimentButton}
            appearance="primary"
            icon={
              <AddCircle24Filled
                style={{
                  opacity: 0.5,
                }}
              />
            }
            onClick={() => {
              setIsNewShadowABDialogOpen(true);
            }}
          >
            New Shadow AB
          </Button>
        </div>
        <div className={styles.list}>
          <ExperimentListTable
            columns={columns}
            filter={experimentFilter}
            defaultPageSize={10}
            containerStyle={styles.experimentContainer}
          />
        </div>
      </div>
      {isNewShadowABDialogOpen && (
        <ShadowABExperimentSetupDialog
          isOpen={isNewShadowABDialogOpen}
          onDismiss={() => {
            setIsNewShadowABDialogOpen(false);
          }}
        />
      )}
    </>
  );
});
