import { observer } from "mobx-react-lite";
import * as React from "react";
import { Exp_Workspace_Id } from "../../../../constants/constants";
import { getExperiments } from "../../../../helpers/apiHelper";
import { perfWrapper } from "../../../../helpers/telemetryHelper";
import type { Experiment } from "../../models/Experiment";
import { PaginationTable } from "../common/PaginationTable";

interface IExperimentListTableProps {
  columns?: Array<{
    key: string;
    title: string;
    width?: number;
    minWidth?: number;
    render?: (data: Experiment) => React.ReactNode;
  }>;
  onRowClick?: (data: Experiment) => void;
  selectionKeys?: string[];
  filter: {
    name?: string;
    owner?: string;
    timeRange?: number;
  };
  defaultPageSize?: number;
  containerStyle?: string;
}

export const ExperimentListTable = observer(
  (props: IExperimentListTableProps) => {
    const {
      defaultPageSize,
      containerStyle,
      columns,
      filter,
      onRowClick,
      selectionKeys,
    } = props;
    const [experiments, setExperiments] = React.useState<Experiment[]>([]);
    const [listLoading, setListLoading] = React.useState(false);
    const [page, setPage] = React.useState(1);
    const [pageSize, setPageSize] = React.useState(defaultPageSize ?? 10);
    const filterExperiments = React.useMemo(() => {
      return experiments
        .filter((exp) => {
          if (
            filter.owner &&
            !exp.owners?.find(
              (v) =>
                filter.owner &&
                v?.toLowerCase().includes(filter.owner.toLowerCase()),
            )
          ) {
            return false;
          }
          if (
            filter.name &&
            !exp.experimentName
              ?.toLowerCase()
              .includes(filter.name.toLowerCase())
          ) {
            return false;
          }
          if (filter.timeRange && exp.experimentCreateTime) {
            const diff =
              new Date().getTime() -
              new Date(exp.experimentCreateTime).getTime();
            if (diff > filter.timeRange * 24 * 60 * 60 * 1000) {
              return false;
            }
          }
          return true;
        })
        .sort(
          (a, b) =>
            new Date(b.experimentCreateTime ?? 0).getTime() -
            new Date(a.experimentCreateTime ?? 0).getTime(),
        );
    }, [experiments, filter]);
    const showExperiments = React.useMemo(() => {
      return filterExperiments.slice((page - 1) * pageSize, page * pageSize);
    }, [page, pageSize, filterExperiments]);
    React.useEffect(() => {
      const params = {
        workspaceId: Exp_Workspace_Id,
        query: {},
      };
      setListLoading(true);
      perfWrapper(
        "LoadExperimentList",
        getExperiments(params)
          .then((exps) => {
            setListLoading(false);
            setExperiments(
              exps.filter(
                (exp) => exp.managementGroup === "/msaiexp/bizchatshadow",
              ),
            );
          })
          .catch(() => {
            setListLoading(false);
          }),
      );
    }, []);
    const listProps = React.useMemo(() => {
      return {
        header: columns ?? [],
        data: showExperiments,
        keyName: "experimentId",
        loading: listLoading,
        pagination: {
          pageSize,
          totalNum: filterExperiments.length,
          page,
        },
        options: {
          innerBorder: true,
          containerStyle,
          selection: !!selectionKeys,
        },
        onRowClick,
        selectionKeys,
        onPageChange: (willPage: number) => {
          setPage(willPage);
        },
        onPageSizeChange: (willPageSize: number) => {
          setPage(1);
          setPageSize(willPageSize);
        },
      };
    }, [
      columns,
      filterExperiments,
      showExperiments,
      listLoading,
      onRowClick,
      selectionKeys,
      containerStyle,
      pageSize,
      page,
    ]);
    return <PaginationTable<Experiment> {...listProps} />;
  },
);
