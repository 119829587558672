import {
  Button,
  Dialog,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  Field,
  Link as FluentLink,
  Label,
  MessageBar,
  MessageBarActions,
  MessageBarBody,
  MessageBarTitle,
  Radio,
  RadioGroup,
  Text,
  Toaster,
  makeStyles,
  useId,
} from "@fluentui/react-components";
import { DismissRegular } from "@fluentui/react-icons";
import { observer } from "mobx-react-lite";
import * as React from "react";
import type { Job } from "../../../partners/OfflineEval/models/Job";
import { SharedToasterPrefix } from "../../Wrappers/ToasterProvider";
import { ExistingExperimentContent } from "./ExistingExperimentContent";
import { SetupNewExperimentByJob } from "./SetupNewExperimentByJob";
import { SetupNewExperimentFromList } from "./SetupNewExperimentFromList";

const useStyle = makeStyles({
  root: {
    maxWidth: "720px",
  },
  title: {
    width: "100%",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  dialogContent: {
    margin: "16px 0 0",
  },
  fieldLabel: {
    fontWeight: 500,
    margin: "8px 0",
  },
});

export const ShadowABExperimentSetupDialog = observer(
  (props: { isOpen: boolean; onDismiss: () => void; upstreamJob?: Job }) => {
    const { isOpen, onDismiss, upstreamJob } = props;
    const styles = useStyle();
    const [experimentOperation, setExperimentOperation] =
      React.useState("create");

    const [createErrorMessage, setCreateErrorMessage] = React.useState("");

    const successToasterId = useId(
      SharedToasterPrefix,
      "ShadowABExperimentSetupSuccessToast",
    );
    return (
      <>
        {isOpen && (
          <Dialog
            modalType="modal"
            open={isOpen}
            onOpenChange={(_, data) => {
              if (data.open === true) {
                onDismiss?.();
              }
            }}
          >
            <DialogSurface className={styles.root}>
              <DialogBody>
                <DialogTitle
                  className={styles.title}
                  data-testid="dialog-title"
                >
                  {"New Shadow AB"}
                  {upstreamJob ? ` from ${upstreamJob.JobName}` : ""}
                </DialogTitle>
                <DialogContent className={styles.dialogContent}>
                  {createErrorMessage && (
                    <MessageBar intent="error" layout="multiline">
                      <MessageBarBody>
                        <MessageBarTitle>
                          Failed to Create Experiment
                        </MessageBarTitle>
                        {createErrorMessage}{" "}
                        <Text block={true}>
                          Go to{" "}
                          <FluentLink
                            href="https://exp.microsoft.com/??workspaceId=${Exp_Workspace_Id}&group=/msaiexp/bizchatshadow&pivot=new"
                            target="_blank"
                          >
                            Control Tower
                          </FluentLink>{" "}
                          to create experiment manually.{" "}
                        </Text>
                      </MessageBarBody>
                      <MessageBarActions
                        containerAction={
                          <Button
                            onClick={() => setCreateErrorMessage("")}
                            aria-label="dismiss"
                            appearance="transparent"
                            icon={<DismissRegular />}
                          />
                        }
                      />
                    </MessageBar>
                  )}
                  <Field>
                    <Label className={styles.fieldLabel}>
                      Select experiment
                    </Label>
                    <RadioGroup
                      layout="horizontal"
                      onChange={(_, data) => setExperimentOperation(data.value)}
                    >
                      <Radio
                        value="create"
                        label="Create new experiment"
                        defaultChecked={true}
                      />
                      <Radio
                        data-testid="select-existing"
                        value="exist"
                        label="Select existing experiment"
                      />
                    </RadioGroup>
                  </Field>
                  {experimentOperation === "create" &&
                    (upstreamJob ? (
                      <SetupNewExperimentByJob
                        toasterId={successToasterId}
                        onDismiss={onDismiss}
                        onErrorMessage={(msg: string) =>
                          setCreateErrorMessage(msg)
                        }
                        upstreamJob={upstreamJob}
                      />
                    ) : (
                      <SetupNewExperimentFromList onDismiss={onDismiss} />
                    ))}
                  <ExistingExperimentContent
                    show={experimentOperation === "exist"}
                    onDismiss={onDismiss}
                  />
                </DialogContent>
              </DialogBody>
            </DialogSurface>
          </Dialog>
        )}
        <Toaster toasterId={successToasterId} />
      </>
    );
  },
);
