import { orchestrator } from "satcheljs";
import { executeJobPreVerification } from "../actions/jobActions";
import {
  getOptionSets,
  getUserIds,
  handleValidationError,
  processResult,
  skipValidationStep,
  validateJobAsync,
} from "../helpers/jobPreValidationHelper";
import { JobPreValidationResults } from "../models/JobPreValidationStep";
import {
  updateIsFinishedVerifing,
  updateJobPrevalidationProgressbarValue,
  updateJobPrevalidationToastBody,
  updateJobPrevalidationToastTitle,
  updateJobValidationStepConfigInfo,
  updateJobValidationStepStatus,
} from "../mutators/jobPreValidationMutators";
import { getExpConfigs, getUserId } from "../selectors/getJobPropV2";
import { jobPreValidationStore } from "../store/jobPreValidationStore";

const executeJobPreValidationStep = async (stepIndex: number) => {
  const expConfigs = getExpConfigs.get();
  const userid = getUserId.get();

  if (!userid || !expConfigs) {
    return skipValidationStep(
      stepIndex,
      "User ID or experiment configurations are undefined.",
    );
  }
  const userIds = await getUserIds();

  if (!userIds.includes(userid)) {
    userIds.push(userid);
  }

  const config = expConfigs.find(
    (exp) =>
      exp.exp_name ===
      jobPreValidationStore.jobPreValidationSteps[stepIndex].stepName,
  );

  if (!config) {
    return skipValidationStep(stepIndex, "Configuration not found.");
  }

  const optionSets = getOptionSets(config);
  updateJobValidationStepConfigInfo(stepIndex, config);

  try {
    const results = await Promise.allSettled(
      userIds.map((userId) => validateJobAsync(config, userId, optionSets)),
    );

    const responseResults = JobPreValidationResults(
      [],
      "responseResultsParser",
    );

    // Using map and every
    const allSuccessful = results
      .map((result) => processResult(result, responseResults))
      .every((success) => success);

    updateJobValidationStepStatus(
      stepIndex,
      allSuccessful ? "scrapping passed" : "scrapping failed",
      responseResults,
    );
  } catch (error) {
    const errorInfo = error instanceof Error ? error.message : "Unknown error";
    handleValidationError(stepIndex, errorInfo);
  }
};

orchestrator(executeJobPreVerification, () => {
  const totalSteps = jobPreValidationStore.jobPreValidationSteps.length;
  let completedSteps = 0;
  updateJobPrevalidationToastTitle(
    `Job validation in progress, it will spend about 30 seconds `,
  );
  updateJobPrevalidationToastBody(
    `${completedSteps} of ${jobPreValidationStore.jobPreValidationSteps.length} completed`,
  );
  updateJobPrevalidationProgressbarValue(0);

  const updateProgress = () => {
    completedSteps += 1;
    updateJobPrevalidationToastBody(
      `${completedSteps} of ${jobPreValidationStore.jobPreValidationSteps.length} completed`,
    );
    const progress = (completedSteps / totalSteps) * 100;
    updateJobPrevalidationProgressbarValue(progress);
  };

  const promises = jobPreValidationStore.jobPreValidationSteps.map((_, index) =>
    executeJobPreValidationStep(index)
      .then(updateProgress)
      .catch((error) => {
        const errorInfo =
          error instanceof Error ? error.message : "Unknown error";
        handleValidationError(index, errorInfo);
      }),
  );

  Promise.all(promises).then(() => {
    updateIsFinishedVerifing(true);
  });
});
