import { computed } from "mobx";
import {
  getTemplateType as sharedGetTemplateType,
  TemplateType,
} from "sydneyeval-shared";
import { jobStore } from "../store/jobStore";

export const getTemplateType = computed(() => {
  return sharedGetTemplateType(jobStore.selectedTemplate?.Type);
});

const multiServiceTemplateNames = [
  "AppChat_Copilot_Evaluation_All_Metrics",
  "AppChat_Copilot_Evaluation_All_Metrics_for_test_tenant",
];
export const getMultiServiceTemplate = computed(() => {
  if (jobStore.selectedTemplate === undefined) {
    return false;
  }
  return multiServiceTemplateNames.includes(jobStore.selectedTemplate?.Name);
});

export const isMultiServiceTemplate = (templateName: string) => {
  if (templateName.includes("Product_")) {
    const templateNameSplit = templateName.split("_")[1];
    const subTemplate = templateName.replace(
      "Product_" + templateNameSplit + "_",
      "",
    );
    return multiServiceTemplateNames.includes(subTemplate);
  }
  return multiServiceTemplateNames.includes(templateName);
};

export const supportEvaluationType = computed(() => {
  return (
    (getTemplateType.get() === TemplateType.Normal ||
      getMultiServiceTemplate.get()) &&
    jobStore.selectedTemplate !== undefined
  );
});
