import { createStore } from "satcheljs";
import { constStr, or } from "sydneyeval-shared";
import type { RenderManifestItem } from "../../../constants/renderManifest";
import type { MetricsData } from "../helpers/metricsHelper";
import type {
  BingDiagnosisMetricsData,
  BingDiagnosisSBSView,
  BingDiagnosisSummary,
} from "../models/BingDiagnosisFile";
import type {
  BingControlVsTreatment,
  BingJobFile,
} from "../models/BingJobFile";
import type { Metric } from "../models/GeneralMetricsResponse";
import type { Job } from "../models/Job";
import type { SBSLink } from "../models/JobSBSExpResult";
import type { ResultPageLoadingMessage } from "../orchestrators/resultOrchestrators";

export const ResultScenarioType = or([
  constStr("Scorecard Comparison"),
  constStr("Metric Diagnosis"),
  constStr("LM Checklist"),
]);

export type ResultScenarioType = ReturnType<typeof ResultScenarioType>;

export const BingScenarioType = or([
  constStr("Scorecard Comparison"),
  constStr("Metric Diagnosis"),
]);

export type BingScenarioType = ReturnType<typeof BingScenarioType>;

export interface DiagnosisSortColumn {
  column: string;
  direction: "asc" | "desc";
}

export interface LoadedCitationLink {
  dataset: string;
  currentToneFilter: string;
  baselineCitationMap: Map<string, Map<string, string>>;
  treatmentCitationMap: Map<string, Map<string, string>>;
}

interface IJobResult {
  templateType: string | undefined;
  resultJob: Job | undefined;
  loadingQueue: ResultPageLoadingMessage[];
  scenario: ResultScenarioType;
  // MChat render data
  resultSegments: string[];
  sourceMap: Map<string, Metric[]>;
  sbsLinks: SBSLink[] | undefined;
  // OPG RAI render data
  manifestItem: RenderManifestItem | undefined;
  // Bing Job output files
  seletedBingFile: string | undefined;
  downloadedBingTables: Map<string, any[]> | undefined;
  bingJobOutputFiles: BingJobFile[] | undefined;
  controlTreatmentPairs: BingControlVsTreatment[] | undefined;
  summaryTable: any[] | undefined;
  conciseTable: any[] | undefined;
  answerTriggerData: any[] | undefined;
  pluginTriggerData: any[] | undefined;
  extensionTriggerData: Record<string, string>[] | undefined;
  diagnosisSummary: BingDiagnosisSummary | undefined;
  diagnosisSummaries: Map<string, BingDiagnosisSummary>;
  loadedMetrics: Map<string, BingDiagnosisMetricsData>;
  loadedSBSViews: Map<string, BingDiagnosisSBSView>;
  loadedCitationLinks: Map<string, LoadedCitationLink>;
  diagnosisSortBy: DiagnosisSortColumn | undefined;
  // Shared
  selectedFilter: Map<string, string | undefined>;

  // MChat perf metrics
  perfMetrics: MetricsData[];
}

export const initJobResultStore: IJobResult = {
  templateType: undefined,
  resultJob: undefined,
  loadingQueue: [],
  scenario: "Scorecard Comparison",
  resultSegments: [],
  sbsLinks: undefined,
  sourceMap: new Map<string, Metric[]>(),
  manifestItem: undefined,
  selectedFilter: new Map<string, string | undefined>(),
  summaryTable: undefined,
  conciseTable: undefined,
  bingJobOutputFiles: undefined,
  downloadedBingTables: undefined,
  seletedBingFile: undefined,
  controlTreatmentPairs: undefined,
  answerTriggerData: undefined,
  pluginTriggerData: undefined,
  extensionTriggerData: undefined,
  diagnosisSummary: undefined,
  diagnosisSummaries: new Map<string, BingDiagnosisSummary>(),
  loadedMetrics: new Map<string, BingDiagnosisMetricsData>(),
  loadedSBSViews: new Map<string, BingDiagnosisSBSView>(),
  diagnosisSortBy: undefined,
  loadedCitationLinks: new Map<string, LoadedCitationLink>(),
  perfMetrics: [],
};

export const resultStore = createStore<IJobResult>(
  "resultStore",
  Object.assign({}, initJobResultStore),
)();
