import {
  Body1,
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  Spinner,
} from "@fluentui/react-components";
import { observable } from "mobx";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { updateTokenByCode } from "../../helpers/apiHelper";
import { telemetryHelper } from "../../helpers/telemetryHelper";
import { updateCurrentPath } from "../../mutators/updateContributions";

export const redirectStore = observable({
  isUpdating: true,
  key: "",
  upn: "",
  error: "",
  code: "",
});

export const updateTokenByKeyIfNeeded = (code: string | null) => {
  if (code) {
    return updateTokenByCode({ code })
      .then((res) => {
        redirectStore.key = res.key;
        redirectStore.upn = res.upn;
        telemetryHelper.logDiagnosticEvent("RefreshTokenGenerated", {
          tid: res.tid,
          oid: res.oid,
          key: res.key,
        });
      })
      .catch((err) => {
        if (err instanceof Error) {
          redirectStore.error = err.message;
        } else {
          redirectStore.error = JSON.stringify(err);
        }
      })
      .finally(() => {
        redirectStore.code = code;
        window.history.replaceState(
          {},
          document.title,
          window.location.pathname,
        );
        redirectStore.isUpdating = false;
      });
  } else {
    return Promise.resolve().then(() => {
      redirectStore.isUpdating = false;
    });
  }
};

export const RedirectView = observer(() => {
  const { isUpdating, key, upn, error } = redirectStore;

  useEffect(() => {
    const code = new URLSearchParams(window.location.search).get("code");
    updateTokenByKeyIfNeeded(code);
  }, []);

  if (isUpdating) {
    return <Spinner label="Updating Refresh Token..." />;
  }

  const renderButtons = () => {
    return (
      <DialogActions>
        <Button
          appearance="primary"
          onClick={() => updateCurrentPath.bind(null, "/")()}
        >
          OK
        </Button>
      </DialogActions>
    );
  };

  if (!redirectStore.code) {
    return (
      <Dialog modalType="alert" open={true}>
        <DialogSurface>
          <DialogBody>
            <DialogTitle>{"Invalid Code"}</DialogTitle>
            <DialogContent>
              <Body1>{`The code is invalid, maybe related to sign-in failure, please try again.`}</Body1>
            </DialogContent>
            {renderButtons()}
          </DialogBody>
        </DialogSurface>
      </Dialog>
    );
  }

  if (redirectStore.error) {
    return (
      <Dialog modalType="alert" open={true}>
        <DialogSurface>
          <DialogBody>
            <DialogTitle>{"Update Refresh Token Failed"}</DialogTitle>
            <DialogContent>
              <Body1>{`Failed to update Refresh Token`}</Body1>
              <br />
              <Body1>{`Error Message: ${error}`}</Body1>
            </DialogContent>
            {renderButtons()}
          </DialogBody>
        </DialogSurface>
      </Dialog>
    );
  }

  return (
    <Dialog modalType="alert" open={true}>
      <DialogSurface>
        <DialogBody>
          <DialogTitle>{"SEVAL Refresh Token Updated"}</DialogTitle>
          <DialogContent>
            <Body1>
              {`SEVAL Refresh Token already updated for account `}
              <strong>{upn}</strong>
              {`, the key is `}
              <strong>{key}</strong>
              {`. `}
              <br />
              <br />
              {`Now you can use the target account to create job.`}
            </Body1>
          </DialogContent>
          {renderButtons()}
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
});
