import {
  Button,
  DialogActions,
  Field,
  Text,
  makeStyles,
} from "@fluentui/react-components";
import { Open20Regular } from "@fluentui/react-icons";
import { observer } from "mobx-react-lite";
import { Exp_Workspace_Id } from "../../../constants/constants";

const useStyle = makeStyles({
  tips: {
    color: "#616161",
    fontSize: "12px",
    margin: "8px 0 16px",
  },
  action: {
    marginTop: "16px",
  },
  longButton: {
    whiteSpace: "nowrap",
  },
});
export const SetupNewExperimentFromList = observer(
  (props: { onDismiss: () => void }) => {
    const { onDismiss } = props;
    const styles = useStyle();

    const handleSetupExperiment = () => {
      window.open(
        `https://exp.microsoft.com/a/CreateExp/SelectTemplate?workspaceId=${Exp_Workspace_Id}`,
        "_blank",
      );
      onDismiss();
    };
    return (
      <>
        <Field className={styles.tips}>
          <Text>
            You'll be guided to the Control Tower to complete the experiment
            setup.
          </Text>
        </Field>
        <DialogActions className={styles.action}>
          <Button
            data-testid="exp-link"
            className={styles.longButton}
            onClick={handleSetupExperiment}
            appearance="primary"
            icon={<Open20Regular />}
          >
            Go to Control Tower and start
          </Button>
          <Button onClick={() => onDismiss()} appearance="secondary">
            Cancel
          </Button>
        </DialogActions>
      </>
    );
  },
);
