export const formatMetricsNumber = (
  value?: number,
  options?: { percent?: boolean; sign?: boolean },
) => {
  if (value === undefined || isNaN(value)) {
    return "--";
  }
  let showValue = value;
  if (options?.percent) {
    showValue *= 100;
  }
  let result = "";
  if (showValue > 1000 || showValue < -1000) {
    result = `${(showValue / 1000).toFixed(2)}k`;
  } else if (showValue > 1 || showValue < -1) {
    result = showValue.toFixed(2);
  } else if (showValue < 1 && showValue > -1 && showValue !== 0) {
    result = showValue.toFixed(4);
  } else {
    result = showValue.toString();
  }
  if (options?.percent) {
    result = `${result}%`;
  }
  if (options?.sign && value > 0) {
    result = `+${result}`;
  }
  return result;
};

export const formatDatetime = (datetime: string) => {
  if (!datetime) {
    return "--";
  }
  const date = new Date(datetime);
  const dateFormatter = new Intl.DateTimeFormat(navigator.language, {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    timeZoneName: "short",
  });
  return dateFormatter.format(date);
};

export const formatDatetimeSimply = (datetime: string) => {
  if (!datetime) {
    return "--";
  }
  const date = new Date(datetime);
  const dateFormatter = new Intl.DateTimeFormat(navigator.language, {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour12: false,
  });
  return dateFormatter.format(date);
};

export const formatDatetimeRange = (start: string, end: string) => {
  if (!start || !end) {
    return "";
  }
  const startDate = new Date(start);
  const endDate = new Date(end);
  const diff = endDate.getTime() - startDate.getTime();
  if (diff < 1000) {
    return "0s";
  } else if (diff < 1000 * 60) {
    return `${Math.floor(diff / 1000)}s`;
  } else if (diff < 1000 * 60 * 60) {
    return `${Math.floor(diff / 1000 / 60)}m`;
  } else if (diff < 1000 * 60 * 60 * 24) {
    return `${Math.floor(diff / 1000 / 60 / 60)}h`;
  } else {
    const day = Math.floor(diff / 1000 / 60 / 60 / 24);
    return `${day}day${day > 1 ? "s" : ""}`;
  }
};

export const formatScorecardStatus = (state?: string) => {
  if (!state) {
    return "";
  }
  if (state === "SubmittingToJobPipeline") {
    return "Submitting";
  }
  if (state === "InJobPipeline") {
    return "Submitted";
  }
  return state;
};
