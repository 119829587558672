import { observer } from "mobx-react-lite";
import { updateJsonStringAction } from "../../../actions/jobActions";
import { getSkipScrapingRules } from "../../../helpers/editorRuleHelper";
import { updatePublicLMChecklistSets } from "../../../mutators/jobCreationFileMutators";
import { jobCreationFileStore } from "../../../store/jobCreationFileStore";
import { jobStore } from "../../../store/jobStore";
import { JSONEditorView } from "../../Other/JSONEditorView";
import { JobLLMChecklistDatasetView } from "../components/JobLLMChecklistDatasetView";
import { JobUploadFolderView } from "../components/JobUploadFolderView";

export const GeneralSkipScrapingRender = observer(() => {
  return (
    <>
      <JobUploadFolderView />
      <JSONEditorView
        key="general_json_editor_GeneralSkipScrapingRender"
        object={jobStore.configuration}
        path={""}
        rules={getSkipScrapingRules()}
        onChange={(configs: unknown) => {
          updateJsonStringAction(JSON.stringify(configs, null, 2));
        }}
      />
      <JobLLMChecklistDatasetView
        publicLMChecklistSets={jobCreationFileStore.publicLMChecklistSets}
        customLMChecklistSets={jobCreationFileStore.customLMChecklistSets}
        setPublicLMChecklistSets={updatePublicLMChecklistSets}
      />
    </>
  );
});
