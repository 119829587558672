import { mutatorAction } from "satcheljs";
import { jobCreationFileStore } from "../store/jobCreationFileStore";

export const updateCustomizedQuerySets = mutatorAction(
  "updateCustomizedQuerySets",
  (customQuerySets: string[]) => {
    jobCreationFileStore.customQuerySets = customQuerySets;
  },
);

export const updateCustomizedLMChecklistSets = mutatorAction(
  "updateCustomizedLMChecklistSets",
  (customLMChecklistSets: string[]) => {
    jobCreationFileStore.customLMChecklistSets = customLMChecklistSets;
  },
);

export const updatePublicLMChecklistSets = mutatorAction(
  "updatePublicLMChecklistSets",
  (publicLMChecklistSets: string[]) => {
    jobCreationFileStore.publicLMChecklistSets = publicLMChecklistSets;
  },
);
