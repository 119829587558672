"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FeatureIterationConnectParser = exports.NexusGetRouterParser = exports.ExpAPIRouterParser = void 0;
const typeHelper_1 = require("../tools/typeHelper");
exports.ExpAPIRouterParser = (0, typeHelper_1.object)({
    method: (0, typeHelper_1.optional)((0, typeHelper_1.or)([
        (0, typeHelper_1.constStr)("HEAD"),
        (0, typeHelper_1.constStr)("GET"),
        (0, typeHelper_1.constStr)("POST"),
        (0, typeHelper_1.constStr)("PUT"),
        (0, typeHelper_1.constStr)("DELETE"),
    ])),
    workspaceId: typeHelper_1.str,
    "api-version": typeHelper_1.str,
    query: (0, typeHelper_1.optional)(typeHelper_1.anyObject),
    payload: (0, typeHelper_1.optional)(typeHelper_1.anyObject),
    scorecardId: (0, typeHelper_1.optional)(typeHelper_1.str),
    featureId: (0, typeHelper_1.optional)(typeHelper_1.str),
});
exports.NexusGetRouterParser = (0, typeHelper_1.object)({
    query: (0, typeHelper_1.object)({
        owner: (0, typeHelper_1.optional)(typeHelper_1.str),
        experimentId: (0, typeHelper_1.optional)(typeHelper_1.str),
        progressionId: (0, typeHelper_1.optional)(typeHelper_1.str),
        scorecardId: (0, typeHelper_1.optional)(typeHelper_1.str),
    }),
    workspaceId: typeHelper_1.str,
});
exports.FeatureIterationConnectParser = (0, typeHelper_1.object)({
    iterationId: (0, typeHelper_1.optional)(typeHelper_1.str),
    featureId: (0, typeHelper_1.optional)(typeHelper_1.str),
    offlineJobId: (0, typeHelper_1.optional)(typeHelper_1.str),
    offlineJobName: (0, typeHelper_1.optional)(typeHelper_1.str),
    shadowABProgressionId: (0, typeHelper_1.optional)(typeHelper_1.str),
    shadowABProgressionName: (0, typeHelper_1.optional)(typeHelper_1.str),
    shadowABFeatureId: (0, typeHelper_1.optional)(typeHelper_1.str),
    shadowABFeatureName: (0, typeHelper_1.optional)(typeHelper_1.str),
});
