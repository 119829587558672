import {
  Apps24Regular,
  ArrowUploadFilled,
  ArrowUploadRegular,
  ClipboardError24Regular,
  ClipboardTextEdit24Regular,
  Flash24Regular,
  SettingsFilled,
  SettingsRegular,
  TextboxSettings24Regular,
  bundleIcon,
} from "@fluentui/react-icons";

import { compact } from "lodash";
import { RedirectView } from "../../components/Other/RedirectView";
import type { SubTabFeatureContribution } from "../../contribution/FeatureContribution";
import { FeatureContributionPageSize } from "../../contribution/FeatureContribution";
import type { FeatureDebugGroupContribution } from "../../contribution/FeatureDebugContribution";
import type { FeatureSettingsContribution } from "../../contribution/FeatureSettingsContribution";
import {
  getLoginUrl,
  listProducts,
  setMetricsChangeLog,
} from "../../helpers/apiHelper";
import { getAppEnv } from "../../helpers/appEnvHelper";
import { isFeatureEnabled } from "../../selectors/features";
import {
  isAdmin,
  isProductAdmin,
  isQueryAdmin,
} from "../../selectors/userPermission";
import { MetricsChangeDialog } from "./components/Dialog/MetricsChangeDialog";
import { ProductAssignmentDialog } from "./components/Dialog/ProductAssignmentDialog";
import { ProductMigrationDialog } from "./components/Dialog/ProductMigrationDialog";
import { RevokeConsentDialog } from "./components/Dialog/RevokeConsentDialog";
import { UpdateAIGraphTemplateDialog } from "./components/Dialog/UpdateAIGraphTemplateDialog";
import { UploadCWCQuerySetDialog } from "./components/Dialog/UploadCWCQuerySetDialog";
import { UploadFakeTenantInfoDialog } from "./components/Dialog/UploadFakeTenantInfoDialog";
import { UploadingFileDialog } from "./components/Dialog/UploadingFileDialog";
import { UploadQuerySetDialog } from "./components/Dialog/UploadQuerySetDialog";
import { UploadRefreshTokenDialog } from "./components/Dialog/UploadRefreshTokenDialog";
import { JobUploadSyntheticIngestionDataFolderDialog } from "./components/JobCreation/components/JobUploadSyntheticIngestionDataFolderDialog";
import { JobCreationView } from "./components/JobCreation/JobCreationView";
import { JobDetailView } from "./components/JobDetail/JobDetailView";
import { JobListView } from "./components/JobList/JobListView";
import { NoAccessView } from "./components/Other/NoAccessView";
import { ProductView } from "./components/ProductSettings/ProductView";
import { QueryManagementView } from "./components/QueryManagement/QueryManagementView";
import { QuerySetsView } from "./components/QuerySet/QuerySetsView";
import { JobScheduleView } from "./components/ScheduledJob/JobScheduleView";
import { downloadFile } from "./helpers/downloadHelper";
import { resultStore } from "./store/resultStore";

require("./orchestrators/uploadTokenOrchestrator");
require("./orchestrators/uploadFileOrchestrator");
require("./orchestrators/uploadFolderOrchestrator");
require("./orchestrators/jobOrchestrators");
require("./orchestrators/jobPriorityOrchestrators");

const UploadIcon = bundleIcon(ArrowUploadFilled, ArrowUploadRegular);
const SettingsIcon = bundleIcon(SettingsFilled, SettingsRegular);

export const offlineEvalContribution: SubTabFeatureContribution = {
  type: "sub-tab",
  name: "Offline Eval",
  subTabs: [
    {
      name: "Evaluations",
      icon: () => <ClipboardError24Regular />,
      defaultPath: "/jobs",
      pathRegistry: {
        "/": {
          render: () => <JobListView />,
        },
        "/redirect": {
          render: () => <RedirectView />,
        },
        "/jobs": {
          render: () => <JobListView />,
        },
        "/create": {
          render: () => <JobCreationView />,
          pageSize: FeatureContributionPageSize.Narrow,
        },
        "/job/:jobId/:selectedscenario?/:metricKey?/:uttquery?": {
          render: () => <JobDetailView />,
          title: () =>
            `SEVAL Pro - Job ${resultStore.resultJob?.ID} - ${resultStore.resultJob?.JobName}`,
        },
        "/detail/:jobId/:selectedscenario?/:metricKey?/:uttquery?": {
          render: () => <JobDetailView />,
          title: () =>
            `SEVAL Pro - Job ${resultStore.resultJob?.ID} - ${resultStore.resultJob?.JobName}`,
        },
      },
    },
    {
      name: "Scheduler",
      icon: () => <Flash24Regular />,
      defaultPath: "/scheduler/list",
      pathRegistry: {
        "/scheduler/:mode/:schedulerId?": {
          render: () => <JobScheduleView />,
        },
      },
    },
    {
      name: "Query Sets",
      icon: () => <TextboxSettings24Regular />,
      defaultPath: "/querysets",
      pathRegistry: {
        "/querysets": {
          render: () => <QuerySetsView />,
        },
      },
    },
    {
      name: "Query Management",
      icon: () => <ClipboardTextEdit24Regular />,
      defaultPath: "/querymanagement",
      pathRegistry: {
        "/querymanagement": {
          render: () => <QueryManagementView />,
        },
      },
    },
    {
      name: "Product Management",
      icon: () => <Apps24Regular />,
      defaultPath: "/product",
      isEnabled: () => isProductAdmin.get(),
      pathRegistry: {
        "/product": {
          render: () => <ProductView />,
          pageSize: FeatureContributionPageSize.Narrow,
        },
      },
    },
  ],
  subTabLinks: [
    {
      name: "User Manual",
      link: "https://aka.ms/sevalmanual",
    },
    {
      name: "BizChat Experimentation Guidance",
      link: "https://eng.ms/docs/experiences-devices/m365-core/microsoft-search-assistants-intelligence-msai/cortana-services-and-skills-compliant/compliant-sydney/m365-chat-platform-aka-compliant-sydney/bizchat/experimentation/process-to-design-and-ship-an-experiment",
    },
    {
      name: "Seek Support",
      link: "https://aka.ms/sevalsupport",
      additionalView: () => <NoAccessView />,
    },
  ],
  isEnabled: () => true,
};

export const offlineEvalSettingsContribution: FeatureSettingsContribution[] =
  compact([
    {
      featureContribution: offlineEvalContribution,
      icon: () => <UploadIcon />,
      text: () => "Upload Refresh Token For Different Service",
      type: "dialog",
      render: (props) => (
        <UploadRefreshTokenDialog
          tokenType={"3S refresh token"}
          tokenName={""}
          dialogTitle={"Upload Refresh Token For Different Service"}
          loggingSource={"UploadRefreshTokenDialog"}
          {...props}
        />
      ),
    },
    isFeatureEnabled("generate-refresh-token") &&
    isFeatureEnabled("refresh-token-migration")
      ? {
          featureContribution: offlineEvalContribution,
          icon: () => <UploadIcon />,
          text: () => "Generate & Upload SEVAL Refresh Token",
          type: "click",
          onClick: () => {
            getLoginUrl().then((url) => {
              window.open(url, "_blank");
            });
          },
        }
      : undefined,
    {
      featureContribution: offlineEvalContribution,
      icon: () => <UploadIcon />,
      text: () => "Upload Test Tenant Refresh Token & Password",
      type: "dialog",
      render: (props) => (
        <UploadFakeTenantInfoDialog
          {...props}
          type={
            isFeatureEnabled("generate-refresh-token")
              ? "SEVAL refresh token"
              : "3S refresh token"
          }
        />
      ),
    },
    {
      featureContribution: offlineEvalContribution,
      icon: () => <UploadIcon />,
      text: () => "Upload Query Set",
      type: "dialog",
      render: (props) => <UploadQuerySetDialog {...props} />,
    },
    {
      featureContribution: offlineEvalContribution,
      icon: () => <UploadIcon />,
      text: () => "Upload AI Graph Template",
      type: "dialog",
      render: (props) => <UpdateAIGraphTemplateDialog {...props} />,
    },
    {
      featureContribution: offlineEvalContribution,
      icon: () => <UploadIcon />,
      text: () => "Upload Query Set For CWC",
      type: "dialog",
      render: (props) => <UploadCWCQuerySetDialog {...props} />,
    },
    isFeatureEnabled("enable-synthetic-data-ingestion") && {
      featureContribution: offlineEvalContribution,
      icon: () => <UploadIcon />,
      text: () => "Upload Synthetic Ingestion Data Folder",
      type: "dialog",
      render: (props) => (
        <JobUploadSyntheticIngestionDataFolderDialog {...props} />
      ),
    },
    {
      featureContribution: offlineEvalContribution,
      icon: () => <SettingsIcon />,
      text: () => "Revoke Consent",
      type: "dialog",
      render: (props) => <RevokeConsentDialog {...props} />,
    },
  ]);

export const offlineEvalDebugGroupContribution: FeatureDebugGroupContribution[] =
  [
    {
      groupName: "Metrics Change",
      debugContributions: [
        {
          featureContribution: offlineEvalContribution,
          text: () => "Add Metrics Change",
          type: "dialog",
          render: (props) => <MetricsChangeDialog {...props} />,
        },
        {
          featureContribution: offlineEvalContribution,
          text: () => "Clean History",
          type: "click",
          onClick: () => {
            setMetricsChangeLog({
              MetricsChange: [],
            });
          },
        },
      ],
      isEnabled: () => isAdmin.get(),
    },
    {
      groupName: "Product Management Tools",
      debugContributions: [
        {
          featureContribution: offlineEvalContribution,
          text: () => "Assign Product",
          type: "dialog",
          render: (props) => <ProductAssignmentDialog {...props} />,
        },
        {
          featureContribution: offlineEvalContribution,
          text: () => "Migrate Product",
          type: "dialog",
          render: (props) => <ProductMigrationDialog {...props} />,
        },
        {
          featureContribution: offlineEvalContribution,
          text: () => "Restore Products",
          type: "dialog",
          render: (props) => (
            <UploadingFileDialog
              type={"ProductsBackup"}
              isOpen={props.isOpen}
              onCancel={props.close}
              onSuccess={props.close}
            />
          ),
        },
        {
          featureContribution: offlineEvalContribution,
          text: () => "Backup Products",
          type: "click",
          onClick: () => {
            listProducts().then((products) => {
              downloadFile(
                JSON.stringify(products, undefined, 2),
                `products_${getAppEnv().type}_${new Date().toISOString()}.json`,
              );
            });
          },
        },
      ],
      isEnabled: () => isAdmin.get() && isQueryAdmin.get(),
    },
  ];
