import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import {
  Body2,
  Spinner,
  mergeClasses,
  shorthands,
} from "@fluentui/react-components";
import { computed } from "mobx";
import { observer } from "mobx-react-lite";
import React from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { FeatureContributionPageSize } from "../contribution/FeatureContribution";
import { telemetryHelper } from "../helpers/telemetryHelper";
import { updateCurrentPath } from "../mutators/updateContributions";
import { isUnknownUser, isUser } from "../selectors/userPermission";
import { contributionStore } from "../store/contributionStore";
import { store } from "../store/store";
import { UnauthenticatedView } from "./Other/UnauthenticatedView";
import { makeResponsiveStyles } from "./Responsive/makeResponsiveStyles";
import { OnboardingProvider } from "./Wrappers/OnboardingProvider";
import { RoutesProvider } from "./Wrappers/RoutesProvider";

const useStyles = makeResponsiveStyles(
  {
    root: {
      width: "100%",
      ...shorthands.flex(1, 1, "auto"),
      boxSizing: "border-box",
      paddingLeft: "70px",
      paddingRight: "70px",
    },
    spinner: {
      width: "100%",
      ...shorthands.margin("10px", "0", "0", "0"),
    },
    noPadding: {
      paddingLeft: "0",
      paddingRight: "0",
      overflow: "hidden",
    },
    smallPadding: {
      paddingLeft: "70px",
      paddingRight: "70px",
    },
    largePadding: {
      paddingLeft: "14%",
      paddingRight: "14%",
    },
  },
  {
    xs: {
      largePadding: {
        paddingLeft: "0",
        paddingRight: "0",
      },
      smallPadding: {
        paddingLeft: "0",
        paddingRight: "0",
      },
    },
    sm: {
      largePadding: {
        paddingLeft: "0",
        paddingRight: "0",
      },
      smallPadding: {
        paddingLeft: "0",
        paddingRight: "0",
      },
    },
    md: {
      largePadding: {
        paddingLeft: "14%",
        paddingRight: "14%",
      },
      smallPadding: {
        paddingLeft: "70px",
        paddingRight: "70px",
      },
    },
  },
);

export const MainContentView = observer(() => {
  const styles = useStyles();

  const paddingStyle = computed(() => {
    switch (contributionStore.selectedContributionPathRegistry?.pageSize) {
      case FeatureContributionPageSize.ThroughPage:
        return styles.noPadding;
      case FeatureContributionPageSize.Narrow:
        return styles.largePadding;
      case FeatureContributionPageSize.FullPage:
        return styles.smallPadding;
      default:
        return styles.smallPadding;
    }
  });

  React.useEffect(() => {
    updateCurrentPath(`${location.pathname}${location.search}`);
  }, []);

  const invalidUser = computed(() => {
    if (!store.account) {
      return true;
    }
    const blockCheck = !isUnknownUser.get() && !isUser.get();
    if (blockCheck) {
      telemetryHelper.logSignEvent("SGCheckFail");
    } else {
      telemetryHelper.logSignEvent("SGCheckPass");
    }
    return blockCheck;
  });

  return (
    <Router>
      <div className={mergeClasses(styles.root, paddingStyle.get())}>
        <AuthenticatedTemplate>
          {isUnknownUser.get() && (
            <Spinner
              className={styles.spinner}
              labelPosition="below"
              label="Checking Permission..."
            />
          )}
          {!isUnknownUser.get() && isUser.get() && (
            <OnboardingProvider>
              <RoutesProvider />
            </OnboardingProvider>
          )}
          {invalidUser.get() && (
            <Body2 style={{ color: "red" }}>
              {`You (${store.account?.username}) are not authorized to access this resource. Please join sevalusergroup SG in https://aka.ms/idweb for getting access. It will take a business day to take effect.`}
            </Body2>
          )}
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
          <Routes>
            <Route
              path="/logout"
              element={<UnauthenticatedView isLogout={true} />}
            />
            <Route
              path="*"
              element={<UnauthenticatedView isLogout={false} />}
            />
          </Routes>
        </UnauthenticatedTemplate>
      </div>
    </Router>
  );
});
