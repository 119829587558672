import {
  Button,
  Link,
  Toast,
  ToastBody,
  ToastTitle,
  useToastController,
} from "@fluentui/react-components";
import { Dismiss16Regular } from "@fluentui/react-icons";
import { observer } from "mobx-react-lite";

interface IProps {
  toasterId: string;
  toastId: string;
  newExperimentUrl: string;
}

export const ShadowABExperimentSetupSuccessToast = observer((props: IProps) => {
  const { toasterId, toastId, newExperimentUrl } = props;
  const { dismissToast } = useToastController(toasterId);

  const clickDismiss = () => {
    dismissToast(toastId);
  };

  return (
    <Toast>
      <ToastTitle
        data-testid="Setup-ToastTitle"
        action={
          <Button
            appearance="transparent"
            size="small"
            icon={<Dismiss16Regular />}
            onClick={() => {
              clickDismiss();
            }}
          />
        }
      >
        Experiment created successfully
      </ToastTitle>
      <ToastBody>
        You will be redirected to the Control Tower. If the redirection does not
        happen automatically, please click the{" "}
        <Link href={newExperimentUrl} target="_blank">
          link
        </Link>
        .
      </ToastBody>
    </Toast>
  );
});
