import {
  Button,
  Combobox,
  makeStyles,
  mergeClasses,
  Option,
  Spinner,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableRow,
  TableSelectionCell,
  Text,
  tokens,
} from "@fluentui/react-components";
import { observer } from "mobx-react-lite";
import * as React from "react";
const useStyles = makeStyles({
  listContainer: {
    minHeight: "400px",
    overflow: "auto",
  },
  empty: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  loading: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden",
  },
  listTable: {
    border: "1px solid #D1D1D1",
    borderRadius: "6px",
    borderCollapse: "separate",
    borderSpacing: "0",
  },
  headerFirst: {
    borderTopLeftRadius: "6px",
  },
  headerLast: {
    borderTopRightRadius: "6px",
  },
  selectionHeader: {
    opacity: 1,
    padding: 0,
  },
  headerCell: {
    fontWeight: 600,
    fontFamily: tokens.fontFamilyBase,
    fontSize: "13px",
    backgroundColor: "#FAFAFA",
    padding: "0 20px",
  },
  rowCell: {
    wordBreak: "break-word",
    padding: "0 20px",
  },
  innerBorder: {
    borderTop: "1px solid #D1D1D1",
  },
  pagination: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: "28px",
    padding: "0 10px",
  },
  paginationAction: {
    display: "flex",
    alignItems: "center",
  },
  paginationInfo: {
    display: "inline-block",
    flex: "0 0 240px",
  },
  pageSize: {
    minWidth: "82px",
    width: "96px",
  },
  pageSizeInput: {
    width: "52px",
  },
});
interface IPaginationTableProps<T> {
  header: Array<{
    key: string;
    title: string;
    width?: number;
    minWidth?: number;
    render?: (data: T) => React.ReactNode;
  }>;
  data: T[];
  keyName: string;
  loading?: boolean;
  options?: {
    innerBorder?: boolean;
    selection?: boolean;
    multiSelection?: boolean;
    pageSizes?: string[];
    hideTotal?: boolean;
    containerStyle?: string;
    renderEmpty?: () => React.ReactNode;
  };
  onRowClick?: (data: T) => void;
  selectionKeys?: string[];
  pagination?: {
    pageSize: number;
    totalNum?: number;
    skipNum?: number;
    hasMore?: boolean;
    page?: number;
  };
  onPageChange?: (page: number) => void;
  onPageSizeChange?: (pageSize: number) => void;
}
export const PaginationTable = observer(
  <T,>(props: IPaginationTableProps<T>) => {
    const styles = useStyles();
    const {
      data,
      keyName,
      loading,
      pagination,
      header,
      options,
      onRowClick,
      selectionKeys,
      onPageChange,
      onPageSizeChange,
    } = props;
    const innerBorderClass = options?.innerBorder ? styles.innerBorder : "";
    const [checkedKeys, setCheckedKeys] = React.useState(
      new Set<string>(selectionKeys ?? []),
    );
    const onSelectionChange = (item: T) => {
      setCheckedKeys((prev) => {
        if (options?.multiSelection) {
          if (prev.has(String(item[keyName as keyof T]))) {
            prev.delete(String(item[keyName as keyof T]));
          } else {
            prev.add(String(item[keyName as keyof T]));
          }
        } else {
          prev.clear();
          prev.add(String(item[keyName as keyof T]));
        }
        return new Set(prev);
      });
      onRowClick?.(item);
    };

    const renderEmptyTable = () => (
      <div
        className={mergeClasses(
          styles.listContainer,
          options?.containerStyle,
          styles.empty,
        )}
      >
        <Text>No result matching found</Text>
      </div>
    );

    const startCount = React.useMemo(() => {
      if (pagination?.skipNum !== undefined) {
        return pagination?.skipNum + 1;
      }
      if (pagination?.page !== undefined) {
        return (pagination?.page - 1) * pagination?.pageSize + 1;
      }
      return 0;
    }, [pagination?.page, pagination?.skipNum, pagination?.pageSize]);

    const endCount = React.useMemo(() => {
      let pageEndCount = 0;
      if (pagination?.skipNum !== undefined) {
        pageEndCount = pagination?.skipNum + pagination?.pageSize;
      }
      if (pagination?.page !== undefined) {
        pageEndCount = pagination?.page * pagination?.pageSize;
      }
      if (pagination?.totalNum !== undefined) {
        return Math.min(pageEndCount, pagination?.totalNum);
      }
      return pageEndCount;
    }, [
      pagination?.page,
      pagination?.skipNum,
      pagination?.pageSize,
      pagination?.totalNum,
    ]);

    const prevDisabled = React.useMemo(() => {
      if (pagination?.skipNum !== undefined) {
        return pagination?.skipNum <= 0;
      }
      if (pagination?.page !== undefined) {
        return pagination?.page <= 1;
      }
      return false;
    }, [pagination?.skipNum, pagination?.page]);

    const nextDisabled = React.useMemo(() => {
      if (
        pagination?.skipNum !== undefined &&
        pagination?.totalNum !== undefined
      ) {
        return pagination.skipNum + pagination?.pageSize >= pagination.totalNum;
      }
      if (pagination?.hasMore !== undefined) {
        return pagination.hasMore === false;
      }
      if (
        pagination?.page !== undefined &&
        pagination?.totalNum !== undefined
      ) {
        return pagination.page * pagination?.pageSize >= pagination.totalNum;
      }
      return false;
    }, [
      pagination?.page,
      pagination?.skipNum,
      pagination?.hasMore,
      pagination?.pageSize,
      pagination?.totalNum,
    ]);

    const onPrevClick = React.useCallback(() => {
      if (pagination?.skipNum !== undefined) {
        onPageChange?.(Math.max(pagination.skipNum / pagination?.pageSize, 1));
      } else if (pagination?.page !== undefined) {
        onPageChange?.(Math.max(pagination.page - 1, 1));
      }
      return 1;
    }, [pagination?.skipNum, pagination?.pageSize, pagination?.page]);

    const onNextClick = React.useCallback(() => {
      let nextPage = 1;
      if (pagination?.skipNum !== undefined) {
        nextPage = pagination.skipNum / pagination?.pageSize + 2;
      } else if (pagination?.page !== undefined) {
        nextPage = pagination.page + 1;
      }
      if (pagination?.totalNum !== undefined) {
        nextPage = Math.min(
          nextPage,
          Math.floor((pagination.totalNum - 1) / pagination?.pageSize) + 1,
        );
      }
      onPageChange?.(nextPage);
    }, [
      pagination?.skipNum,
      pagination?.pageSize,
      pagination?.page,
      pagination?.totalNum,
    ]);

    return (
      <>
        {loading ? (
          <div
            className={mergeClasses(
              styles.listContainer,
              options?.containerStyle,
              styles.loading,
            )}
          >
            <Spinner />
          </div>
        ) : data.length > 0 ? (
          <>
            <div
              className={mergeClasses(
                styles.listContainer,
                options?.containerStyle,
              )}
            >
              <Table className={styles.listTable}>
                <TableHeader>
                  <TableRow>
                    {options?.selection ? (
                      <TableSelectionCell
                        className={mergeClasses(
                          styles.headerCell,
                          styles.headerFirst,
                          styles.selectionHeader,
                        )}
                        type={options?.multiSelection ? "checkbox" : "radio"}
                        invisible
                      />
                    ) : (
                      <></>
                    )}
                    {header.map((column, i) => {
                      const isFirst = i === 0 && !options?.selection;
                      const isLast = i === header.length - 1;
                      const cellClass = mergeClasses(
                        styles.headerCell,
                        isFirst ? styles.headerFirst : "",
                        isLast ? styles.headerLast : "",
                      );
                      return (
                        <TableHeaderCell
                          key={column.key}
                          className={cellClass}
                          style={{
                            minWidth: column.minWidth,
                            width: column.width,
                          }}
                        >
                          {column.title}
                        </TableHeaderCell>
                      );
                    })}
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {data.map((item) => (
                    <TableRow
                      key={String(item[keyName as keyof T])}
                      onClick={() => {
                        onSelectionChange?.(item);
                      }}
                    >
                      {options?.selection ? (
                        <TableSelectionCell
                          className={mergeClasses(innerBorderClass)}
                          checked={checkedKeys.has(
                            String(item[keyName as keyof T]),
                          )}
                          type={options?.multiSelection ? "checkbox" : "radio"}
                          checkboxIndicator={{ "aria-label": "Select row" }}
                        />
                      ) : (
                        <></>
                      )}
                      {header.map((column) => {
                        return (
                          <TableCell
                            key={String(column.key)}
                            className={mergeClasses(
                              styles.rowCell,
                              innerBorderClass,
                            )}
                          >
                            {column.render
                              ? column.render(item)
                              : String(item[column.key as keyof T])}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
            {pagination ? (
              <div className={styles.pagination}>
                <span className={styles.paginationInfo}>
                  {options?.hideTotal ? (
                    <></>
                  ) : (
                    <>
                      {"Showing "}
                      {startCount} - {endCount} of {pagination.totalNum}
                      {" items"}
                    </>
                  )}
                </span>
                <div className={styles.paginationAction}>
                  <Button
                    size="large"
                    appearance="transparent"
                    disabled={prevDisabled}
                    onClick={onPrevClick}
                  >
                    {"< Prev"}
                  </Button>
                  <Button
                    size="large"
                    appearance="transparent"
                    disabled={nextDisabled}
                    onClick={onNextClick}
                  >
                    {"Next >"}
                  </Button>
                  <Combobox
                    className={styles.pageSize}
                    input={{ className: styles.pageSizeInput }}
                    value={`${pagination.pageSize}/Page`}
                    onOptionSelect={(_e, select) => {
                      onPageSizeChange?.(Number(select.optionValue));
                    }}
                  >
                    {(options?.pageSizes ?? ["10", "20", "50"]).map(
                      (option) => (
                        <Option
                          key={option}
                          value={option}
                        >{`${option}/Page`}</Option>
                      ),
                    )}
                  </Combobox>
                </div>
                <span className={styles.paginationInfo}></span>
              </div>
            ) : (
              <></>
            )}
          </>
        ) : options?.renderEmpty ? (
          options?.renderEmpty()
        ) : (
          renderEmptyTable()
        )}
      </>
    );
  },
);
