import { array, object, optional, str } from "sydneyeval-shared";

export const PhysicalAccount = object({
  userId: optional(str),
  userName: str,
});

export const VirtualAccount = object({
  userId: str,
  userName: str,
  userTags: array(str),
  physicalAccounts: array(PhysicalAccount),
});

export const PhysicalTenant = object({
  tenantId: str,
  tenantName: str,
  virtualAccounts: array(VirtualAccount),
});

export const VirtualTenant = object({
  tenantId: str,
  tenantName: str,
  tenantTags: array(str),
  createdBy: str,
  ring: optional(str),
  physicalTenants: array(PhysicalTenant),
});

export type VirtualTenant = ReturnType<typeof VirtualTenant>;
export type VirtualAccount = ReturnType<typeof VirtualAccount>;
