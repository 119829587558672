import { computed } from "mobx";
import { observer } from "mobx-react-lite";
import { Fragment, useEffect } from "react";
import { useParams } from "react-router-dom";
import { bingfile_DiagnosisFileName } from "../../../../constants/constants";
import { updateSelectBingFileAction } from "../../actions/resultActions";
import { switchScenario } from "../../mutators/jobResultMutators";
import {
  getAnswerTriggerRateColumns,
  getAnswerTriggerRateData,
  getBingDropdownValues,
  getConciseTableColumns,
  getConciseTableData,
  getExtensionTriggerColumns,
  getExtensionTriggerTableData,
  getPluginTriggerColumns,
  getPluginTriggerTableData,
  getSummaryTableColumns,
  getSummaryTableData,
} from "../../selectors/prepareBingJobResult";
import { BingScenarioType, resultStore } from "../../store/resultStore";
import { FilterButton } from "../Other/FilterButton";
import { MessageNotificationBar } from "./Banner/MessageNotificationBar";
import { GeneralResultTable } from "./Table/GeneralResultTable";
import { BingDiagnosisView } from "./components/BingDiagnosisView";
import { BingDropDown } from "./components/BingDropDown";
import { useResultViewStyles } from "./sharedStyles";

const Scenario = ["Scorecard Comparison", "Metric Diagnosis"] as const;
type Scenario = (typeof Scenario)[number];

export const BingDetailCard = observer(() => {
  const styles = useResultViewStyles();
  const outputfiles = resultStore.bingJobOutputFiles;
  const { selectedscenario } = useParams();

  const scenario = resultStore.scenario;

  const summaryColumn = getSummaryTableColumns.get();
  const summaryTable = getSummaryTableData.get();

  const conciseColumn = getConciseTableColumns.get();
  const conciceTable = getConciseTableData.get();

  const answerTriggerColumns = getAnswerTriggerRateColumns.get();
  const answerTriggerData = getAnswerTriggerRateData.get();

  const pluginTriggerColumns = getPluginTriggerColumns.get();
  const pluginTriggerData = getPluginTriggerTableData.get();

  const extensionTriggerColumns = getExtensionTriggerColumns.get();
  const extensionTriggerData = getExtensionTriggerTableData.get();

  const parseSelectedscenario = computed(() => {
    if (selectedscenario !== undefined) {
      try {
        return BingScenarioType(selectedscenario, "BingScenarioType");
      } catch {
        return undefined;
      }
    }
    return scenario;
  });

  useEffect(() => {
    const selectedScenario = parseSelectedscenario.get();
    if (selectedScenario !== undefined) {
      switchScenario(selectedScenario);
    }
  }, [parseSelectedscenario.get()]);

  if (outputfiles === undefined) {
    return <div></div>;
  }

  const renderMetricsSummary = () => {
    return <GeneralResultTable columns={summaryColumn} data={summaryTable} />;
  };

  const renderE2E_Consice = () => {
    return <GeneralResultTable columns={conciseColumn} data={conciceTable} />;
  };

  const renderAnswerTriggerTable = () => {
    return (
      <GeneralResultTable
        columns={answerTriggerColumns}
        data={answerTriggerData ?? []}
      />
    );
  };

  const renderPlugInTriggerTable = () => {
    return (
      <GeneralResultTable
        columns={pluginTriggerColumns}
        data={pluginTriggerData ?? []}
      />
    );
  };

  const renderExtensionTriggerTable = () => {
    return (
      <GeneralResultTable
        columns={extensionTriggerColumns}
        data={extensionTriggerData ?? []}
      />
    );
  };

  const isSupportDiagnosis = () => {
    return (
      outputfiles?.find((item) =>
        item.fileName.includes(bingfile_DiagnosisFileName),
      ) !== undefined
    );
  };

  return (
    <div key="BingDetailCard">
      <div className={styles.scenarioRow}>
        <div className={styles.buttonRow}>
          {Scenario.map((s) => {
            if (s === "Metric Diagnosis" && !isSupportDiagnosis()) {
              return <Fragment key="skip"></Fragment>;
            }
            return (
              <FilterButton
                value={s}
                text={s}
                selectedTab={scenario}
                key={s}
                clickTab={() => {
                  switchScenario(s);
                  updateSelectBingFileAction(outputfiles[0].folderName);
                }}
                activeIcon={undefined}
                inactionIcon={undefined}
              />
            );
          })}
        </div>
      </div>
      <div className={styles.detailedCard}>
        {scenario === "Scorecard Comparison" && (
          <div key={"Scorecard"} className={styles.dataPane}>
            <MessageNotificationBar></MessageNotificationBar>
            <BingDropDown
              options={getBingDropdownValues.get()}
              multiSelect={[]}
            />
            <div key="MetricSummary" className={styles.tableTitle}>
              Metric Summary
            </div>
            <div key="MetricSummaryTable" className={styles.scrollable}>
              {renderMetricsSummary()}
            </div>

            <div key="E2EMetric" className={styles.tableTitle}>
              E2E Concise Metrics
            </div>
            <div key="E2EMetricTable" className={styles.scrollable}>
              {renderE2E_Consice()}
            </div>

            <div key="Answer" className={styles.tableTitle}>
              Answer Trigger Rate Metrics
            </div>
            <div key="AnswerTable" className={styles.scrollable}>
              {renderAnswerTriggerTable()}
            </div>

            <div key="Plugin" className={styles.tableTitle}>
              Plugin Trigger Rate Metrics
            </div>
            <div key="PluginTable" className={styles.scrollable}>
              {renderPlugInTriggerTable()}
            </div>

            <div key="Extension" className={styles.tableTitle}>
              Extension Runner Trigger Rate Metrics
            </div>
            <div key="ExtensionTable" className={styles.scrollable}>
              {renderExtensionTriggerTable()}
            </div>
          </div>
        )}
        {isSupportDiagnosis() && scenario === "Metric Diagnosis" && (
          <BingDiagnosisView />
        )}
      </div>
    </div>
  );
});
