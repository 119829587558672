import {
  Body1,
  Button,
  Caption1,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  DialogTrigger,
  Label,
} from "@fluentui/react-components";
import {
  AddCircle24Filled,
  QuestionCircle16Regular,
} from "@fluentui/react-icons";
import { observer } from "mobx-react-lite";
import { useEffect, useMemo, useReducer, useRef, useState } from "react";
import { useToast } from "../../../../components/Wrappers/ToasterProvider";
import { telemetryHelper } from "../../../../helpers/telemetryHelper";
import {
  getOptions,
  importSnapshot,
  queriesEdited,
  validateImportFileSchema,
} from "../../helpers/queryManagementHelper";
import { QueryManagementBulkEditDialog } from "./components/QueryManagementBulkEditDialog";
import { QueryManagementFilterPopover } from "./components/QueryManagementFilterPopover";
import { QueryManagementImportExportPopover } from "./components/QueryManagementImportExportPopover";
import { QueryManagementQueryDialog } from "./components/QueryManagementQueryDialog";
import { QueryManagementQueryTable } from "./components/QueryManagementQueryTable";
import { useQueryManagementStyles } from "./styles/QueryManagementStyles";
import type { Filters, SelectOptions } from "./types/Display";
import type { Query, TaggedAssertions } from "./types/Query";

export const QueryManagementView = observer(() => {
  const styles = useQueryManagementStyles();
  const toast = useToast();
  const fullFileInputRef = useRef<HTMLInputElement>(null);

  const [loading, setLoading] = useState<boolean>(false);

  const [queries, setQueries] = useState<Query[]>([]);
  const [initialQueries, setInitialQueries] = useState<Query[]>([]);
  const [taggedAssertions, setTaggedAssertions] = useState<TaggedAssertions[]>(
    [],
  );

  const [filters, setFilters] = useState<Filters>({
    segmentTwo: [],
    utterance: "",
    queryOwner: "",
    environment: "",
    term: "",
    freOrSparklePrompt: [],
    sets: [],
    assertionOwner: "",
    showArchived: false,
    selectedOnly: false,
  });

  const [checkedQueries, dispatch] = useReducer(
    querySetReducer,
    new Set<Query>(),
  );

  const [importErrorMessage, setImportErrorMessage] = useState<string>("");

  const [selectOptions, setSelectOptions] = useState<SelectOptions>({
    segmentOne: getOptions(queries, "segmentOne"),
    segmentTwo: getOptions(queries, "segmentTwo"),
    queryOwner: getOptions(queries, "queryOwner"),
    environment: getOptions(queries, "environment"),
    term: getOptions(queries, "term"),
    groundingDataSource: getOptions(queries, "groundingDataSource"),
    dataSourceInfo: getOptions(queries, "dataSourceInfo"),
    freOrSparklePrompt: getOptions(queries, "freOrSparklePrompt"),
    sets: getOptions(queries, "sets"),
    flags: getOptions(queries, "flags"),
  });

  const [queryDialogOpen, setQueryDialogOpen] = useState<boolean>(false);
  const [queryToEdit, setQueryToEdit] = useState<Query | undefined>(undefined);

  const [bulkEditDialogOpen, setBulkEditDialogOpen] = useState<boolean>(false);

  const [unsavedChanges, setUnsavedChanges] = useState<boolean>(false);
  const [exportComplete, setExportComplete] = useState<boolean>(false);

  useEffect(() => {
    telemetryHelper.logUserActionEvent("QueryManagementToolVisitPage");
  }, []);

  useEffect(() => {
    setSelectOptions({
      segmentOne: getOptions(queries, "segmentOne"),
      segmentTwo: getOptions(queries, "segmentTwo"),
      queryOwner: getOptions(queries, "queryOwner"),
      environment: getOptions(queries, "environment"),
      term: getOptions(queries, "term"),
      groundingDataSource: getOptions(queries, "groundingDataSource"),
      dataSourceInfo: getOptions(queries, "dataSourceInfo"),
      freOrSparklePrompt: getOptions(queries, "freOrSparklePrompt"),
      sets: getOptions(queries, "sets"),
      flags: getOptions(queries, "flags"),
    });
  }, [queries]);

  const emptyFilters = () =>
    filters.environment === "" &&
    filters.term === "" &&
    filters.segmentTwo.length === 0 &&
    filters.sets.length === 0 &&
    filters.freOrSparklePrompt.length === 0 &&
    filters.utterance === "" &&
    filters.queryOwner === "" &&
    filters.assertionOwner === "" &&
    !filters.showArchived &&
    !filters.selectedOnly;

  const filteredQueries = useMemo(() => {
    if (emptyFilters()) {
      return queries.filter((query) => !query.archived);
    }

    return queries.filter((query) => {
      const segmentsTwoFilter =
        filters.segmentTwo.length === 0 ||
        (query.segmentTwo && filters.segmentTwo.includes(query.segmentTwo));

      const utteranceFilter =
        filters.utterance === "" ||
        query.query.toLowerCase().includes(filters.utterance.toLowerCase());

      const queryOwnerFilter =
        filters.queryOwner === "" ||
        (query.queryOwner &&
          query.queryOwner
            .toLowerCase()
            .includes(filters.queryOwner.toLowerCase()));

      const environmentFilter =
        filters.environment === "" ||
        (query.environment &&
          query.environment
            .toLowerCase()
            .includes(filters.environment.toLowerCase()));

      const termFilter =
        filters.term === "" ||
        (filters.term === "None"
          ? !query.term
          : query.term &&
            query.term.toLowerCase().includes(filters.term.toLowerCase()));

      const freFilter =
        filters.freOrSparklePrompt.length === 0 ||
        (query.freOrSparklePrompt &&
          filters.freOrSparklePrompt.includes(query.freOrSparklePrompt));

      const setsFilter =
        filters.sets.length === 0 ||
        (query.sets &&
          filters.sets.every((set) => query.sets && query.sets.includes(set)));

      const assertionOwnerFilter =
        filters.assertionOwner === "" ||
        (query.assertions &&
          query.assertions.some(
            (assertion) =>
              assertion.owner &&
              assertion.owner
                .toLowerCase()
                .includes(filters.assertionOwner.toLowerCase()),
          ));

      const archivedFilter = filters.showArchived || !query.archived;

      const selectedOnlyFilter =
        !filters.selectedOnly || checkedQueries.has(query);

      return (
        segmentsTwoFilter &&
        utteranceFilter &&
        queryOwnerFilter &&
        environmentFilter &&
        termFilter &&
        freFilter &&
        setsFilter &&
        assertionOwnerFilter &&
        archivedFilter &&
        selectedOnlyFilter
      );
    });
  }, [filters, queries, checkedQueries]);

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      if (unsavedChanges) {
        event.preventDefault();
        event.returnValue = "";
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [unsavedChanges]);

  useEffect(() => {
    setUnsavedChanges(queriesEdited(queries, initialQueries));
  }, [queries]);

  useEffect(() => {
    if (checkedQueries.size > 0 || unsavedChanges) {
      setExportComplete(false);
    }
  }, [checkedQueries, unsavedChanges]);

  useEffect(() => {
    if (exportComplete) {
      const resetEditedQueries = queries.map((query) => ({
        ...query,
        edited: false,
      }));
      setInitialQueries(resetEditedQueries);
      setQueries(resetEditedQueries);
      dispatch({ type: "empty" });
    }
  }, [exportComplete]);

  const handleSubmitNewQuery = (newQuery: Query) => {
    setQueries([newQuery, ...queries]);
    setQueryDialogOpen(false);
    telemetryHelper.logUserActionEvent("QueryManagementToolCreateNewQuery");
  };

  const handleEditQuery = (editedQuery: Query) => {
    const newQueries = queries.map((query) => {
      if (query.id === editedQuery.id) {
        if (checkedQueries.has(query)) {
          dispatch({ type: "delete", query: query });
          dispatch({ type: "add", query: editedQuery });
        }
        return editedQuery;
      }

      return query;
    });
    setQueries(newQueries);
    setQueryDialogOpen(false);
    setQueryToEdit(undefined);
    telemetryHelper.logUserActionEvent("QueryManagementToolEditQuery");
  };

  const handleArchive = (id: string) => {
    const newQueries = queries.map((query) => {
      if (query.id === id) {
        dispatch({ type: "delete", query: query });
        return {
          ...query,
          archived: true,
        };
      }
      return query;
    });
    setQueries(newQueries);
  };

  const handleRestore = (id: string) => {
    const newQueries = queries.map((query) => {
      if (query.id === id) {
        return {
          ...query,
          archived: false,
        };
      }
      return query;
    });
    setQueries(newQueries);
  };

  const handleSelectAllClick = (allFilteredChecked: boolean) => {
    if (!allFilteredChecked) {
      filteredQueries.forEach((query) => {
        dispatch({ type: "add", query: query });
      });
    } else {
      if (emptyFilters()) {
        // Uncheck all queries
        dispatch({ type: "empty" });
      } else {
        // Uncheck all currently filtered queries
        filteredQueries.forEach((query) => {
          dispatch({ type: "delete", query: query });
        });
      }
    }
  };

  const renderToolBar = () => {
    return (
      <div className={styles.toolBar}>
        <div>
          {unsavedChanges && (
            <div className={styles.unexported}>
              <Body1 className={styles.unexportedText}>
                You have unsaved changes. Export them before leaving the page or
                else all progress will be lost.
              </Body1>
            </div>
          )}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: "8px",
            }}
          >
            {checkedQueries.size > 0 && (
              <>
                <Body1 className={styles.boldLabel}>
                  {checkedQueries.size === 1
                    ? "1 query selected"
                    : `${checkedQueries.size} queries selected`}
                </Body1>
                <QueryManagementBulkEditDialog
                  isOpen={bulkEditDialogOpen}
                  setOpen={setBulkEditDialogOpen}
                  queries={queries}
                  checkedQueries={checkedQueries}
                  setQueries={setQueries}
                  dispatch={dispatch}
                  selectOptions={selectOptions}
                />
              </>
            )}
          </div>
        </div>
        <div>
          <QueryManagementFilterPopover
            filters={filters}
            setFilters={setFilters}
            selectOptions={selectOptions}
            emptyFilters={emptyFilters}
          />
          <QueryManagementImportExportPopover
            fullFileInputRef={fullFileInputRef}
            importErrorMessage={importErrorMessage}
            queries={queries}
            taggedAssertions={taggedAssertions}
            checkedQueries={checkedQueries}
            selectOptions={selectOptions}
            setInitialQueries={setInitialQueries}
            setImportErrorMessage={setImportErrorMessage}
            renderFullErrorDialog={renderFullErrorDialog}
            setQueries={setQueries}
            setTaggedAssertions={setTaggedAssertions}
            setExportComplete={setExportComplete}
            setLoading={setLoading}
          />
          <Button
            aria-label="Add New Query"
            className={styles.queryButtons}
            appearance="primary"
            icon={<AddCircle24Filled className={styles.icon} />}
            onClick={() => {
              setQueryToEdit(undefined);
              setQueryDialogOpen(true);
            }}
          >
            New Query
          </Button>
        </div>
        <QueryManagementQueryDialog
          isOpen={queryDialogOpen}
          setOpen={setQueryDialogOpen}
          handleSubmit={handleSubmitNewQuery}
          handleEdit={handleEditQuery}
          selectOptions={selectOptions}
          query={queryToEdit}
        />
      </div>
    );
  };

  const renderFullErrorDialog = () => (
    <Dialog>
      <DialogTrigger disableButtonEnhancement>
        <Button appearance="transparent" className={styles.errorButton}>
          View full error
        </Button>
      </DialogTrigger>
      <DialogSurface>
        <DialogBody>
          <DialogTitle>Error</DialogTitle>
          <DialogContent>
            <Body1>{importErrorMessage}</Body1>
          </DialogContent>
          <DialogActions>
            <DialogTrigger disableButtonEnhancement>
              <Button appearance="primary">Close</Button>
            </DialogTrigger>
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );

  const renderWikiLink = () => (
    <div className={styles.wiki}>
      <QuestionCircle16Regular />
      <a
        target="_blank"
        href="https://eng.ms/docs/experiences-devices/m365-core/microsoft-search-assistants-intelligence-msai/cortana-services-and-skills-compliant/compliant-sydney/m365-chat-platform-aka-compliant-sydney/bizchat/quality-evaluation/datasets/query-management-tool"
        className={styles.link}
      >
        Wiki
      </a>
    </div>
  );

  const renderImportView = () => (
    <div className={styles.importView}>
      <Button
        className={styles.queryButtons}
        appearance="primary"
        onClick={() => {
          fullFileInputRef.current?.click();
          setImportErrorMessage("");
        }}
      >
        Import queries
      </Button>
      <Label style={{ fontSize: "12px" }}>
        <em>.yaml, .yml</em>
      </Label>
      <Label style={{ fontSize: "12px" }}>
        Download the latest MSIT/Test Tenant snapshot YAML files{" "}
        <a
          target="_blank"
          href="https://o365exchange.visualstudio.com/O365%20Core/_git/SydneyEvaluation?path=/Enterprise/data/LMChecklist"
          className={styles.link}
        >
          here.
        </a>
      </Label>
      {importErrorMessage && (
        <div
          data-testid="importErrorMessage"
          className={styles.errorMessageContainer}
        >
          <Caption1 className={styles.importErrorMessage}>
            {importErrorMessage}
          </Caption1>
          {renderFullErrorDialog()}
        </div>
      )}
      <input
        data-testid="fileInput"
        ref={fullFileInputRef}
        type="file"
        accept=".yaml,.yml"
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          const file = event.target?.files?.[0];
          if (file) {
            setLoading(true);
            toast.onToastStart("Importing file...");
            validateImportFileSchema(file)
              .then((selectedFile) => importSnapshot(selectedFile))
              .then((data) => {
                setInitialQueries(data.queries);
                setQueries(data.queries);
                setTaggedAssertions(data.taggedAssertions);
                setImportErrorMessage("");
                toast.onToastSuccess("File imported successfully.");
                telemetryHelper.logUserActionEvent(
                  "QueryManagementToolImportQuerySet",
                );
              })
              .catch((error) => {
                setImportErrorMessage(error.message);
                toast.onToastFailure("An error occurred during import.");
                telemetryHelper.logUserActionEvent(
                  "QueryManagementToolImportError",
                  {
                    error: error.message,
                  },
                );
              })
              .finally(() => {
                if (fullFileInputRef.current) {
                  fullFileInputRef.current.value = "";
                }
                setLoading(false);
              });
          }
        }}
        style={{ display: "none" }}
      />
    </div>
  );

  return (
    <>
      {queries.length === 0 && !loading && renderImportView()}
      {queries.length > 0 && renderToolBar()}
      {queries.length > 0 && (
        <QueryManagementQueryTable
          queries={filteredQueries}
          setQueryToEdit={setQueryToEdit}
          setQueryDialogOpen={setQueryDialogOpen}
          checkedQueries={checkedQueries}
          dispatch={dispatch}
          isLoading={loading}
          handleSelectAllClick={handleSelectAllClick}
          handleArchive={handleArchive}
          handleRestore={handleRestore}
        />
      )}
      {renderWikiLink()}
    </>
  );
});

export const querySetReducer = (
  state: Set<Query>,
  action: { type: "add" | "delete"; query: Query } | { type: "empty" },
) => {
  switch (action.type) {
    case "add":
      state.add(action.query);
      return new Set(state);
    case "delete":
      state.delete(action.query);
      return new Set(state);
    case "empty":
      return new Set<Query>();
    default:
      throw new Error("Invalid action type");
  }
};
