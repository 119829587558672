import { observer } from "mobx-react-lite";
import { isFeatureEnabled } from "../../../../../selectors/features";
import { refreshCustomQuerySets } from "../../../helpers/jobCreationHelper";
import {
  updateCustomizedQuerySets,
  updatePublicLMChecklistSets,
} from "../../../mutators/jobCreationFileMutators";
import { jobCreationFileStore } from "../../../store/jobCreationFileStore";
import { jobStore } from "../../../store/jobStore";
import { JobEvalTimeOverrideView } from "../components/JobEvalTimeOverrideView";
import { JobLLMChecklistDatasetView } from "../components/JobLLMChecklistDatasetView";
import { JobMetricsBlockView } from "../components/JobMetricsBlockView";
import { JobSbsExpContainer } from "../components/JobSBSExpContainer";
import { JobSelectedFileView } from "../components/JobSelectedFileView";
import { JobUserIdView } from "../components/JobUserIdView";
import { JobPairExpMultiServiceView } from "../ConfigComponents/JobPairExpMultiServiceView";
import { useJobCreationStyles } from "./useJobCreationStyles";

const renderDivider = () => {
  const styles = useJobCreationStyles();
  return <div className={styles.divider} />;
};

export const OPGMultiServiceRender = observer(() => {
  const selectedTemplate = jobStore.selectedTemplate;
  if (selectedTemplate === undefined) {
    return <></>;
  }
  return (
    <>
      <JobMetricsBlockView />
      <JobSelectedFileView
        customQuerySets={jobCreationFileStore.customQuerySets}
        setCustomQuerySets={updateCustomizedQuerySets}
        refreshCustomQuerySets={refreshCustomQuerySets}
      />
      <JobLLMChecklistDatasetView
        publicLMChecklistSets={jobCreationFileStore.publicLMChecklistSets}
        customLMChecklistSets={jobCreationFileStore.customLMChecklistSets}
        setPublicLMChecklistSets={updatePublicLMChecklistSets}
      />
      <JobUserIdView />
      {isFeatureEnabled("eval-time-override") && <JobEvalTimeOverrideView />}
      {renderDivider()}
      <JobPairExpMultiServiceView />
      <JobSbsExpContainer />
    </>
  );
});
