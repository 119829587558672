import {
  Combobox,
  InfoLabel,
  Option,
  shorthands,
  tokens,
} from "@fluentui/react-components";
import { observer } from "mobx-react-lite";
import { isEyesOnEnvironment } from "sydneyeval-shared";
import { makeResponsiveStyles } from "../../../../../components/Responsive/makeResponsiveStyles";
import { Tip } from "../../../../../components/Shared/Tip";
import { ConditionalWrapper } from "../../../../../components/Wrappers/ConditionalWrapper";
import { isFeatureEnabled } from "../../../../../selectors/features";
import { store } from "../../../../../store/store";
import {
  updatePropValueActionV2,
  updateSelectedEvaluationTypeAction,
} from "../../../actions/jobActions";
import type { EvaluationType } from "../../../models/Types";
import { jobStore } from "../../../store/jobStore";

const useStyles = makeResponsiveStyles(
  {
    root: {
      backgroundColor: "white",
      boxSizing: "border-box",
      display: "flex",
      flexDirection: "column",
      ...shorthands.gap("20px"),
      ...shorthands.padding("24px", "87px", "24px", "87px"),
      ...shorthands.border("1px", "solid", "#EDEBE9"),
      ...shorthands.borderRadius("8px"),
    },
    block: {
      display: "flex",
      flexDirection: "column",
      ...shorthands.flex(1),
      width: "100%",
      ...shorthands.gap("8px"),
    },
    blockTitle: {
      fontFamily: tokens.fontFamilyBase,
      fontSize: "16px",
      fontWeight: 600,
      lineHeight: "22px",
    },
    dropdownMenu: {
      backgroundColor: "#F8F7F9",
    },
    listbox: {
      maxHeight: "300px",
    },
  },
  {
    xs: {
      root: {
        ...shorthands.padding("24px", "24px", "24px", "24px"),
      },
    },
  },
);

export const JobEvaluationTypeView = observer(() => {
  const styles = useStyles();

  const selectedTemplate = jobStore.selectedTemplate;

  const disableEyesOnPersonalAccount = isFeatureEnabled(
    "disable-eyeson-personal",
  );

  const disablePersonalAccount =
    disableEyesOnPersonalAccount &&
    isEyesOnEnvironment(selectedTemplate?.EnvType ?? "");

  const onEvaluationTypeChanged = (evaluationType: EvaluationType) => {
    updateSelectedEvaluationTypeAction(evaluationType);
    switch (evaluationType) {
      case "PersonalAccount":
        updatePropValueActionV2({
          prop: "configs_in_json.conversations.user_id",
          newData: store.account?.username,
        });
        break;
      case "FakeTenant":
        updatePropValueActionV2({
          prop: "configs_in_json.conversations.user_id",
          newData: "MeganBowen@meinbizchat.onmicrosoft.com",
        });
        break;
    }
  };

  const selectedEvaluationType = jobStore.selectedEvaluationType;

  const personalAccountTypeDisplayString =
    "Eyes-On Test Set + Personal Account";
  const fakeTenantTypeDisplayString = "Eyes-On Test Set + Test Tenant";

  const getDisplayStringFromEvaluationType = (type: EvaluationType) => {
    switch (type) {
      case "PersonalAccount":
        return personalAccountTypeDisplayString;
      case "FakeTenant":
        return fakeTenantTypeDisplayString;
    }
  };

  const getEvaluationTypeFromDisplayString = (displayString: string) => {
    switch (displayString) {
      case personalAccountTypeDisplayString:
        return "PersonalAccount";
      case fakeTenantTypeDisplayString:
        return "FakeTenant";
      default:
        return "PersonalAccount";
    }
  };

  return (
    <div className={styles.block}>
      <InfoLabel
        required
        className={styles.blockTitle}
        info={
          <div>
            <div>
              When user select Eyes-On Test Set + Personal Account, allow user
              to select any query set with their account
            </div>
            <div>
              When user select Eyes-On Test Set + Test Tenant, allow user to
              select any query set and specify a test tenant account (not
              @microsoft.com) account.
            </div>
          </div>
        }
      >
        Evaluation Type
      </InfoLabel>
      <Combobox
        className={styles.dropdownMenu}
        listbox={{ className: styles.listbox }}
        placeholder="Select evaluation type"
        value={getDisplayStringFromEvaluationType(selectedEvaluationType)}
        disabled={selectedTemplate === undefined}
        selectedOptions={[selectedEvaluationType]}
        onOptionSelect={(_, data) => {
          if (data.optionValue) {
            const evaluationType = getEvaluationTypeFromDisplayString(
              data.optionValue,
            );
            onEvaluationTypeChanged(evaluationType);
          }
        }}
      >
        <ConditionalWrapper
          condition={disablePersonalAccount}
          Wrapper={Tip}
          wrapperProps={{
            content: "This template does not support Personal Account",
            relationship: "label",
          }}
        >
          <Option key={"PersonalAccount"} disabled={disablePersonalAccount}>
            {getDisplayStringFromEvaluationType("PersonalAccount")}
          </Option>
        </ConditionalWrapper>

        <Option key={"FakeTenant"}>
          {getDisplayStringFromEvaluationType("FakeTenant")}
        </Option>
      </Combobox>
    </div>
  );
});
