import {
  Button,
  Dropdown,
  Option,
  shorthands,
  Subtitle1,
  Switch,
  tokens,
} from "@fluentui/react-components";
import { Open20Regular } from "@fluentui/react-icons";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { ResponsiveRow } from "../../../../components/Responsive/ResponsiveRow";
import { makeResponsiveStyles } from "../../../../components/Responsive/makeResponsiveStyles";
import { Exp_Workspace_Id } from "../../../../constants/constants";
import { getExperimentScorecards } from "../../../../helpers/apiHelper";
import { perfWrapper } from "../../../../helpers/telemetryHelper";
import type { Scorecard } from "../../models/Experiment";
import {
  formatDatetime,
  formatDatetimeRange,
  formatScorecardStatus,
} from "../../utils/format";
import { ShadowABBreadcrumb } from "../ShadowABBreadcrumb";
import { ExperimentScorecardTable } from "./ExperimentScorecardTable";

const useStyles = makeResponsiveStyles(
  {
    root: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      boxSizing: "border-box",
      minHeight: "100%",
      height: "100%",
      maxHeight: "calc(100vh - 100px)",
      padding: "24px",
      overflow: "auto",
      backgroundColor: "#FFF",
    },
    title: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      width: "100%",
    },
    titleLeft: {
      display: "flex",
      alignItems: "center",
    },
    titleRight: {
      display: "flex",
      alignItems: "center",
    },
    columnContainer: {
      display: "flex",
      flexDirection: "column",
    },
    rowContainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "flex-start",
      ...shorthands.gap("8px"),
    },
    listTable: {
      width: "100%",
      flex: 1,
    },
    label: {
      ...shorthands.margin("5px", "0", "5px", "0"),
      fontFamily: tokens.fontFamilyBase,
      fontWeight: 600,
      fontSize: "12px",
      lineHeight: "16px",
      fontStyle: "normal",
      color: "#605E5C",
    },
    functionBarContainer: {
      width: "100%",
      ...shorthands.margin("15px", "0", "20px", "0"),
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      flexWrap: "wrap-reverse",
      justifyContent: "space-between",
    },
    icon: {
      marginRight: "18px",
    },
  },
  {
    xs: {
      rowContainer: {
        width: "100%",
      },
    },
    sm: {
      rowContainer: {
        width: "100%",
      },
    },
    md: {
      rowContainer: {
        width: "100%",
      },
    },
    lg: {
      rowContainer: {
        maxWidth: "70%",
      },
    },
  },
);

export const ExperimentScorecardList = observer(() => {
  const styles = useStyles();
  const { experimentId } = useParams();
  const experimentQuery = new URLSearchParams(useLocation().search);
  const experimentName = experimentQuery.get("name") ?? "";
  const experimentCreateTime = experimentQuery.get("createTime") ?? "";
  const [loadingScorecards, setLoadingScorecards] = React.useState(false);
  const [allScorecards, setAllScorecards] = React.useState<Scorecard[]>([]);
  const [scorecardFilterStatus, setScorecardFilterStatus] =
    React.useState("All");
  const [scorecardFilterProgression, setScorecardFilterProgression] =
    React.useState("All");
  const [scorecardFilterRing, setScorecardFilterRing] = React.useState("All");
  const [scorecardFilterTimeRange, setScorecardFilterTimeRange] =
    React.useState("All");
  const [scorecardFilterRunningStage, setScorecardFilterRunningStage] =
    React.useState(false);

  React.useEffect(() => {
    setScorecardFilterStatus("All");
    setScorecardFilterProgression("All");
    setScorecardFilterRing("All");
    setScorecardFilterTimeRange("All");
    setScorecardFilterRunningStage(false);
    if (!experimentId) {
      return;
    }
    const params = {
      workspaceId: Exp_Workspace_Id,
      query: {
        experimentId,
      },
    };
    setLoadingScorecards(true);
    perfWrapper(
      "LoadExperimentScorecardList",
      getExperimentScorecards(params)
        .then((scorecards) => {
          setLoadingScorecards(false);
          setAllScorecards(
            scorecards.sort(
              (a, b) => Number(b.analysisTaskId) - Number(a.analysisTaskId),
            ),
          );
        })
        .catch(() => {
          setLoadingScorecards(false);
        }),
    );
  }, [experimentId]);

  const filterDropdownOptions = React.useMemo(() => {
    const statusSet = new Set<string>(["All"]);
    const progressionSet = new Set<string>(["All"]);
    const ringSet = new Set<string>(["All"]);
    const timeRangeSet = new Set<string>(["All"]);
    allScorecards.forEach((scorecard) => {
      if (scorecard.analysisTaskState) {
        statusSet.add(formatScorecardStatus(scorecard.analysisTaskState));
      }
      if (scorecard.progressionName) {
        progressionSet.add(scorecard.progressionName);
      }
      if (scorecard.stageName && scorecard.experimentStepName) {
        ringSet.add(`${scorecard.stageName} ${scorecard.experimentStepName}`);
      }
      if (scorecard.timeRangeStart && scorecard.timeRangeEnd) {
        timeRangeSet.add(
          `${formatDatetimeRange(
            scorecard.timeRangeStart,
            scorecard.timeRangeEnd,
          )}`,
        );
      }
    });
    return {
      status: Array.from(statusSet).map((v) => {
        return { key: v, value: v, name: v };
      }),
      progression: Array.from(progressionSet).map((v) => {
        return { key: v, value: v, name: v };
      }),
      ring: Array.from(ringSet)
        .sort()
        .map((v) => {
          return { key: v, value: v, name: v };
        }),
      timeRange: Array.from(timeRangeSet)
        .sort((a, b) => parseInt(a) - parseInt(b))
        .map((v) => {
          return { key: v, value: v, name: v };
        }),
    };
  }, [allScorecards]);

  const scorecardFilter = React.useMemo(() => {
    return {
      experiment: experimentId,
      status: scorecardFilterStatus,
      progression: scorecardFilterProgression,
      ring: scorecardFilterRing,
      timeRange: scorecardFilterTimeRange,
      runningStage: scorecardFilterRunningStage,
    };
  }, [
    experimentId,
    scorecardFilterStatus,
    scorecardFilterProgression,
    scorecardFilterRing,
    scorecardFilterTimeRange,
    scorecardFilterRunningStage,
  ]);

  if (!experimentId) {
    return (
      <div className={styles.root}>
        <div className={styles.title}>
          <div className={styles.titleLeft}></div>
        </div>
      </div>
    );
  }

  return (
    <>
      <ShadowABBreadcrumb
        nav={[
          { text: "Experiments", link: "/shadowab" },
          { text: experimentName ?? "" },
        ]}
      />
      <div className={styles.root}>
        <div className={styles.title}>
          <div className={styles.titleLeft}>
            <Subtitle1>{experimentName}</Subtitle1>
            <Link
              to={`https://exp.microsoft.com/a/feature/${experimentId}?workspaceId=${Exp_Workspace_Id}`}
              target="_blank"
            >
              <Button
                appearance="transparent"
                icon={<Open20Regular />}
                className={styles.icon}
              />
            </Link>
            <span>
              {experimentCreateTime ? formatDatetime(experimentCreateTime) : ""}
            </span>
          </div>
          <div className={styles.titleRight}>
            <Switch
              checked={scorecardFilterRunningStage}
              onChange={(_e, data) =>
                setScorecardFilterRunningStage(data.checked)
              }
              label="Running stage only"
            />
          </div>
        </div>
        <div className={styles.functionBarContainer}>
          <div className={styles.rowContainer}>
            <ResponsiveRow
              maxColumnCount={5}
              maxColumnCountSmall={1}
              columnGap={8}
            >
              <div className={styles.columnContainer}>
                <label className={styles.label}>Time range</label>
                <Dropdown
                  aria-label="Time Range Dropdown"
                  value={scorecardFilterTimeRange}
                  onOptionSelect={(_, data) => {
                    setScorecardFilterTimeRange(data.optionValue ?? "");
                  }}
                >
                  {filterDropdownOptions.timeRange.map((option) => (
                    <Option key={option.key} value={option.value}>
                      {option.name}
                    </Option>
                  ))}
                </Dropdown>
              </div>
              <div className={styles.columnContainer}>
                <label className={styles.label}>Status</label>
                <Dropdown
                  aria-label="Status Dropdown"
                  value={scorecardFilterStatus}
                  onOptionSelect={(_, data) => {
                    setScorecardFilterStatus(data.optionValue ?? "");
                  }}
                >
                  {filterDropdownOptions.status.map((option) => (
                    <Option key={option.key} value={option.value}>
                      {option.name}
                    </Option>
                  ))}
                </Dropdown>
              </div>
              <div className={styles.columnContainer}>
                <label className={styles.label}>Progression name</label>
                <Dropdown
                  aria-label="Progression Dropdown"
                  value={scorecardFilterProgression}
                  onOptionSelect={(_, data) => {
                    setScorecardFilterProgression(data.optionValue ?? "");
                  }}
                >
                  {filterDropdownOptions.progression.map((option) => (
                    <Option key={option.key} value={option.value}>
                      {option.name}
                    </Option>
                  ))}
                </Dropdown>
              </div>

              <div className={styles.columnContainer}>
                <label className={styles.label}>Stage and step</label>
                <Dropdown
                  aria-label="Stage and step Dropdown"
                  value={scorecardFilterRing}
                  onOptionSelect={(_, data) => {
                    setScorecardFilterRing(data.optionValue ?? "");
                  }}
                >
                  {filterDropdownOptions.ring.map((option) => (
                    <Option key={option.key} value={option.value}>
                      {option.name}
                    </Option>
                  ))}
                </Dropdown>
              </div>
            </ResponsiveRow>
          </div>
        </div>
        <div className={styles.listTable}>
          <ExperimentScorecardTable
            filter={scorecardFilter}
            allScorecards={allScorecards}
            isLoading={loadingScorecards}
          />
        </div>
      </div>
    </>
  );
});
