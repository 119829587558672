import { action } from "satcheljs";

export const ingestSyntheticDataAction = action(
  "ingestSyntheticDataAction",
  (
    containerName: string,
    blobName: string,
    submittedBy: string,
    onSuccess?: () => void,
    onError?: (error: Error) => void,
  ) => ({
    containerName,
    blobName,
    submittedBy,
    onSuccess,
    onError,
  }),
);
