import { shorthands, tokens } from "@fluentui/react-components";
import { makeResponsiveStyles } from "../../../../../components/Responsive/makeResponsiveStyles";

export const useStyles = makeResponsiveStyles(
  {
    root: {
      backgroundColor: "white",
      boxSizing: "border-box",
      display: "flex",
      flexDirection: "column",
      ...shorthands.gap("20px"),
      ...shorthands.padding("24px", "87px", "24px", "87px"),
      ...shorthands.border("1px", "solid", "#EDEBE9"),
      ...shorthands.borderRadius("8px"),
    },
    block: {
      display: "flex",
      flexDirection: "column",
      ...shorthands.flex(1),
      width: "100%",
      ...shorthands.gap("8px"),
    },
    blockTitle: {
      fontFamily: tokens.fontFamilyBase,
      fontSize: "16px",
      fontWeight: 600,
      lineHeight: "22px",
    },
    metricsContainer: {
      ...shorthands.borderRadius("8px"),
      ...shorthands.border("1px", "solid", "#EDEBE9"),
      ...shorthands.padding("10px", "12px"),
    },
    checkboxContainer: {
      flexBasis: "1",
      flexGrow: "1",
      display: "flex",
      flexDirection: "row",
      ...shorthands.gap("12px"),
      fontFamily: tokens.fontFamilyBase,
      fontSize: "14px",
      fontWeight: 400,
      lineHeight: "20px",
    },
    checkbox: {
      '> input[type="checkbox"]:checked': {
        backgroundColor: "red",
        color: "red",
      },
    },
    checkboxContainerWithWarning: {
      flexBasis: "1",
      flexGrow: "1",
      display: "flex",
      flexDirection: "column",
      ...shorthands.gap("5px"),
      fontFamily: tokens.fontFamilyBase,
      fontSize: "14px",
      fontWeight: 400,
      lineHeight: "20px",
    },
  },
  {
    xs: {
      root: {
        ...shorthands.padding("24px", "24px", "24px", "24px"),
      },
    },
  },
);
