import { array, bool, num, object, optional, str } from "sydneyeval-shared";

export const Experiment = object({
  experimentId: str,
  experimentName: optional(str),
  experimentDescription: optional(str),
  experimentCreateTime: optional(str),
  owners: optional(array(str)),
  managementGroup: optional(str),
});
export const Scorecard = object({
  analysisTaskId: optional(str),
  analysisTaskState: optional(str),
  scorecardId: optional(str),
  scorecardState: optional(str),
  analysisStudyId: optional(str),
  analysisStudyName: optional(str),
  stageId: optional(str),
  stageName: optional(str),
  experimentStepId: optional(str),
  experimentStepName: optional(str),
  stageState: optional(str),
  progressionId: optional(str),
  progressionName: optional(str),
  isProgressionActive: optional(bool),
  createdTime: optional(str),
  experimentId: optional(str),
  experimentName: optional(str),
  managementGroup: optional(str),
  timeRangeStart: optional(str),
  timeRangeEnd: optional(str),
  segments: optional(
    array(
      object({
        name: optional(str),
      }),
    ),
  ),
});
export const FeatureEvalExperiment = object({
  ID: optional(num),
  Name: optional(str),
  Stage: optional(str),
  FeatureId: optional(str),
  IterationId: optional(str),
  Group: optional(str),
  UpstreamId: optional(num),
  ExternalId: optional(str),
  ExternalLink: optional(str),
  CreatedBy: optional(str),
  CreatedAt: optional(str),
});

export const ScorecardMetricsMeta = object({
  experimentStepId: optional(str),
  value: optional(
    array(
      object({
        controlVariant: optional(str),
        treatmentVariant: optional(str),
        segmentId: optional(str),
      }),
    ),
  ),
});
export const ScorecardMetrics = object({
  metricName: optional(str),
  metricLabel: optional(str),
  scorecardLayout: optional(str),
  scorecardLayoutTab: optional(str),
  metricCategories: optional(array(str)),
  desirableDirection: optional(str),
  rollupType: optional(str),
  controlVariant: optional(
    object({
      average: optional(num),
    }),
  ),
  treatmentVariant: optional(
    object({
      average: optional(num),
    }),
  ),
  delta: optional(
    object({
      absolute: optional(num),
      relative: optional(num),
    }),
  ),
  testResult: optional(
    object({
      pMove: optional(
        object({
          increaseProbability: optional(num),
          decreaseProbability: optional(num),
        }),
      ),
      pValue: optional(num),
    }),
  ),
});

export type Experiment = ReturnType<typeof Experiment>;
export type Scorecard = ReturnType<typeof Scorecard>;
export type ScorecardMetrics = ReturnType<typeof ScorecardMetrics>;
export type ScorecardMetricsMeta = ReturnType<typeof ScorecardMetricsMeta>;
export type FeatureEvalExperiment = ReturnType<typeof FeatureEvalExperiment>;

export const ExperimentsResponse = array(Experiment);
export const SetupExperimentResponse = object({
  uuid: str,
});
export const ExperimentScorecardsResponse = array(Scorecard);
export const ScorecardMetricsMetaResponse = ScorecardMetricsMeta;
export const ScorecardMetricsResponse = object({
  value: array(ScorecardMetrics),
  nextLink: optional(str),
});

export type ExperimentsResponse = ReturnType<typeof ExperimentsResponse>;
export type SetupExperimentResponse = ReturnType<
  typeof SetupExperimentResponse
>;
export type ExperimentScorecardsResponse = ReturnType<
  typeof ExperimentScorecardsResponse
>;
export type ScorecardMetricsMetaResponse = ReturnType<
  typeof ScorecardMetricsMeta
>;
export type ScorecardMetricsResponse = ReturnType<typeof ScorecardMetrics>;
export type FeatureEvalExperimentResponse = ReturnType<
  typeof FeatureEvalExperiment
>;
