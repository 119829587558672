import { orchestrator } from "satcheljs";
import { perfWrapper } from "../../../helpers/telemetryHelper";
import {
  switchMChatScenarioAction,
  updateBingDiagnosisSummaryAction,
  updateSelectBingFileAction,
  updateSelectedFilterAction,
} from "../actions/resultActions";
import {
  getAndUpdateCitationLinks,
  getAnswerTriggerData,
  getDiagnosisSummaryData,
  getE2EConciseMetricsData,
  getExtensionTriggerData,
  getMetricsSummaryData,
  getPluginTriggerData,
} from "../helpers/bingResultHelper";
import { getMChatMetrics } from "../helpers/renderMetricsHelper";
import type { BingJobFile } from "../models/BingJobFile";
import {
  addLoadingMessage,
  removeLoadingMessage,
  resetSelectedFilter,
  switchScenario,
  updateBingAnswerTriggerData,
  updateBingDiagnosisSummary,
  updateBingExtensionTriggerData,
  updateBingPluginTriggerData,
  updateConciseTable,
  updateDiagnosisSortBy,
  updateSelectBingFile,
  updateSelectedFilter,
  updateSummaryTable,
} from "../mutators/jobResultMutators";
import { disableDiagnosisView } from "../selectors/jobDetailHelper";
import { getBingDropdownValuesInDiagnosisPage } from "../selectors/prepareBingJobResult";
import { resultStore } from "../store/resultStore";

const updateDiagnosisFile = (fileList: BingJobFile[]) => {
  const downloaded = resultStore.diagnosisSummaries?.get(
    resultStore.seletedBingFile ?? "",
  );
  if (downloaded !== undefined) {
    updateBingDiagnosisSummaryAction(downloaded);
    return;
  }

  addLoadingMessage("Loading Bing Diagnosis Summary");
  perfWrapper(
    "LoadBingDiagnosisSummary",
    getDiagnosisSummaryData(fileList),
  ).finally(() => {
    removeLoadingMessage("Loading Bing Diagnosis Summary");
  });
};

export type ResultPageLoadingMessage =
  | "Loading Metrics Summary"
  | "Loading E2E Concise Metrics"
  | "Loading Answer Trigger Rate Metrics"
  | "Loading Plugin Trigger Rate Metrics"
  | "Loading Extension Trigger Rate Metrics"
  | "Loading Bing Diagnosis Summary"
  | "Loading SBS Experiment"
  | "Loading LM Checklist"
  | "Loading MChat metrics"
  | "Loading Privacy Filter Query Set"
  | "Loading General Metrics"
  | "Loading Bing Job Output Files"
  | "Loading MChat Metric Diagnosis Utterance List"
  | "Loading MChat Metric Diagnosis SValue";

export const loadingRequestWrapper = (
  promis: Promise<void>,
  message: ResultPageLoadingMessage,
) => {
  addLoadingMessage(message);
  return promis.finally(() => {
    removeLoadingMessage(message);
  });
};

const loadData = (
  key: string,
  updateFunction: (arg: Record<string, string>[]) => void,
  getDataFunction: (fileList: BingJobFile[]) => Promise<void>,
  message: ResultPageLoadingMessage,
  fileList: BingJobFile[],
) => {
  const downloaded = resultStore.downloadedBingTables?.get(key);
  if (downloaded !== undefined) {
    updateFunction(downloaded);
    return;
  }
  loadingRequestWrapper(getDataFunction(fileList), message);
};

const updateAllMetricsTable = (fileList: BingJobFile[]) => {
  loadData(
    `${resultStore.seletedBingFile}/summary`,
    updateSummaryTable,
    getMetricsSummaryData,
    "Loading Metrics Summary",
    fileList,
  );
  loadData(
    `${resultStore.seletedBingFile}/concise`,
    updateConciseTable,
    getE2EConciseMetricsData,
    "Loading E2E Concise Metrics",
    fileList,
  );
  loadData(
    `${resultStore.seletedBingFile}/answer`,
    updateBingAnswerTriggerData,
    getAnswerTriggerData,
    "Loading Answer Trigger Rate Metrics",
    fileList,
  );
  loadData(
    `${resultStore.seletedBingFile}/plugin`,
    updateBingPluginTriggerData,
    getPluginTriggerData,
    "Loading Plugin Trigger Rate Metrics",
    fileList,
  );
  loadData(
    `${resultStore.seletedBingFile}/extension`,
    updateBingExtensionTriggerData,
    getExtensionTriggerData,
    "Loading Extension Trigger Rate Metrics",
    fileList,
  );
};

orchestrator(updateSelectBingFileAction, ({ path }) => {
  updateSelectBingFile(path);
  const outputfiles = resultStore.bingJobOutputFiles;
  if (resultStore.scenario === "Metric Diagnosis") {
    const fileList = outputfiles?.filter(
      (item) => item.folderName === resultStore.seletedBingFile,
    );

    if (fileList) {
      updateDiagnosisFile(fileList);
    }
  }
  if (resultStore.scenario === "Scorecard Comparison") {
    const fileList = outputfiles?.filter(
      (item) => item.folderName === resultStore.seletedBingFile,
    );

    if (fileList === undefined) {
      return;
    }
    updateAllMetricsTable(fileList);
    resetSelectedFilter();
  }
});

orchestrator(updateSelectedFilterAction, ({ filter, value }) => {
  updateSelectedFilter(filter, value);
  getAndUpdateCitationLinks();
  if (filter === "Metrics" && resultStore.diagnosisSortBy !== undefined) {
    const selectedMetrics = value?.split(",") ?? [];
    const hasSortedBy = selectedMetrics.find(
      (item) => item === resultStore.diagnosisSortBy?.column,
    );
    if (hasSortedBy === undefined && selectedMetrics.length > 0) {
      updateDiagnosisSortBy(selectedMetrics[0]);
    }
  }
});

orchestrator(updateBingDiagnosisSummaryAction, ({ table }) => {
  updateBingDiagnosisSummary(table);
  const options = getBingDropdownValuesInDiagnosisPage.get();
  options.forEach((value, key) => {
    updateSelectedFilter(key, value[0]);
  });
  getAndUpdateCitationLinks();
  updateDiagnosisSortBy(options.get("Metrics")?.[0] ?? "");
});

orchestrator(switchMChatScenarioAction, ({ scenario }) => {
  switchScenario(scenario);
  if (disableDiagnosisView.get()) {
    return;
  }

  switch (scenario) {
    case "Metric Diagnosis":
      getMChatMetrics();
      break;
    default:
      break;
  }
});
