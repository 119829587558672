"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ClientFeatureFlagConfig = exports.ClientFeatureFlagSets = exports.ClientFeatureFlagScopes = exports.ClientFeatureFlagDefaults = exports.ClientFeatureFlags = void 0;
const typeHelper_1 = require("../tools/typeHelper");
// Please refer the following wiki page for portal client feature rollout
// https://dev.azure.com/msasg/Cortana/_wiki/wikis/M365%20Chat.wiki/275957/Release-Features-for-SEVAL-Portal-Client
exports.ClientFeatureFlags = [
    ["shadowAB", false],
    ["use-dev-immunity", true],
    ["product-registration", false],
    ["eval-time-override", false],
    ["bypass-jobprevalidation", false],
    ["strict-permission-check", false],
    ["append-timezone", false],
    ["refresh-token-migration", false],
    ["get-user-list-from-blob", false],
    ["generate-refresh-token", false],
    ["use-blob-cache", false],
    ["gpt-agent-list", false],
    ["job-priority", false],
    ["enable-new-metrics", true], // used to test to compare old and new metrics
    ["enable-new-leo-details", false], // enable to get leo details from new table
    ["select-synthetic-tenant-test-user", false, ["LOCALHOST", "STAGING"]], // used to select test user & query set for Synthetic Tenants
    ["enable-synthetic-data-ingestion", false, ["LOCALHOST", "STAGING"]], // used to submit a request to trigger a data init ingestion workflow
    ["disable-eyeson-personal", false],
];
exports.ClientFeatureFlagDefaults = exports.ClientFeatureFlags.reduce((acc, cur) => {
    acc[cur[0]] = cur[1];
    return acc;
}, {});
exports.ClientFeatureFlagScopes = exports.ClientFeatureFlags.reduce((acc, cur) => {
    if (cur.length === 3) {
        acc[cur[0]] = cur[2];
    }
    return acc;
}, {});
exports.ClientFeatureFlagSets = (0, typeHelper_1.object)(exports.ClientFeatureFlags.reduce((acc, cur) => {
    acc[cur[0]] = (0, typeHelper_1.optional)(typeHelper_1.bool);
    return acc;
}, {}));
exports.ClientFeatureFlagConfig = (0, typeHelper_1.object)({
    portal: exports.ClientFeatureFlagSets,
});
