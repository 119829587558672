import { computed } from "mobx";
import { resultStore } from "../store/resultStore";

export const getBingDropdownValues = computed(() => {
  const getValuesForColumn = (column: string) => {
    if (!resultStore.summaryTable || resultStore.summaryTable.length === 0) {
      return [];
    }

    if (resultStore.summaryTable[0][column] === undefined) {
      return [];
    }
    const allValues = new Set<string>(
      resultStore.summaryTable
        ?.map((record) => record[column].toString())
        .filter((item) => item.length !== 0),
    );

    return allValues.size > 0 ? Array.from(allValues) : [];
  };

  const dropdownOptionsMap = new Map<string, string[]>();
  dropdownOptionsMap.set("Metrics", getValuesForColumn("Metric"));
  dropdownOptionsMap.set("Segments", getValuesForColumn("segment"));
  dropdownOptionsMap.set(
    "Control/Treatment",
    resultStore.controlTreatmentPairs?.map((pair) =>
      pair.treatment === undefined
        ? pair.control
        : `${pair.control}/${pair.treatment}`,
    ) ?? [],
  );

  return dropdownOptionsMap;
});

export const getBingDropdownValuesInDiagnosisPage = computed(() => {
  if (resultStore.diagnosisSummary === undefined) {
    return new Map<string, string[]>();
  }

  const dropdownOptionsMap = new Map<string, string[]>();
  const allMetrics = Object.keys(resultStore.diagnosisSummary);
  dropdownOptionsMap.set("Metrics", allMetrics);
  const selectedMetric = resultStore.selectedFilter
    .get("Metrics")
    ?.split(",")?.[0];
  const firstMetric = selectedMetric ?? allMetrics[0];
  if (firstMetric) {
    const firstMetricData = resultStore.diagnosisSummary[firstMetric];
    if (firstMetricData !== undefined) {
      const allSegments = Object.keys(firstMetricData);
      const selectedSegment = resultStore.selectedFilter.get("Segments");
      const showSelectedSegment =
        selectedSegment !== undefined && allSegments.includes(selectedSegment);
      dropdownOptionsMap.set("Segments", allSegments);
      const firstSegment = showSelectedSegment
        ? selectedSegment
        : allSegments[0];
      if (firstSegment) {
        const firstSegmentData = firstMetricData[firstSegment];
        if (firstSegmentData !== undefined) {
          const allExperiments = Object.keys(firstSegmentData);
          dropdownOptionsMap.set("Control/Treatment", allExperiments);
        }
      } else {
        dropdownOptionsMap.set("Control/Treatment", []);
      }
    } else {
      dropdownOptionsMap.set("Segments", []);
      dropdownOptionsMap.set("Control/Treatment", []);
    }
  }

  return dropdownOptionsMap;
});
export const getSummaryTableColumns = computed(() => {
  if (!resultStore.summaryTable || resultStore.summaryTable.length === 0) {
    return [];
  }
  return Object.keys(resultStore.summaryTable[0]);
});

export const getSummaryTableData = computed(() => {
  if (!resultStore.summaryTable || resultStore.summaryTable.length === 0) {
    return [];
  }

  const filters = Array.from(resultStore.selectedFilter.keys());
  if (!resultStore.selectedFilter || filters.length === 0) {
    return resultStore.summaryTable;
  }
  return resultStore.summaryTable.filter((record) => {
    const selectedMetric = resultStore.selectedFilter.get("Metrics");
    const isMetricMatch =
      selectedMetric === undefined ? true : record["Metric"] === selectedMetric;
    const selectedSegment = resultStore.selectedFilter.get("Segments");
    const isSegmentMatch =
      selectedSegment === undefined
        ? true
        : record["segment"] === selectedSegment;
    const selectedControlTreatment = resultStore.selectedFilter
      .get("Control/Treatment")
      ?.split("/");
    const isControlTreatmentMatch =
      selectedControlTreatment === undefined
        ? true
        : selectedControlTreatment.find(
            (item) => record["exp_name"].indexOf(item) >= 0,
          ) !== undefined;

    return isMetricMatch && isSegmentMatch && isControlTreatmentMatch;
  });
});

export const getConciseTableColumns = computed(() => {
  if (!resultStore.conciseTable || resultStore.conciseTable.length === 0) {
    return [];
  }
  return Object.keys(resultStore.conciseTable[0]);
});

export const getConciseTableData = computed(() => {
  if (!resultStore.conciseTable || resultStore.conciseTable.length === 0) {
    return [];
  }

  const filters = Array.from(resultStore.selectedFilter.keys());
  if (!resultStore.selectedFilter || filters.length === 0) {
    return resultStore.conciseTable;
  }
  return resultStore.conciseTable.filter((record) => {
    const selectedMetric = resultStore.selectedFilter.get("Metrics");
    const isMetricMatch =
      selectedMetric === undefined ? true : record["Metric"] === selectedMetric;
    const selectedControlTreatment = resultStore.selectedFilter
      .get("Control/Treatment")
      ?.split("/");
    const isControlTreatmentMatch =
      selectedControlTreatment === undefined
        ? true
        : selectedControlTreatment.find(
            (item) => record["exp_name"].indexOf(item) >= 0,
          ) !== undefined;

    return isMetricMatch && isControlTreatmentMatch;
  });
});

export const getAnswerTriggerRateColumns = computed(() => {
  if (
    !resultStore.answerTriggerData ||
    resultStore.answerTriggerData.length === 0
  ) {
    return [];
  }
  return Object.keys(resultStore.answerTriggerData[0]).filter(
    (item) => item !== "Category" && item !== "name",
  );
});

export const getAnswerTriggerRateData = computed(() => {
  if (
    !resultStore.answerTriggerData ||
    resultStore.answerTriggerData.length === 0
  ) {
    return [];
  }

  const filters = Array.from(resultStore.selectedFilter.keys());
  if (!resultStore.selectedFilter || filters.length === 0) {
    return resultStore.answerTriggerData;
  }
  return resultStore.answerTriggerData.filter((record) => {
    const selectedSegment = resultStore.selectedFilter.get("Segments");
    const isSegmentMatch =
      selectedSegment === undefined
        ? true
        : record["segment"] === selectedSegment;
    const selectedControlTreatment = resultStore.selectedFilter
      .get("Control/Treatment")
      ?.split("/");
    const isControlTreatmentMatch =
      selectedControlTreatment === undefined
        ? true
        : selectedControlTreatment.find(
            (item) => record["name"].indexOf(item) >= 0,
          ) !== undefined;

    return isSegmentMatch && isControlTreatmentMatch;
  });
});

export const getPluginTriggerColumns = computed(() => {
  if (
    !resultStore.pluginTriggerData ||
    resultStore.pluginTriggerData.length === 0
  ) {
    return [];
  }
  return Object.keys(resultStore.pluginTriggerData[0]).filter(
    (item) => item !== "Category" && item !== "name",
  );
});

export const getPluginTriggerTableData = computed(() => {
  if (
    !resultStore.pluginTriggerData ||
    resultStore.pluginTriggerData.length === 0
  ) {
    return [];
  }

  const filters = Array.from(resultStore.selectedFilter.keys());
  if (!resultStore.selectedFilter || filters.length === 0) {
    return resultStore.pluginTriggerData;
  }
  return resultStore.pluginTriggerData.filter((record) => {
    const selectedSegment = resultStore.selectedFilter.get("Segments");
    const isSegmentMatch =
      selectedSegment === undefined
        ? true
        : record["segment"] === selectedSegment;
    const selectedControlTreatment = resultStore.selectedFilter
      .get("Control/Treatment")
      ?.split("/");
    const isControlTreatmentMatch =
      selectedControlTreatment === undefined
        ? true
        : selectedControlTreatment.find(
            (item) => record["name"].indexOf(item) >= 0,
          ) !== undefined;

    return isSegmentMatch && isControlTreatmentMatch;
  });
});

export const getExtensionTriggerColumns = computed(() => {
  if (
    !resultStore.extensionTriggerData ||
    resultStore.extensionTriggerData.length === 0
  ) {
    return [];
  }
  return Object.keys(resultStore.extensionTriggerData[0]).filter(
    (item) => item !== "Category" && item !== "name",
  );
});

export const getExtensionTriggerTableData = computed(() => {
  if (
    !resultStore.extensionTriggerData ||
    resultStore.extensionTriggerData.length === 0
  ) {
    return [];
  }

  const filters = Array.from(resultStore.selectedFilter.keys());
  if (!resultStore.selectedFilter || filters.length === 0) {
    return resultStore.extensionTriggerData;
  }
  return resultStore.extensionTriggerData.filter((record) => {
    const selectedSegment = resultStore.selectedFilter.get("Segments");
    const isSegmentMatch =
      selectedSegment === undefined
        ? true
        : record["segment"] === selectedSegment;
    const selectedControlTreatment = resultStore.selectedFilter
      .get("Control/Treatment")
      ?.split("/");
    const isControlTreatmentMatch =
      selectedControlTreatment === undefined
        ? true
        : selectedControlTreatment.find(
            (item) => record["name"].indexOf(item) >= 0,
          ) !== undefined;

    return isSegmentMatch && isControlTreatmentMatch;
  });
});

export const getDiagnosisTableData = computed(() => {
  if (!resultStore.diagnosisSummary) {
    return [];
  }

  const selectedMetric = resultStore.selectedFilter.get("Metrics")?.split(",");
  const sortMetric = resultStore.diagnosisSortBy?.column ?? selectedMetric?.[0];
  const isAsc = resultStore.diagnosisSortBy?.direction === "asc";
  const selectedSegment = resultStore.selectedFilter.get("Segments");
  const selectedControlTreatment =
    resultStore.selectedFilter.get("Control/Treatment");
  if (
    sortMetric === undefined ||
    selectedSegment === undefined ||
    selectedControlTreatment === undefined
  ) {
    return [];
  }

  try {
    const data =
      resultStore.diagnosisSummary[sortMetric][selectedSegment][
        selectedControlTreatment
      ];
    if (data === undefined) {
      return [];
    }

    return isAsc ? data.sbs_views : data.sbs_views.slice().reverse();
  } catch (e) {
    return [];
  }
});

export const getLoadedMetricsData = computed(() => {
  if (!resultStore.loadedMetrics) {
    return [];
  }

  return Array.from(resultStore.loadedMetrics.keys()).map((key) => {
    return {
      Key: key,
      Conversation:
        resultStore.loadedSBSViews.get(key)?.baseline[0].Request ?? "",
      Metrics: resultStore.loadedMetrics.get(key) ?? {},
    };
  });
});
