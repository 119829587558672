import { observer } from "mobx-react-lite";
import { updateJsonStringAction } from "../../../actions/jobActions";
import { getOPGRules } from "../../../helpers/editorRuleHelper";
import { getMultiServiceTemplate } from "../../../selectors/getTemplateType";
import { jobStore } from "../../../store/jobStore";
import { JSONEditorView } from "../../Other/JSONEditorView";
import { JobUploadFolderView } from "../components/JobUploadFolderView";
import { OPGMultiServiceRender } from "./OPGMultiServiceRender";

export const OPGTemplateRender = observer(() => {
  const isMultiService = getMultiServiceTemplate.get();
  if (isMultiService) {
    return <OPGMultiServiceRender />;
  }
  return (
    <>
      <JobUploadFolderView />
      <JSONEditorView
        key="opg_json_editor_OPGTemplateRender"
        object={jobStore.configuration}
        path={""}
        rules={getOPGRules()}
        onChange={(configs: string) => {
          updateJsonStringAction(JSON.stringify(configs, null, 2));
        }}
      />
    </>
  );
});
