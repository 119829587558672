import type { JobTemplate } from "../models/JobTemplate";
import type { BizChatQuerySetValidationResult } from "../store/jobStore";
import { initJobStore, jobStore } from "../store/jobStore";

import { set, unset } from "lodash";
import { mutatorAction } from "satcheljs";
import {
  anyObject,
  JobConfiguration,
  parseJsonStrWithDefault,
} from "sydneyeval-shared";
import type {
  JobConfigurationDeleteAction,
  JobConfigurationUpdateAction,
} from "../models/JobConfigurationAction";
import type { EvaluationType } from "../models/Types";

export const resetJobStore = mutatorAction("resetJobStore", () => {
  Object.assign(jobStore, {
    ...initJobStore,
    productFilter: jobStore.productFilter,
  });
});

export const updateProductFilter = mutatorAction(
  "updateProductFilter",
  (productId: string) => {
    jobStore.productFilter = productId;
  },
);

export const updateJobName = mutatorAction(
  "updateJobName",
  (newName: string) => {
    jobStore.jobName = newName;
  },
);

export const updateAvailableTemplates = mutatorAction(
  "updateAvailableTemplates",
  (list: JobTemplate[]) => {
    jobStore.availableTemplates = list;
  },
);

export const updateDataSetsString = mutatorAction(
  "updateDataSetsString",
  (dataSetsStr: string) => {
    jobStore.dataSetsString = dataSetsStr;
  },
);

export const updateJsonString = mutatorAction(
  "updateJsonString",
  (jsonStr: string) => {
    jobStore.configurationJsonString = jsonStr;
  },
);

export const updateSelectedTemplate = mutatorAction(
  "updateSelectedTemplate",
  (selected: JobTemplate) => {
    jobStore.selectedTemplate = selected;
    jobStore.jobName = selected.Name;
    const rawConfig = parseJsonStrWithDefault(selected.Settings, anyObject, {});
    if ("default_settings" in rawConfig) {
      const rawConfigObject = parseJsonStrWithDefault(
        selected.Settings,
        JobConfiguration,
        {},
      );
      jobStore.defaultTemplateSettings = rawConfigObject?.default_settings;
      delete rawConfig.default_settings;
    }
    jobStore.configuration = rawConfig;
    jobStore.dataSetsString = (() => {
      if (!selected.Datasets) {
        return undefined;
      }

      try {
        return JSON.stringify(JSON.parse(selected.Datasets), null, 2);
      } catch {
        return selected.Datasets;
      }
    })();

    jobStore.configurationJsonString = JSON.stringify(
      jobStore.configuration,
      null,
      2,
    );
  },
);

export const overrideProductPipelineSettings = mutatorAction(
  "overrideProductPipelineSettings",
  (settings: string) => {
    jobStore.configuration = parseJsonStrWithDefault(settings, anyObject, {});
    jobStore.configurationJsonString = JSON.stringify(
      jobStore.configuration,
      null,
      2,
    );
  },
);

export const updateJsonStringFromConfiguration = mutatorAction(
  "updateJsonStringFromConfiguration",
  () => {
    jobStore.configurationJsonString = JSON.stringify(
      jobStore.configuration,
      null,
      2,
    );
  },
);

export const updateSelectedEvaluationType = mutatorAction(
  "updateSelectedEvaluationType",
  (selected: EvaluationType) => {
    jobStore.selectedEvaluationType = selected;
  },
);

export const updateConfiguration = mutatorAction(
  "updateConfiguration",
  (config: object) => {
    jobStore.configuration = config;
  },
);

export const updatePropValueV2 = mutatorAction(
  "updatePropValue",
  (update: JobConfigurationUpdateAction) => {
    // We directly update the configuration object by path
    // the `set` operation is not type safe, it will create a new property if the property does not exist
    // but we have already generated valid path in JobConfigurationUpdateAction
    // so we can safely use `set` here
    if (jobStore.configuration !== undefined) {
      set(jobStore.configuration, update.prop, update.newData);
    }
  },
);

export const deletePropValue = mutatorAction(
  "deletePropValue",
  (path: JobConfigurationDeleteAction) => {
    if (jobStore.configuration !== undefined) {
      unset(jobStore.configuration, path);
    }
  },
);

export const updateInputCache = mutatorAction(
  "updateInputCache",
  (prop: JobConfigurationUpdateAction["prop"], cache: string) => {
    jobStore.inputCache.set(prop, cache);
  },
);

export const updateBizchatQuerySetValidationResult = mutatorAction(
  "updateBizchatQuerySetValidationResult",
  (querySet: string, validationResult: BizChatQuerySetValidationResult) => {
    jobStore.bizchatQuerySetValidationResult.set(querySet, validationResult);
  },
);

export const updateSBSConfirmationResolve = mutatorAction(
  "updateSBSConfirmationResolve",
  (resolve: ((value: boolean) => void) | undefined) => {
    jobStore.SBSConfirmationResolve = resolve;
  },
);

export const updateBingAvailableClusters = mutatorAction(
  "updateBingAvailableClusters",
  (clusters: string[]) => {
    jobStore.availableClusters = clusters;
  },
);
