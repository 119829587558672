import { Button, shorthands, tokens } from "@fluentui/react-components";
import React, { useEffect } from "react";
import {
  createJob,
  getBingProductMetaData,
  getCustomLMChecklistSets,
  getJobTemplates,
  getPublicLMChecklistSets,
  listProducts,
} from "../../../../helpers/apiHelper";
import { updateSelectedTemplateAction } from "../../actions/jobActions";
import type { JobTemplate } from "../../models/JobTemplate";
import {
  resetJobCreationStore,
  updateStatusIsLoading,
  updateStatusIsSubmitting,
  updateStatusIsTokenDialogOpen,
  updateStatusIsVerifying,
} from "../../mutators/jobCreationMutators";
import {
  resetJobStore,
  updateAvailableTemplates,
} from "../../mutators/jobMutators";

import { observer } from "mobx-react-lite";
import { useLocation } from "react-router-dom";
import { BingMetaDataResponse, parseJsonStrOptional } from "sydneyeval-shared";
import { makeResponsiveStyles } from "../../../../components/Responsive/makeResponsiveStyles";
import { useToast } from "../../../../components/Wrappers/ToasterProvider";
import {
  perfWrapper,
  telemetryHelper,
} from "../../../../helpers/telemetryHelper";
import { updateCurrentPath } from "../../../../mutators/updateContributions";
import { resetJobPriorityStateAction } from "../../actions/jobPriorityActions";
import { getDefaultTemplate } from "../../helpers/jobCloneHelper";
import {
  confirmSBSValidator,
  isJobCreationEditDisabled,
  priorityJobValidator,
  refreshQuerySets,
  servicePrevalidation,
  tokenPopupValidator,
} from "../../helpers/jobCreationHelper";
import {
  updateCustomizedLMChecklistSets,
  updateCustomizedQuerySets,
  updatePublicLMChecklistSets,
} from "../../mutators/jobCreationFileMutators";
import {
  updateProductJobTemplates,
  updateProductMetaData,
  updateProducts,
} from "../../mutators/updateProductSettings";
import { getJobCreationErrorMessage } from "../../selectors/creationError/getJobCreationError";
import { generateCurrentJob } from "../../selectors/generateCurrentJob";
import { jobCreationStore } from "../../store/jobCreationStore";
import { jobStore } from "../../store/jobStore";
import { TokenUploadingDialog } from "../Dialog/TokenUploadingDialog";
import { ShareButton } from "../JobDetail/components/ShareButton";
import { MainRender } from "./TemplatesRenders/MainRender";
import { JobSBSConfirmationDialog } from "./components/JobSBSConfirmationDialog";

const useStyles = makeResponsiveStyles(
  {
    root: {
      backgroundColor: "white",
      boxSizing: "border-box",
      display: "flex",
      flexDirection: "column",
      ...shorthands.gap("20px"),
      ...shorthands.padding("24px", "87px", "24px", "87px"),
      ...shorthands.border("1px", "solid", "#EDEBE9"),
      ...shorthands.borderRadius("8px"),
    },
    rowWithMediumGap: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      ...shorthands.gap("32px"),
    },
    rowWithSmallGap: {
      display: "flex",
      width: "100%",
      flexDirection: "row",
      ...shorthands.gap("16px"),
    },
    columnWithSmallerGap: {
      display: "flex",
      width: "100%",
      flexDirection: "column",
      ...shorthands.gap("8px"),
    },
    block: {
      display: "flex",
      flexDirection: "column",
      ...shorthands.flex(1),
      width: "100%",
      ...shorthands.gap("8px"),
    },
    blockTitle: {
      fontFamily: tokens.fontFamilyBase,
      fontSize: "16px",
      fontWeight: 600,
      lineHeight: "22px",
    },
    dropdownMenu: {
      backgroundColor: "#F8F7F9",
    },
    spinner: {
      width: "100%",
    },
    metricsContainer: {
      ...shorthands.borderRadius("8px"),
      ...shorthands.border("1px", "solid", "#EDEBE9"),
      ...shorthands.padding("10px", "12px"),
    },
    checkboxContainer: {
      flexBasis: "1",
      flexGrow: "1",
      display: "flex",
      flexDirection: "row",
      ...shorthands.gap("12px"),
      fontFamily: tokens.fontFamilyBase,
      fontSize: "14px",
      fontWeight: 400,
      lineHeight: "20px",
    },
    checkbox: {
      '> input[type="checkbox"]:checked': {
        backgroundColor: "red",
        color: "red",
      },
    },
    jsonPreviewer: {
      height: "600px",
      "> span": {
        ...shorthands.flex(1),
      },
      "> textarea": {
        maxHeight: "inherit",
      },
    },
    dataSetsPreviewer: {
      height: "100px",
      "> span": {
        ...shorthands.flex(1),
      },
      "> textarea": {
        maxHeight: "inherit",
      },
    },
    divider: {
      width: "100%",
      height: "1px",
      backgroundColor: "#D2D0CE",
    },
    experimentationFieldContainer: {
      display: "flex",
      flexDirection: "row",
      ...shorthands.gap("8px"),
      justifyContent: "space-between",
      alignItems: "center",
      width: "100%",
    },
    experimentationFieldTitle: {
      fontFamily: tokens.fontFamilyBase,
      fontSize: "14px",
      fontWeight: 400,
      lineHeight: "20px",
      width: "110px",
    },
    headerContainer: {
      ...shorthands.margin("50px", "0", "28px", "0"),
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },
    headerTitleContainer: {
      alignItems: "center",
      display: "flex",
      justifyContent: "center",
      width: "100%",
    },
    headerTitle: {
      fontFamily: tokens.fontFamilyBase,
      fontSize: "24px",
      lineHeight: "32px",
      fontWeight: 600,
    },
    listbox: {
      maxHeight: "300px",
    },
    textBoxHeight: {
      height: "100px",
    },
    warningBox: {
      color: tokens.colorStatusWarningBorderActive,
    },
    warningIcon: {
      verticalAlign: "middle",
    },
    operationButton: {
      display: "flex",
      ...shorthands.margin("50px", "0", "28px", "0"),
      flexDirection: "row-reverse",
      gap: "8px",
    },
    overlay: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      position: "fixed",
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      background: "rgba(0, 0, 0, 0.4)",
    },
  },
  {
    xs: {
      root: {
        ...shorthands.padding("24px", "24px", "24px", "24px"),
      },
    },
  },
);

export const JobCreationViewContent = observer(() => {
  const styles = useStyles();
  const toast = useToast();
  const state: unknown = useLocation().state;

  const submitJob = async () => {
    toast.onToastStart("Creating Job...");
    updateStatusIsSubmitting(true);

    telemetryHelper.logUserActionEvent("SubmitJob");

    try {
      const job = await generateCurrentJob();
      createJob(job)
        .then(
          (result) => {
            telemetryHelper.logUserActionEvent("SubmitJobSuccess", {
              data: result,
            });
            toast.onToastSuccess("Create Job Succeeded");
            updateCurrentPath("/");
          },
          (error) => {
            telemetryHelper.logUserActionEvent("SubmitJobFailure", {
              message: error.message,
            });
            toast.onToastFailure(`Create Job Failed: ${error.message}`);
          },
        )
        .finally(() => updateStatusIsSubmitting(false));
    } catch (error) {
      if (error instanceof Error) {
        telemetryHelper.logUserActionEvent("SubmitJobFailure", {
          message: error.message,
        });
        toast.onToastFailure(`Create Job Failed: ${error.message}`);
      }
    }
  };

  const onSubmitClicked = React.useCallback(() => {
    if (jobCreationStore.isSubmitting || jobCreationStore.isVerifying) {
      return;
    }

    toast.onToastStart("Verifying Job Creation...");
    updateStatusIsVerifying(true);

    if (jobStore.selectedTemplate === undefined) {
      toast.onToastFailure("Please select a template");
      updateStatusIsVerifying(false);
      return;
    }

    const errorMessage = getJobCreationErrorMessage.get();
    if (errorMessage !== undefined) {
      toast.onToastFailure(errorMessage);
      updateStatusIsVerifying(false);
      return;
    }

    Promise.resolve()
      .then(() => confirmSBSValidator())
      .then(() => priorityJobValidator())
      .then(() => tokenPopupValidator())
      .then(() => servicePrevalidation())
      .then(() => {
        toast.onToastSuccess("Job Creation Verified");
        submitJob();
      })
      .catch((error) => {
        toast.onToastFailure(error.toString());
      })
      .finally(() => {
        updateStatusIsVerifying(false);
      });
  }, []);

  const handleJobTemplates = async (jobTemplates: JobTemplate[]) => {
    const templates = jobTemplates;

    updateAvailableTemplates(templates);

    const defaultTemplate = await getDefaultTemplate(state);
    if (defaultTemplate) {
      updateSelectedTemplateAction(defaultTemplate);
    }
  };

  useEffect(() => {
    updateStatusIsLoading(true);
    perfWrapper(
      "LoadCreateJobPage",
      Promise.all([
        getJobTemplates(),
        refreshQuerySets(),
        getCustomLMChecklistSets(),
        getPublicLMChecklistSets(),
        listProducts(),
        getBingProductMetaData("Bing").catch(() => undefined),
        getBingProductMetaData("CWC").catch(() => undefined),
      ]).then(
        ([
          jobTemplates,
          customQS,
          customLMChecklist,
          publicLMChecklist,
          products,
          bingMetaData,
          cwcMetaData,
        ]) => {
          updateProducts(products);
          updateProductJobTemplates(jobTemplates);
          updateProductMetaData(
            parseJsonStrOptional(bingMetaData, BingMetaDataResponse),
            parseJsonStrOptional(cwcMetaData, BingMetaDataResponse),
          );
          updateCustomizedQuerySets(customQS);
          updateCustomizedLMChecklistSets(customLMChecklist);
          updatePublicLMChecklistSets(publicLMChecklist);
          handleJobTemplates(jobTemplates);
          updateStatusIsLoading(false);
        },
      ),
    );
    return () => {
      resetJobStore();
      resetJobCreationStore();
      resetJobPriorityStateAction();
    };
  }, []);

  const renderShareSubmitButton = () => {
    return (
      <div className={styles.operationButton}>
        <Button
          disabled={isJobCreationEditDisabled.get()}
          appearance={"primary"}
          onClick={onSubmitClicked}
        >
          Submit
        </Button>
        <ShareButton />
      </div>
    );
  };

  const renderHeader = () => {
    return (
      <div>
        <div>
          <div className={styles.headerTitleContainer}>
            <span className={styles.headerTitle}>Create a new evaluation</span>
          </div>
        </div>
        <div>{!jobCreationStore.isLoading && renderShareSubmitButton()}</div>
      </div>
    );
  };

  const renderTokenDialog = () => {
    return (
      <TokenUploadingDialog
        isOpen={jobCreationStore.isTokenDialogOpen}
        email={jobCreationStore.tokenDialogEmail}
        onSuccess={() => {
          updateStatusIsTokenDialogOpen(false);
          servicePrevalidation()
            ?.then(() => {
              submitJob();
            })
            .catch((error) => {
              toast.onToastFailure(error.toString());
            });
        }}
        onCancel={updateStatusIsTokenDialogOpen.bind(null, false)}
        shouldUploadSevalToken={jobCreationStore.sevalTokenVerifyFailed}
        shouldUploadSssToken={jobCreationStore.substrateTokenVerifyFailed}
        shouldUploadTeamsCliToken={jobCreationStore.teamsCliTokenVerifyFailed}
        canSkipTokenUploading={jobCreationStore.canSkipTokenUploading}
      />
    );
  };

  return (
    <>
      {renderHeader()}
      <MainRender />
      {renderTokenDialog()}
      <JobSBSConfirmationDialog />
    </>
  );
});
