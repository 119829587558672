import {
  Field,
  InfoLabel,
  Input,
  shorthands,
  tokens,
} from "@fluentui/react-components";
import { observer } from "mobx-react-lite";
import { makeResponsiveStyles } from "../../../../../components/Responsive/makeResponsiveStyles";
import { updatePropValueActionV2 } from "../../../actions/jobActions";
import { getJobCreationError } from "../../../selectors/creationError/getJobCreationError";
import { getUserId } from "../../../selectors/getJobPropV2";
import { jobStore } from "../../../store/jobStore";
import { isSyntheticUserEnabled } from "../../../helpers/jobSyntheticUserHelper";

const useStyles = makeResponsiveStyles(
  {
    root: {
      backgroundColor: "white",
      boxSizing: "border-box",
      display: "flex",
      flexDirection: "column",
      ...shorthands.gap("20px"),
      ...shorthands.padding("24px", "87px", "24px", "87px"),
      ...shorthands.border("1px", "solid", "#EDEBE9"),
      ...shorthands.borderRadius("8px"),
    },
    block: {
      display: "flex",
      flexDirection: "column",
      ...shorthands.flex(1),
      width: "100%",
      ...shorthands.gap("8px"),
    },
    blockTitle: {
      fontFamily: tokens.fontFamilyBase,
      fontSize: "16px",
      fontWeight: 600,
      lineHeight: "22px",
    },
  },
  {
    xs: {
      root: {
        ...shorthands.padding("24px", "24px", "24px", "24px"),
      },
    },
  },
);

export const JobUserIdView = observer(() => {
  const styles = useStyles();

  const disabled =
    !isSyntheticUserEnabled() &&
    jobStore.selectedEvaluationType !== "FakeTenant";
  if (disabled) {
    return null;
  }

  return (
    <div className={styles.block}>
      <InfoLabel
        className={styles.blockTitle}
        info={"The user email address used for scraping."}
      >
        User ID:
      </InfoLabel>
      <Field
        validationState={getJobCreationError.get().userIdError.state}
        validationMessage={getJobCreationError.get().userIdError.message}
      >
        <Input
          size="medium"
          placeholder="MeganBowen@meinbizchat.onmicrosoft.com"
          value={getUserId.get()}
          onChange={(_, data) => {
            updatePropValueActionV2({
              prop: "configs_in_json.conversations.user_id",
              newData: data.value,
            });
          }}
        />
      </Field>
    </div>
  );
});
