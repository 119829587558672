import { ObservableMap } from "mobx";
import type { JobTemplate } from "../models/JobTemplate";

import { createStore } from "satcheljs";
import type { JobTemplatesDefaultExpConfig } from "sydneyeval-shared";
import { contributionServices } from "../contributionServices";
import type { Job } from "../models/Job";
import type { JobConfigurationUpdateAction } from "../models/JobConfigurationAction";
import { LocalCache } from "../models/LocalCache";
import type { EvaluationType } from "../models/Types";

export type BizChatQuerySetValidationResult = {
  state: "success" | "error";
  message: string | undefined;
};

interface IJobStore {
  dataSetsString: string | undefined;
  configuration: object | undefined;
  configurationJsonString: string;
  selectedTemplate: JobTemplate | undefined;
  defaultTemplateSettings: JobTemplatesDefaultExpConfig | undefined;
  availableTemplates: JobTemplate[];
  availableClusters: string[];
  selectedEvaluationType: EvaluationType;
  jobName: string;
  productFilter: string | undefined;
  caches: {
    jobIdToJob: ObservableMap<number, Job>;
    emailToUrl: ObservableMap<string, string>;
  };
  inputCache: Map<JobConfigurationUpdateAction["prop"], string>;
  bizchatQuerySetValidationResult: Map<string, BizChatQuerySetValidationResult>;
  SBSConfirmationResolve: ((value: boolean) => void) | undefined;
}

export const initJobStore: IJobStore = {
  dataSetsString: undefined,
  configuration: undefined,
  configurationJsonString: "",
  selectedTemplate: undefined,
  defaultTemplateSettings: undefined,
  availableTemplates: [],
  availableClusters: [],
  selectedEvaluationType: "PersonalAccount",
  jobName: "",
  productFilter:
    contributionServices.getLocalCache?.(LocalCache)?.productFilter,
  caches: {
    jobIdToJob: new ObservableMap<number, Job>(),
    emailToUrl: new ObservableMap<string, string>(),
  },
  inputCache: new Map<JobConfigurationUpdateAction["prop"], string>(),
  bizchatQuerySetValidationResult: new Map(),
  SBSConfirmationResolve: undefined,
};

export const jobStore = createStore<IJobStore>(
  "jobStore",
  Object.assign({}, initJobStore),
)();
