import * as React from "react";

import {
  Button,
  Dropdown,
  Input,
  Option,
  shorthands,
  tokens,
} from "@fluentui/react-components";

import { AddCircle24Filled } from "@fluentui/react-icons";
import { observer } from "mobx-react-lite";
import { ResponsiveRow } from "../../../../components/Responsive/ResponsiveRow";
import { makeResponsiveStyles } from "../../../../components/Responsive/makeResponsiveStyles";
import { productOptions } from "../../../../constants";
import { telemetryHelper } from "../../../../helpers/telemetryHelper";
import { isFeatureEnabled } from "../../../../selectors/features";
import { refreshProductSettings } from "../../actions/productSettingsActions";
import { updateIsOpenProductSelectDialog } from "../../mutators/jobCreationMutators";
import {
  getAllProducts,
  PRODUCT_ID_BING,
  PRODUCT_ID_CWC,
  PRODUCT_ID_M365_CHAT,
  PRODUCT_ID_OPG,
} from "../../selectors/productSelectors";
import { productSettingsStore } from "../../store/productSettingsStore";
import { ProductSelectDialog } from "../Dialog/ProductSelectDialog";
import { JobSetAsPriorityDialog } from "../JobCreation/components/JobSetAsPriorityDialog";
import { ShareButton } from "../JobDetail/components/ShareButton";
import { JobFilterView } from "./JobFilterView";
import { JobsTable } from "./JobsTable";

const useStyles = makeResponsiveStyles(
  {
    root: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      boxSizing: "border-box",
      minHeight: "100%",
      overflowX: "auto",
    },
    columnContainer: {
      display: "flex",
      flexDirection: "column",
    },
    rowContainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "flex-start",
      ...shorthands.gap("8px"),
    },
    label: {
      ...shorthands.margin("5px", "0", "5px", "0"),
      fontFamily: tokens.fontFamilyBase,
      fontWeight: 600,
      fontSize: "12px",
      lineHeight: "16px",
      fontStyle: "normal",
      color: "#605E5C",
    },
    functionBarContainer: {
      width: "100%",
      ...shorthands.margin("15px", "0", "20px", "0"),
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      flexWrap: "wrap-reverse",
      justifyContent: "space-between",
    },
    newEvaluationButton: {
      ...shorthands.padding("10px", "12px"),
      ...shorthands.borderRadius(tokens.borderRadiusLarge),
      width: "171px",
      height: "44px",
      color: "white",
      marginLeft: "auto",
      fontFamily: tokens.fontFamilyBase,
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 600,
      lineHeight: "22px",
    },
    icon: {
      opacity: 0.5,
      color: "#fff",
    },
    dialogContainer: {
      height: "90vh",
      width: "90vw",
      maxWidth: "90vw",
      ...shorthands.overflow("auto", "auto"),
    },
  },
  {
    xs: {
      rowContainer: {
        width: "100%",
      },
    },
    sm: {
      rowContainer: {
        width: "100%",
      },
    },
    md: {
      rowContainer: {
        width: "100%",
      },
    },
    lg: {
      rowContainer: {
        maxWidth: "70%",
      },
    },
  },
);

const statusOptions = [
  "All",
  "Running",
  "Created",
  "Submitted",
  "Completed",
  "Failed",
  "SubmitFailed",
  "Canceled",
  "CancelRequested",
  "Deleted",
  "DatasetNotFound",
];

export const getFiltersByProductId = (productId: string) => {
  switch (productId) {
    case "All":
      return { targetProductType: "All", targetExperimentPrefix: "" };
    case PRODUCT_ID_M365_CHAT:
      return { targetProductType: "BizChat", targetExperimentPrefix: "" };
    case PRODUCT_ID_BING:
      return { targetProductType: "Bing", targetExperimentPrefix: "" };
    case PRODUCT_ID_CWC:
      return { targetProductType: "CWC", targetExperimentPrefix: "" };
    case PRODUCT_ID_OPG:
      return { targetProductType: "OPG", targetExperimentPrefix: "" };
    default:
      break;
  }

  // Custom product
  const targetProduct = productSettingsStore.products.find(
    (_) => _.id === productId,
  );

  if (targetProduct === undefined) {
    return { targetProductType: "", targetExperimentPrefix: "" };
  } else {
    return {
      targetProductType: "",
      targetExperimentPrefix: `Product_${targetProduct.displayName.replace(
        " ",
        "",
      )}_`,
    };
  }
};

export const JobListView = observer(() => {
  const styles = useStyles();
  const [selectedStatus, setSelectedStatus] = React.useState(statusOptions[0]);
  const [jobNameInput, setJobNameInput] = React.useState("");
  const [creatorInput, setCreatorInput] = React.useState("");

  const [jobNameKeyword, setJobNameKeyword] = React.useState("");
  const [creatorKeyword, setCreatorKeyword] = React.useState("");
  const [jobFilterType, setJobFilterType] = React.useState("all");
  const [productType, setProductType] = React.useState<string>(
    productOptions[0],
  );
  const [experimentPrefix, setExperimentPrefix] = React.useState("");

  React.useEffect(() => {
    refreshProductSettings();
  }, []);

  React.useEffect(() => {
    // Log view render when the status is changed.
    telemetryHelper.logDiagnosticEvent("ViewDataChanged", {
      panelType: "Evaluation",
      panelUri: "Evaluation.JobList",
      message: "JobListView updated with status change",
    });
  }, [
    selectedStatus,
    jobNameKeyword,
    creatorKeyword,
    jobFilterType,
    productType,
    experimentPrefix,
  ]);

  const handleProductIdChange = (productId: string) => {
    const { targetProductType, targetExperimentPrefix } =
      getFiltersByProductId(productId);

    setProductType(targetProductType);
    setExperimentPrefix(targetExperimentPrefix);
  };

  return (
    <div className={styles.root}>
      <JobFilterView
        type={jobFilterType}
        onClickType={(type) => {
          setJobFilterType(type);
          if (type !== "all") {
            setCreatorInput("");
            setCreatorKeyword("");
          } else {
            setJobNameKeyword(jobNameInput);
            setCreatorKeyword(creatorInput);
          }
        }}
      />
      <div className={styles.functionBarContainer}>
        <div className={styles.rowContainer}>
          <ResponsiveRow
            maxColumnCount={4}
            maxColumnCountSmall={1}
            columnGap={8}
          >
            <div className={styles.columnContainer}>
              <label className={styles.label}>Product</label>

              <Dropdown
                aria-label="Product Filering Dropdown"
                defaultValue={"All"}
                defaultSelectedOptions={["All"]}
                onOptionSelect={(_, data) => {
                  handleProductIdChange(data.optionValue ?? "All");
                }}
              >
                <Option key={"All"}>{"All"}</Option>
                {getAllProducts.get().map((product) => (
                  <Option key={product.id} value={product.id}>
                    {product.displayName}
                  </Option>
                ))}
              </Dropdown>
            </div>
            <div className={styles.columnContainer}>
              <label className={styles.label}>Status</label>
              <Dropdown
                aria-label="Status Filering Dropdown"
                defaultValue={statusOptions[0]}
                defaultSelectedOptions={[statusOptions[0]]}
                onOptionSelect={(_, data) => {
                  setSelectedStatus(data.optionValue ?? statusOptions[0]);
                }}
              >
                {statusOptions.map((option) => (
                  <Option key={option}>{option}</Option>
                ))}
              </Dropdown>
            </div>
            <div className={styles.columnContainer}>
              <label className={styles.label}>Job Name</label>
              <Input
                appearance="outline"
                placeholder="Type job name"
                value={jobNameInput}
                onKeyDown={(ev) => {
                  if (ev.key === "Enter") {
                    setJobNameKeyword(jobNameInput);
                    setCreatorKeyword(creatorInput);
                  }
                }}
                onChange={(_, data) => {
                  setJobNameInput(data.value);
                }}
              />
            </div>
            {jobFilterType === "all" && (
              <div className={styles.columnContainer}>
                <label className={styles.label}>Created By</label>
                <Input
                  appearance="outline"
                  placeholder="Type job creator"
                  value={creatorInput}
                  onKeyDown={(ev) => {
                    if (ev.key === "Enter") {
                      setCreatorKeyword(creatorInput);
                      setJobNameKeyword(jobNameInput);
                    }
                  }}
                  onChange={(_, data) => {
                    setCreatorInput(data.value);
                  }}
                />
              </div>
            )}
          </ResponsiveRow>
        </div>
        <Button
          className={styles.newEvaluationButton}
          appearance="primary"
          icon={<AddCircle24Filled className={styles.icon} />}
          onClick={() => {
            updateIsOpenProductSelectDialog(true);
          }}
        >
          New Evaluation
        </Button>
      </div>
      <JobsTable
        selectedStatus={selectedStatus}
        jobNameKeyword={jobNameKeyword}
        creator={creatorKeyword}
        filter={jobFilterType}
        product={productType}
        experimentPrefix={experimentPrefix}
      />
      {isFeatureEnabled("job-priority") && <JobSetAsPriorityDialog />}

      <ShareButton />
      <ProductSelectDialog />
    </div>
  );
});
