import { isFeatureEnabled } from "../../../selectors/features";
import { store } from "../../../store/store";
import { jobStore } from "../store/jobStore";
import { generateFormattedDateStringForSyntheticUser } from "./formatHelper";

export const getSysthenticIngestionDataFolderName = () =>
  "useruploads/" +
  (store.account?.username ?? "UnknownUser") +
  "/" +
  generateFormattedDateStringForSyntheticUser();

export const isSyntheticUserEnabled = () => {
  return (
    isFeatureEnabled("select-synthetic-tenant-test-user") &&
    jobStore.selectedTemplate?.Name ===
      "bizChat_evaluation_all_metrics_for_test_tenant"
  );
};
