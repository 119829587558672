"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ErrorCode = exports.MISSING_SYNTHETIC_TENANT_TOKEN = exports.SYNTHETIC_TENANT_NOT_FOUND = exports.INVALID_USER_INPUT = exports.SECURITY_GROUP_NOT_FOUND = exports.QUERY_SET_VALIDATION_FAILED = exports.QUERY_SET_NOT_FOUND = exports.INVALID_REFRESH_TOKEN = exports.INVALID_USER_TOKEN = void 0;
const tools_1 = require("../tools");
// This error code is returned when the user is not authenticated.
exports.INVALID_USER_TOKEN = "INVALID_USER_TOKEN";
// This error cide is returned when the target refresh token is invalid.
// We should not treat this error code as API error, because it is a normal scenario.
exports.INVALID_REFRESH_TOKEN = "INVALID_REFRESH_TOKEN";
// This error code is returned when the user is authenticated but the target queryset file is not found.
// Basically happens when the queryset file is deleted unexpectedly due to pipeline failure or regular data deletion.
exports.QUERY_SET_NOT_FOUND = "QUERY_SET_NOT_FOUND";
// This error code is returned when the queryset content is invalid,
// Basically happens when the queryset content is not allowed to be uploaded,
// or the generated queryset content is not valid.
exports.QUERY_SET_VALIDATION_FAILED = "QUERY_SET_VALIDATION_FAILED";
// This error code is returned when the security group is not found from DSAPI.
exports.SECURITY_GROUP_NOT_FOUND = "SECURITY_GROUP_NOT_FOUND";
// This error code is returned when the user input is invalid.
exports.INVALID_USER_INPUT = "INVALID_USER_INPUT";
// This error code is returned when the synthetic tenant is not found from Synthetic Tenant API.
exports.SYNTHETIC_TENANT_NOT_FOUND = "SYNTHETIC_TENANT_NOT_FOUND";
// This error code is returned when the synthetic tenant token is missing.
exports.MISSING_SYNTHETIC_TENANT_TOKEN = "MISSING_SYNTHETIC_TENANT_TOKEN";
// Error codes that can be returned by the SEVAL API routers which specifically indicate the error scenario.
exports.ErrorCode = (0, tools_1.optional)((0, tools_1.or)([
    (0, tools_1.constStr)(exports.INVALID_USER_TOKEN),
    (0, tools_1.constStr)(exports.INVALID_REFRESH_TOKEN),
    (0, tools_1.constStr)(exports.QUERY_SET_NOT_FOUND),
    (0, tools_1.constStr)(exports.QUERY_SET_VALIDATION_FAILED),
    (0, tools_1.constStr)(exports.SECURITY_GROUP_NOT_FOUND),
    (0, tools_1.constStr)(exports.INVALID_USER_INPUT),
    (0, tools_1.constStr)(exports.SYNTHETIC_TENANT_NOT_FOUND),
    (0, tools_1.constStr)(exports.MISSING_SYNTHETIC_TENANT_TOKEN),
]));
