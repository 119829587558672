import {
  Button,
  DialogActions,
  Field,
  Input,
  Text,
  makeStyles,
} from "@fluentui/react-components";
import { Open20Regular } from "@fluentui/react-icons";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { Exp_Workspace_Id } from "../../../constants/constants";
import { ExperimentListTable } from "../../../partners/ShadowAB/components/experiments/ExperimentListTable";
import type { Experiment } from "../../../partners/ShadowAB/models/Experiment";
import { getUserAlias } from "../../../partners/ShadowAB/utils/account";
import { formatDatetime } from "../../../partners/ShadowAB/utils/format";

const useStyle = makeStyles({
  tips: {
    color: "#616161",
    fontSize: "12px",
    margin: "8px 0 16px",
  },
  filter: {
    width: "226px",
    marginBottom: "16px",
  },
  experimentContainer: {
    minHeight: "50px",
  },
  action: {
    marginTop: "16px",
  },
  longButton: {
    whiteSpace: "nowrap",
  },
});

export const ExistingExperimentContent = observer(
  (props: { show: boolean; onDismiss: () => void }) => {
    const styles = useStyle();
    const { show, onDismiss } = props;
    const myAlias = getUserAlias();
    const [searchName, setSearchName] = React.useState("");
    const columns = React.useMemo(() => {
      return [
        {
          key: "experimentName",
          title: "Experiment name",
          minWidth: 200,
          width: 240,
        },
        {
          key: "experimentCreateTime",
          title: "Created at",
          minWidth: 160,
          width: 200,
          render: (item: Experiment) => (
            <>{formatDatetime(item.experimentCreateTime ?? "")}</>
          ),
        },
      ];
    }, []);
    const experimentFilter = React.useMemo(() => {
      return {
        name: searchName,
      };
    }, [myAlias, searchName]);
    const [experimentId, setExperimentId] = React.useState("");
    const experimentKeys = React.useMemo(() => [experimentId], [experimentId]);
    const onExperimentClick = React.useCallback((row: Experiment) => {
      setExperimentId(row.experimentId);
    }, []);
    const handleSetupExperiment = () => {
      window.open(
        `https://exp.microsoft.com/a/feature/${experimentId}?workspaceId=${Exp_Workspace_Id}`,
        "_blank",
      );
      onDismiss();
    };
    return (
      <div style={{ display: show ? "block" : "none" }}>
        <Field className={styles.tips}>
          <Text>
            The shadow AB will be based on an existing experiment and a cloned
            progression. You'll be guided to the Control Tower to continue.
          </Text>
        </Field>
        <Field label="Experiment name" className={styles.filter}>
          <Input
            placeholder="Type experiment name"
            value={searchName}
            onChange={(_e, data) => {
              setSearchName(data.value);
            }}
          />
        </Field>
        <Field>
          <ExperimentListTable
            columns={columns}
            filter={experimentFilter}
            onRowClick={onExperimentClick}
            selectionKeys={experimentKeys}
            defaultPageSize={5}
            containerStyle={styles.experimentContainer}
          />
        </Field>
        <DialogActions className={styles.action}>
          <Button
            data-testid="exp-link"
            className={styles.longButton}
            onClick={handleSetupExperiment}
            appearance="primary"
            disabled={!experimentId}
            icon={<Open20Regular />}
          >
            Go to Control Tower and continue
          </Button>
          <Button onClick={() => onDismiss()} appearance="secondary">
            Cancel
          </Button>
        </DialogActions>
      </div>
    );
  },
);
