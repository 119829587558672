import {
  Link as FluentLink,
  makeStyles,
  Text,
  tokens,
} from "@fluentui/react-components";
import { Open16Regular } from "@fluentui/react-icons";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { Link } from "react-router-dom";
import { Exp_Workspace_Id } from "../../../../constants/constants";
import type { Scorecard } from "../../models/Experiment";
import {
  formatDatetime,
  formatDatetimeRange,
  formatDatetimeSimply,
  formatScorecardStatus,
} from "../../utils/format";
import { PaginationTable } from "../common/PaginationTable";
import { StatusTag } from "../common/StatusTag";

const useStyles = makeStyles({
  emptyTable: {
    width: "100%",
    height: "100%",
    backgroundColor: "#FAFAFA",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
});

interface IExperimentTableProps {
  allScorecards: Scorecard[];
  filter: {
    experiment?: string;
    status?: string;
    progression?: string;
    ring?: string;
    timeRange?: string;
    runningStage?: boolean;
  };
  isLoading?: boolean;
}
export const ExperimentScorecardTable = observer(
  (props: IExperimentTableProps) => {
    const { filter, allScorecards, isLoading } = props;
    const styles = useStyles();
    const [totalNum, setTotalNum] = React.useState(allScorecards.length);
    const [skipNum, setSkipNum] = React.useState(0);
    const [pageSize, setPageSize] = React.useState(10);
    const [scorecards, setScorecards] = React.useState<Scorecard[]>([]);

    React.useEffect(() => {
      if (filter) {
        setSkipNum(0);
      }
    }, [filter]);

    React.useEffect(() => {
      const filterScorecards = allScorecards.filter((scorecard) => {
        if (
          filter.status !== "All" &&
          formatScorecardStatus(scorecard.analysisTaskState) !== filter.status
        ) {
          return false;
        }
        if (
          filter.progression !== "All" &&
          scorecard.progressionName !== filter.progression
        ) {
          return false;
        }
        if (
          filter.ring !== "All" &&
          `${scorecard.stageName} ${scorecard.experimentStepName}` !==
            filter.ring
        ) {
          return false;
        }
        if (
          filter.timeRange !== "All" &&
          formatDatetimeRange(
            scorecard.timeRangeStart ?? "",
            scorecard.timeRangeEnd ?? "",
          ) !== filter.timeRange
        ) {
          return false;
        }
        if (filter.runningStage && scorecard.stageState === "Running") {
          return false;
        }
        return true;
      });
      setTotalNum(filterScorecards.length);
      const showScorecards = filterScorecards.slice(
        skipNum,
        skipNum + pageSize,
      );
      setScorecards(showScorecards);
    }, [skipNum, pageSize, filter, allScorecards]);

    const listProps = React.useMemo(() => {
      const header = [
        {
          key: "scorecardId",
          title: "Scorecard ID",
          minWidth: 110,
          width: 110,
          render: (item: Scorecard) => {
            if (!item.scorecardId) {
              return (
                <Text style={{ color: tokens.colorNeutralForeground4 }}>
                  Not available
                </Text>
              );
            }
            return (
              <Link
                to={`/shadowab/scorecard/${item.scorecardId}?experimentId=${item.experimentId}`}
              >
                <FluentLink>{item.scorecardId}</FluentLink>
              </Link>
            );
          },
        },
        {
          key: "timeRangeStart",
          title: "Time range",
          minWidth: 186,
          width: 200,
          render: (item: Scorecard) => {
            if (item.timeRangeStart && item.timeRangeEnd) {
              return (
                <>
                  {formatDatetimeRange(item.timeRangeStart, item.timeRangeEnd)}
                  {" ("}
                  {formatDatetimeSimply(item.timeRangeStart)}
                  {" - "}
                  {formatDatetimeSimply(item.timeRangeEnd)}
                  {")"}
                </>
              );
            }
            return <>{"--"}</>;
          },
        },
        {
          key: "analysisTaskState",
          title: "Status",
          minWidth: 104,
          width: 120,
          render: (item: Scorecard) => (
            <StatusTag label={formatScorecardStatus(item.analysisTaskState)} />
          ),
        },
        {
          key: "analysisStudyName",
          title: "Analysis name",
          minWidth: 220,
          width: 260,
          render: (item: Scorecard) => (
            <Link
              to={`https://exp.microsoft.com/a/feature/id/analysis-results/${item.experimentStepId}/scorecards/study/${item.analysisStudyId}?workspaceId=${Exp_Workspace_Id}`}
              target="_blank"
            >
              <FluentLink>
                {item.analysisStudyName}
                <Open16Regular style={{ verticalAlign: "text-bottom" }} />
              </FluentLink>
            </Link>
          ),
        },
        {
          key: "experimentStepName",
          title: "Stage and step",
          minWidth: 120,
          width: 160,
          render: (item: Scorecard) => (
            <>
              {item.stageName} {item.experimentStepName}
            </>
          ),
        },
        {
          key: "progressionName",
          title: "Progression",
          minWidth: 220,
          width: 260,
          render: (item: Scorecard) => <>{item.progressionName}</>,
        },
        {
          key: "createdTime",
          title: "Created at",
          minWidth: 186,
          width: 200,
          render: (item: Scorecard) => (
            <>{formatDatetime(item.createdTime ?? "")}</>
          ),
        },
      ];
      return {
        header,
        data: scorecards,
        keyName: "analysisTaskId",
        loading: isLoading,
        options: {
          innerBorder: true,
          renderEmpty: () => (
            <div className={styles.emptyTable}>
              Scorecards will be displayed here once they are available.
            </div>
          ),
        },
        pagination: {
          totalNum,
          skipNum,
          pageSize,
        },
        onPageChange: (page: number) => {
          setSkipNum((page - 1) * pageSize);
        },
        onPageSizeChange: (result: number) => {
          setPageSize(result);
        },
      };
    }, [totalNum, skipNum, pageSize, isLoading, scorecards]);

    return <PaginationTable {...listProps} />;
  },
);
