import type { JobTemplate } from "../models/JobTemplate";

import { action } from "satcheljs";
import type {
  JobConfigurationDeleteAction,
  JobConfigurationUpdateAction,
} from "../models/JobConfigurationAction";
import type { EvaluationType } from "../models/Types";

export const updateJobNameAction = action(
  "updateJobNameAction",
  (newName: string) => ({
    newName,
  }),
);

export const updateProductFilterAction = action(
  "updateProductFilterAction",
  (productId: string) => ({ productId }),
);

export const updateSelectedTemplateAction = action(
  "updateSelectedTemplateAction",
  (newTemplate: JobTemplate) => ({ newTemplate }),
);

export const updateDataSetsStringAction = action(
  "updateDataSetsStringAction",
  (dataSetsStr: string) => ({ dataSetsStr }),
);

export const updateJsonStringAction = action(
  "updateJsonStringAction",
  (jsonStr: string) => ({ jsonStr }),
);

export const updatePropValueActionV2 = action(
  "updatePropValueActionV2",
  (update: JobConfigurationUpdateAction) => ({ update }),
);

export const deletePropValueActionV2 = action(
  "deletePropValueActionV2",
  (update: JobConfigurationDeleteAction) => ({ update }),
);

export const updateSelectedEvaluationTypeAction = action(
  "updateSelectedEvaluationTypeAction",
  (newEvaluationType: EvaluationType) => ({ newEvaluationType }),
);

export const executeJobPreVerification = action(
  "executeJobPreVerification",
  () => ({}),
);

export const generateRefreshTokenAction = action(
  "generateRefreshToken",
  (callback?: () => void) => ({ callback }),
);
