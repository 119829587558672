import { cloneDeep } from "lodash";
import { orchestrator } from "satcheljs";
import { v4 as uuidv4 } from "uuid";
import {
  createProduct,
  deleteProduct,
  getJobTemplates,
  listProducts,
  updateProduct,
} from "../../../helpers/apiHelper";
import { store } from "../../../store/store";
import {
  onAddPipelineClick,
  onBackClick,
  onCancelClick,
  onDeletePipelineClick,
  onDeleteProductClick,
  onDupPipelineClick,
  onEditPipelineClick,
  onEditProductClick,
  onNextClick,
  onPipelineNameChange,
  onPipelineSettingsChange,
  onProductNameChange,
  onReleaseClick,
  onSaveClick,
  onSavePipelineClick,
  onSaveProductClick,
  refreshProductSettings,
} from "../actions/productSettingsActions";
import { tryFormat } from "../helpers/productHelper";
import {
  updateProductJobTemplates,
  updateProducts,
  updateProductSettingScenario,
} from "../mutators/updateProductSettings";
import { productSettingsStore } from "../store/productSettingsStore";

orchestrator(refreshProductSettings, () => {
  updateProductSettingScenario({
    scenarioType: "Loading",
  });

  Promise.all([getJobTemplates(), listProducts()])
    .then(([templates, products]) => {
      updateProductJobTemplates(
        templates.sort((a, b) =>
          `${a.Type}-${a.ExperimentName}`.localeCompare(
            `${b.Type}-${b.ExperimentName}`,
          ),
        ),
      );
      updateProducts(products);
      updateProductSettingScenario({
        scenarioType: "Listing",
      });
    })
    .catch((error) => {
      updateProductSettingScenario({
        scenarioType: "Error",
        errorMessage:
          error instanceof Error ? error.message : JSON.stringify(error),
      });
    });
});

orchestrator(onBackClick, () => {
  const scenario = productSettingsStore.scenario;
  switch (scenario.scenarioType) {
    case "Creating":
      updateProductSettingScenario({
        scenarioType: "Listing",
      });
      return;

    case "EditingProduct":
      if (scenario.currentPipeline !== undefined) {
        updateProductSettingScenario({
          ...scenario,
          currentPipeline: undefined,
        });
        return;
      } else {
        updateProductSettingScenario({
          scenarioType: "Listing",
        });
        return;
      }

    default:
      break;
  }
});

orchestrator(onSaveClick, () => {
  const scenario = productSettingsStore.scenario;
  switch (scenario.scenarioType) {
    case "Creating":
      onSaveProductClick(false);
      break;

    case "EditingProduct":
      if (scenario.currentPipeline !== undefined) {
        onSavePipelineClick({
          ...scenario.currentPipeline,
          lastUpdatedTime: new Date().toISOString(),
        });
      } else {
        onSaveProductClick(false);
      }
      break;

    default:
      break;
  }
});

orchestrator(onReleaseClick, ({ isConfirmed }) => {
  const scenario = productSettingsStore.scenario;
  if (scenario.scenarioType !== "EditingProduct") {
    return;
  }

  if (isConfirmed) {
    onSaveProductClick(true);
  } else {
    updateProductSettingScenario({
      ...scenario,
      isReleasing: scenario.selectedProduct,
    });
  }
});

orchestrator(onNextClick, () => {
  if (productSettingsStore.scenario.scenarioType !== "Creating") {
    return;
  }

  const creatorId = store.account?.localAccountId ?? "";

  updateProductSettingScenario({
    scenarioType: "EditingProduct",
    selectedProduct: {
      id: uuidv4(),
      displayName: productSettingsStore.scenario.currentProductName,
      templates: cloneDeep(productSettingsStore.scenario.currentPipelines),
      creatorId: creatorId,
      adminIds: [creatorId],
      status: "draft",
      lastUpdatedTime: "",
    },
    currentProductName: productSettingsStore.scenario.currentProductName,
    currentPipeline: undefined,
    currentPipelines: cloneDeep(productSettingsStore.scenario.currentPipelines),
  });
});

orchestrator(onEditProductClick, ({ product }) => {
  if (productSettingsStore.scenario.scenarioType !== "Listing") {
    return;
  }

  updateProductSettingScenario({
    scenarioType: "EditingProduct",
    selectedProduct: product,
    currentProductName: product.displayName,
    currentPipeline: undefined,
    currentPipelines: cloneDeep(product.templates),
  });
});

orchestrator(onDeleteProductClick, ({ product, isConfirmed }) => {
  if (productSettingsStore.scenario.scenarioType !== "Listing") {
    return;
  }

  if (!isConfirmed) {
    updateProductSettingScenario({
      scenarioType: "Listing",
      isDeleting: product,
    });
    return;
  }

  updateProductSettingScenario({
    scenarioType: "Listing",
  });

  productSettingsStore.toaster?.onToastStart?.("Deleting Product...");

  deleteProduct({
    ProductId: product.id,
  })
    .then((products) => {
      updateProducts(products);
      productSettingsStore.toaster?.onToastSuccess?.("Product Deleted");
    })
    .catch((error) => {
      const errorMessage =
        error instanceof Error ? error.message : JSON.stringify(error);

      productSettingsStore.toaster?.onToastFailure?.(
        errorMessage,
        "Delete Product Failed",
      );

      updateProductSettingScenario({
        scenarioType: "Error",
        errorMessage,
      });
    });
});

orchestrator(onCancelClick, () => {
  const scenario = productSettingsStore.scenario;
  switch (scenario.scenarioType) {
    case "Listing":
      updateProductSettingScenario({
        scenarioType: "Listing",
      });
      return;

    case "EditingProduct":
      updateProductSettingScenario({
        ...scenario,
        isReleasing: undefined,
      });
      return;

    default:
      return;
  }
});

/** Edit Product */

orchestrator(onProductNameChange, ({ productName }) => {
  if (
    productSettingsStore.scenario.scenarioType !== "EditingProduct" &&
    productSettingsStore.scenario.scenarioType !== "Creating"
  ) {
    return;
  }

  updateProductSettingScenario({
    ...productSettingsStore.scenario,
    currentProductName: productName,
  });
});

orchestrator(onPipelineNameChange, ({ pipelineName }) => {
  if (
    productSettingsStore.scenario.scenarioType !== "EditingProduct" ||
    productSettingsStore.scenario.currentPipeline === undefined
  ) {
    return;
  }

  updateProductSettingScenario({
    ...productSettingsStore.scenario,
    currentPipeline: {
      ...productSettingsStore.scenario.currentPipeline,
      displayName: pipelineName,
    },
  });
});

orchestrator(onPipelineSettingsChange, ({ settingsOverride }) => {
  if (
    productSettingsStore.scenario.scenarioType !== "EditingProduct" ||
    productSettingsStore.scenario.currentPipeline === undefined
  ) {
    return;
  }

  updateProductSettingScenario({
    ...productSettingsStore.scenario,
    currentPipeline: {
      ...productSettingsStore.scenario.currentPipeline,
      settingsOverride,
    },
  });
});

orchestrator(onAddPipelineClick, ({ template }) => {
  if (productSettingsStore.scenario.scenarioType !== "EditingProduct") {
    return;
  }

  updateProductSettingScenario({
    ...productSettingsStore.scenario,
    currentPipelines: [
      ...productSettingsStore.scenario.currentPipelines,
      template,
    ],
  });
});

orchestrator(onDupPipelineClick, ({ template }) => {
  if (productSettingsStore.scenario.scenarioType !== "EditingProduct") {
    return;
  }

  updateProductSettingScenario({
    ...productSettingsStore.scenario,
    currentPipelines: [
      ...productSettingsStore.scenario.currentPipelines,
      {
        ...template,
        id: uuidv4(),
      },
    ],
  });
});

orchestrator(onEditPipelineClick, ({ template }) => {
  if (productSettingsStore.scenario.scenarioType !== "EditingProduct") {
    return;
  }

  updateProductSettingScenario({
    ...productSettingsStore.scenario,
    currentPipeline: {
      ...template,
      settingsOverride: tryFormat(template.settingsOverride),
    },
  });
});

orchestrator(onDeletePipelineClick, ({ template }) => {
  if (productSettingsStore.scenario.scenarioType !== "EditingProduct") {
    return;
  }

  updateProductSettingScenario({
    ...productSettingsStore.scenario,
    currentPipelines: productSettingsStore.scenario.currentPipelines.filter(
      (_) => _.id !== template.id,
    ),
  });
});

orchestrator(onSavePipelineClick, ({ template }) => {
  if (productSettingsStore.scenario.scenarioType !== "EditingProduct") {
    return;
  }

  updateProductSettingScenario({
    ...productSettingsStore.scenario,
    currentPipelines: productSettingsStore.scenario.currentPipelines.map((_) =>
      _.id === template.id ? template : _,
    ),
    currentPipeline: undefined,
  });
});

orchestrator(onSaveProductClick, ({ release }) => {
  const scenario = productSettingsStore.scenario;
  if (
    scenario.scenarioType !== "EditingProduct" &&
    scenario.scenarioType !== "Creating"
  ) {
    return;
  }

  const findProductById = (id: string) =>
    productSettingsStore.products.find((_) => _.id === id);

  const currentUserId = store.account?.localAccountId ?? "";

  Promise.resolve()
    .then(() => {
      productSettingsStore.toaster?.onToastStart?.("Saving Products...");
    })
    .then(() => {
      switch (scenario.scenarioType) {
        // If current state is creating, create the product
        case "Creating":
          return createProduct({
            Product: {
              id: uuidv4(),
              displayName: scenario.currentProductName,
              templates: scenario.currentPipelines,
              creatorId: currentUserId,
              adminIds: [currentUserId],
              status: release ? "published" : "draft",
              lastUpdatedTime: new Date().toISOString(),
              publishedTime: release ? new Date().toISOString() : undefined,
            },
          });

        // If current state is editing, update the product or create a new one
        case "EditingProduct":
          // If the product is existing, update the product.
          // Otherwise, add the product
          if (findProductById(scenario.selectedProduct.id) !== undefined) {
            return updateProduct({
              Product: {
                ...scenario.selectedProduct,
                displayName: scenario.currentProductName,
                templates: scenario.currentPipelines,
                lastUpdatedTime: new Date().toISOString(),
                status: release ? "published" : "draft",
                publishedTime: release ? new Date().toISOString() : undefined,
              },
            });
          } else {
            return createProduct({
              Product: {
                ...scenario.selectedProduct,
                status: release ? "published" : "draft",
                publishedTime: release ? new Date().toISOString() : undefined,
              },
            });
          }
      }
    })
    .then((products) => {
      updateProducts(products);
      productSettingsStore.toaster?.onToastSuccess?.("Product Saved");
    })
    .then(() => {
      updateProductSettingScenario({
        scenarioType: "Listing",
      });
    })
    .catch((error) => {
      const errorMessage =
        error instanceof Error ? error.message : JSON.stringify(error);

      productSettingsStore.toaster?.onToastFailure?.(
        errorMessage,
        "Save Products Failed",
      );

      updateProductSettingScenario({
        scenarioType: "Error",
        errorMessage,
      });
    });
});
