import {
  makeStyles,
  MessageBar,
  MessageBarBody,
} from "@fluentui/react-components";
import { observer } from "mobx-react-lite";
import { ShadowABBreadcrumb } from "../ShadowABBreadcrumb";
import { ExperimentList } from "./ExperimentList";
const useStyles = makeStyles({
  banner: {
    marginTop: "24px",
    width: "100%",
  },
  root: {
    display: "flex",
    justifyContent: "flex-start",
    flexDirection: "column",
  },
});

export const ExperimentContainer = observer(() => {
  const styles = useStyles();
  return (
    <>
      <ShadowABBreadcrumb nav={[{ text: "Experiments" }]} />
      <div className={styles.root}>
        <MessageBar className={styles.banner} intent="info">
          <MessageBarBody>
            Shadow AB is now exclusive to Bizchat users. To access this feature,
            reach out to copilotonlineeval@microsoft.com.
          </MessageBarBody>
        </MessageBar>
        <ExperimentList />
      </div>
    </>
  );
});
