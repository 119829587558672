import { makeStyles } from "@fluentui/react-components";
import type { RefreshTokenType } from "../../models/Types";

const useStyles = makeStyles({
  link: {
    color: "rgba(0, 120, 212, 1)",
  },
});

export const GuidanceLink3S = () => {
  const styles = useStyles();
  return (
    <a
      className={styles.link}
      target="_blank"
      href="https://eng.ms/docs/experiences-devices/m365-core/microsoft-search-assistants-intelligence-msai/msai-stca/sydney-evaluation-platform/copilot-evaluation-platform/seval-manual-book/upload-token/generate-seval-refresh-token"
      rel="noreferrer"
    >
      {"Prepare for 3S Token"}
    </a>
  );
};

export const GuidanceLinkSEVAL = () => {
  const styles = useStyles();
  return (
    <a
      className={styles.link}
      target="_blank"
      href="https://eng.ms/docs/experiences-devices/m365-core/microsoft-search-assistants-intelligence-msai/msai-stca/sydney-evaluation-platform/copilot-evaluation-platform/seval-manual-book/upload-token/generate-seval-refresh-token"
      rel="noreferrer"
    >
      {"Prepare for Sydney User Refresh Token"}
    </a>
  );
};

export const GuidanceLinkTeamsCli = () => {
  const styles = useStyles();
  return (
    <a
      className={styles.link}
      target="_blank"
      href="https://eng.ms/docs/experiences-devices/m365-core/microsoft-search-assistants-intelligence-msai/msai-stca/sydney-evaluation-platform/copilot-evaluation-platform/seval-manual-book/upload-token/generate-teams-cli-refresh-token"
      rel="noreferrer"
    >
      {"Prepare for Teams CLI Token"}
    </a>
  );
};

export const GuidanceLinkByTokenType = (tokenType: RefreshTokenType) => {
  switch (tokenType) {
    case "3S refresh token":
      return <GuidanceLink3S />;
    case "Teams CLI refresh token":
      return <GuidanceLinkTeamsCli />;
    case "SEVAL refresh token":
      return <GuidanceLinkSEVAL />;
    default:
      return undefined;
  }
};
