import { Button, InfoLabel } from "@fluentui/react-components";
import { observer } from "mobx-react-lite";
import { updatePropValueActionV2 } from "../../../actions/jobActions";
import {
  getBingJobRunMode,
  getBingJobTestSetsList,
} from "../../../selectors/getJobPropV2";
import { jobCreationStore } from "../../../store/jobCreationStore";
import { JobClusterView } from "../components/JobClusterView";
import { JobEnginesView } from "../components/JobEnginesView";
import { JobScenarioView } from "../components/JobScenarioView";
import { JobTestSetBlockView } from "../components/JobTestSetBlockView";
import { useJobCreationStyles } from "./useJobCreationStyles";

export const CWCRender = observer(() => {
  const styles = useJobCreationStyles();

  if (jobCreationStore.bingMetaData === undefined) {
    return <></>;
  }

  const metaData = jobCreationStore.bingMetaData;
  const runMode = getBingJobRunMode.get();

  return (
    <div className={styles.columnWithSmallerGap}>
      <JobClusterView />
      <JobScenarioView metadata={metaData} />
      <div className={styles.block}>
        <InfoLabel className={styles.blockTitle}>Run Type</InfoLabel>
        <div className={styles.rowWithSmallGap}>
          <Button
            appearance={runMode === "single_turn" ? "primary" : "outline"}
            shape="square"
            onClick={() => {
              const testSets = getBingJobTestSetsList.get();
              updatePropValueActionV2({
                prop: "single_turn",
                newData:
                  testSets === undefined
                    ? undefined
                    : Object.fromEntries(
                        Object.entries(testSets).map(([key, value]) => [
                          key,
                          {
                            ...value,
                            metrics: value.metrics?.filter(
                              (_) => metaData.metrics.singleturn?.includes(_),
                            ),
                          },
                        ]),
                      ),
              });
              updatePropValueActionV2({
                prop: "multi_turn",
                newData: undefined,
              });
            }}
          >
            Single Turn
          </Button>
          <Button
            appearance={runMode === "multi_turn" ? "primary" : "outline"}
            shape="square"
            onClick={() => {
              const testSets = getBingJobTestSetsList.get();
              updatePropValueActionV2({
                prop: "multi_turn",
                newData:
                  testSets === undefined
                    ? undefined
                    : Object.fromEntries(
                        Object.entries(testSets).map(([key, value]) => [
                          key,
                          {
                            ...value,
                            metrics: value.metrics?.filter(
                              (_) => metaData.metrics.multiturn?.includes(_),
                            ),
                          },
                        ]),
                      ),
              });

              updatePropValueActionV2({
                prop: "single_turn",
                newData: undefined,
              });
            }}
          >
            Multi Turn
          </Button>
        </div>
        <JobTestSetBlockView metadata={metaData} />
        <JobEnginesView />
      </div>
    </div>
  );
});
